import { Navigate } from 'react-router-dom'
import { TOKEN_DATA_KEY } from '../common/constants'

export function isAuthenticated(): boolean {
  return !!localStorage.getItem(TOKEN_DATA_KEY)
}

export const PrivateRouter = (component: any) => {
  const Component = component

  return isAuthenticated() ? <Component /> : <Navigate to='/' replace />
}
