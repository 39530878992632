import { useEffect, useState } from 'react'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import { useNavigate } from 'react-router-dom'
import * as S from './styles'
import { MdLockOutline } from 'react-icons/md'
import Badge from '../../../components/Badge'
import { getUserById } from '../../../services/authUserApi'
import { Masks } from '../../../common/masks'
import Toast from '../../../components/Toast'
import { Profile } from '../../../models/Profile'
import { getSalesChannel } from '../../../services/plansApi'
import { getUserData } from '../../../services/userData'

const sections: ISections[] = [
  { description: 'Home', path: '/dashboard/home' },
  { description: 'Meu perfil' }
]

const MyProfile = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const [active, setActive] = useState(false)
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [salesChannelIds, setSalesChannelIds] = useState<string[]>([])

  useEffect(() => {
    async function init() {
      try {
        const user = getUserData()
        if (!user) return

        const response = await getUserById(user.id)
        if (response.data) {
          setName(response.data.name)
          setCpf(Masks.cpf(response.data.cpf))
          setEmail(response.data.email)
          setActive(response.data.active)
          setProfiles(response.data.profiles || [])
          getSalesChannels(response.data.salesChannelIds || [])
        }
      } catch (error) {
        Toast.showError('Usuário não encontrado!')
      }
    }
    init()
  }, [])

  async function getSalesChannels(salesChannelIds: string[]) {
    try {
      let names = []
      for (let i = 0; i < salesChannelIds.length; i++) {
        const response = await getSalesChannel(salesChannelIds[i])
        if (response.data && response.data.name_fantasy) {
          names.push(response.data.name_fantasy)
        }
      }

      if (!names.length) {
        names = ['N/A']
      }

      setSalesChannelIds(names)
    } catch (error) {
      Toast.showError('Não foi possível carregar os canais de vendas!')
    }
  }

  function handleChangePassword() {
    navigate(`/dashboard/meu-perfil/alterar-senha`)
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Meu perfil'} />
        </S.ContainerTitle>
        <S.ContainerCard onSubmit={() => {}}>
          <S.Actions onClick={() => {}}>
            <S.Action onClick={handleChangePassword}>
              <MdLockOutline size={20} />
              {'Alterar senha'}
            </S.Action>
          </S.Actions>
          <S.Row>
            <S.Col>
              <S.Label>Nome</S.Label>
              <S.Value>{name}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>CPF</S.Label>
              <S.Value>{cpf}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>E-mail</S.Label>
              <S.Value>{email}</S.Value>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Perfil de Acesso</S.Label>
              {profiles.map((prof, index) => (
                <S.Value key={index}>{prof.name}</S.Value>
              ))}
            </S.Col>
            <S.Col>
              <S.Label>Canais de Venda</S.Label>
              {salesChannelIds.map((salesChannel, index) => (
                <S.Value key={index}>{salesChannel}</S.Value>
              ))}
            </S.Col>
            <S.Col>
              <S.Label>Status</S.Label>
              <S.Value>
                {active ? (
                  <Badge title="Ativo" type="success" />
                ) : (
                  <Badge title="Inativo" type="error" />
                )}
              </S.Value>
            </S.Col>
          </S.Row>
        </S.ContainerCard>
      </S.Container>
    </S.ContainerPage>
  )
}

export default MyProfile
