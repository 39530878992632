import { useNavigate, useParams } from 'react-router-dom'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { validateBr } from 'js-brasil'
import Select, { IOption } from '../../../../components/Select'
import Breadcrumb, { ISections } from '../../../../components/Breadcrumb'
import {
  getGenders,
  getSubscriber,
  updateSubscriber
} from '../../../../services/accountsApi'
import Card from '../../../../components/Card'
import Title from '../../../../components/Title'
import TextInput from '../../../../components/TextInput'
import { Masks } from '../../../../common/masks'
import DefaultButton from '../../../../components/DefaultButton'
import {
  isEmpty,
  limitDate,
  validatePhone
} from '../../../../common/validators'
import { Subscriber } from '../../../../models/Subscriber'
import {
  convertUSDateInBRLDate,
  formatToUSDate
} from '../../../../common/utils'
import Toast from '../../../../components/Toast'

type ValueField = {
  value: string
  isInvalid?: boolean
}

const EditPersonalData = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState<ValueField>({ value: '' })
  const [socialName, setSocialName] = useState<ValueField>({ value: '' })
  const [cpf, setCpf] = useState<ValueField>({ value: '' })
  const [email, setEmail] = useState<ValueField>({ value: '' })
  const [phone, setPhone] = useState<ValueField>({ value: '' })
  const [birthDate, setBirthDate] = useState<ValueField>({ value: '' })
  const [biologicalSex, setBiologicalSex] = useState<ValueField>({ value: '' })
  const [sex, setSex] = useState<ValueField>({ value: '' })
  const [genders, setGenders] = useState<IOption[]>([])

  const biologicalSexes: IOption[] = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' }
  ]

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Visualizar titular', path: `/dashboard/subscribers/${id}` },
    { description: 'Editar' }
  ]

  useEffect(() => {
    loadGenders()
    loadSubscriber()
  }, [])

  async function loadGenders() {
    try {
      const response = await getGenders()
      if (response && response.data) {
        const options: IOption[] = response.data.map((gender) => ({
          value: gender.code,
          label: gender.description
        }))
        setGenders(options)
      }
    } catch {}
  }

  async function loadSubscriber() {
    if (!id) return
    try {
      const response = await getSubscriber(id)
      if (response.data) {
        const data = response.data
        setName({ value: data.name })
        setSocialName({ value: data.social_name })
        setCpf({ value: Masks.cpf(data.cpf) })
        setEmail({ value: data.email })
        setPhone({ value: Masks.phone(data.phone) })
        setBirthDate({ value: convertUSDateInBRLDate(data.birth) || '' })
        setBiologicalSex({ value: data.birth_sex })
        if (data.gender) {
          setSex({ value: data.gender.code })
        }
      }
    } catch (error) {
      Toast.showError('Não foi possível carregar o assinante.')
    }
  }

  function goBack() {
    navigate(`/dashboard/clientes/${id}`)
  }

  function validateFields(): boolean {
    let isValid = true

    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    if (!validateBr.cpf(cpf.value)) {
      setCpf({ ...cpf, isInvalid: true })
      isValid = false
    }

    if (isEmpty(email.value)) {
      setEmail({ ...email, isInvalid: true })
      isValid = false
    }

    if (!validatePhone(phone.value)) {
      setPhone({ ...phone, isInvalid: true })
      isValid = false
    }

    if (isEmpty(biologicalSex.value)) {
      setBiologicalSex({ ...biologicalSex, isInvalid: true })
      isValid = false
    }

    if (isEmpty(sex.value)) {
      setSex({ ...sex, isInvalid: true })
      isValid = false
    }

    if (isEmpty(birthDate.value) || !limitDate(birthDate.value)) {
      setBirthDate({ ...birthDate, isInvalid: true })
      isValid = false
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!id) return
    if (!validateFields()) return

    try {
      setLoading(true)
      const data: Subscriber = {
        name: name.value,
        social_name: socialName.value,
        cpf: Masks.onlyNumbers(cpf.value),
        email: email.value,
        phone: Masks.onlyNumbers(phone.value),
        birth: formatToUSDate(birthDate.value),
        birth_sex: biologicalSex.value,
        gender: sex.value
      }
      await updateSubscriber(id, data)
      setLoading(false)
      Toast.showSuccess('Assinante atualizado com sucesso!')
      goBack()
    } catch (error) {
      setLoading(false)
      const err = error as any
      console.error(error)
      Toast.showError(err.response.data.message)
    }
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Editar dados pessoais'} />
        </S.ContainerTitle>
        <S.Form onSubmit={handleSubmit}>
          <Card>
            <S.Row>
              <S.Col>
                <S.HeaderTitle>Dados pessoais</S.HeaderTitle>
                <S.SubTitle>Os campos com (*) são obrigatórios</S.SubTitle>
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Nome Completo *</S.Label>
                <TextInput
                  maxLength={50}
                  value={name.value}
                  onChange={(value) =>
                    setName({ value: Masks.inputName(value).toUpperCase() })
                  }
                  messageError={
                    name.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Nome Social</S.Label>
                <TextInput
                  maxLength={50}
                  value={socialName.value}
                  onChange={(value) =>
                    setSocialName({
                      value: Masks.inputName(value).toUpperCase()
                    })
                  }
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CPF *</S.Label>
                <TextInput
                  maxLength={50}
                  value={cpf.value}
                  onChange={(value) => setCpf({ value: Masks.cpf(value) })}
                  messageError={cpf.isInvalid ? 'Campo vazio ou inválido' : ''}
                />
              </S.Col>
              <S.Col>
                <S.Label>E-mail *</S.Label>
                <TextInput
                  maxLength={50}
                  value={email.value}
                  onChange={(value) => setEmail({ value })}
                  messageError={
                    email.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Celular *</S.Label>
                <TextInput
                  value={phone.value}
                  onChange={(value) => setPhone({ value: Masks.phone(value) })}
                  messageError={
                    phone.isInvalid ? 'Campo vazio ou inválido' : ''
                  }
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Data de Nascimento *</S.Label>
                <TextInput
                  placeholder="DD/MM/YYYY"
                  value={birthDate.value}
                  onChange={(value) =>
                    setBirthDate({ value: Masks.dateDDMMYYYY(value) })
                  }
                  messageError={
                    birthDate.isInvalid
                      ? 'Campo vazio ou limite de data inválido'
                      : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Sexo Biológico *</S.Label>
                <Select
                  title="Escolha um Sexo"
                  options={biologicalSexes}
                  value={biologicalSex.value}
                  onChange={(event) =>
                    setBiologicalSex({ value: event.target.value })
                  }
                  messageError={
                    biologicalSex.isInvalid ? 'Campo deve ser selecionado' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Gênero *</S.Label>
                <Select
                  title="Escolha um Gênero"
                  options={genders}
                  value={sex.value}
                  onChange={(event) => setSex({ value: event.target.value })}
                  messageError={
                    sex.isInvalid ? 'Campo deve ser selecionado' : ''
                  }
                />
              </S.Col>
            </S.Row>
          </Card>
          <S.BoxButton>
            <DefaultButton
              secondary
              label="Voltar"
              type="button"
              onClick={goBack}
            />
            <DefaultButton label="Salvar" type="submit" loading={loading} />
          </S.BoxButton>
        </S.Form>
      </S.Container>
    </S.ContainerPage>
  )
}

export default EditPersonalData
