import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import TextInput from '../../../components/TextInput'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { Masks } from '../../../common/masks'
import { getDataCnpj } from '../../../services/cpnj'
import DefaultButton from '../../../components/DefaultButton'
import { validateBr } from 'js-brasil'
import { isEmpty } from '../../../common/validators'
import Select, { IOption } from '../../../components/Select'
import { getCategories, postSalesChannel } from '../../../services/plansApi'
import { SalesChannel } from '../../../models/SalesChannel'
import { useNavigate } from 'react-router-dom'
import Toast from '../../../components/Toast'
import {
  getDefaultPaymentMethods,
  translatePaymentMethod
} from '../../../common/utils'

type ValueField = {
  value: string
  isInvalid?: boolean
}
type TypeSales = { type_saleschannel_id: string }

type Payload = Omit<SalesChannel, 'id' | 'type_saleschannel' | 'active'> &
  TypeSales

const NewSalesChannel = () => {
  const navigate = useNavigate()
  const [name, setName] = useState<ValueField>({ value: '' })
  const [nameFantasy, setNameFantasy] = useState<ValueField>({ value: '' })
  const [cnpj, setCnpj] = useState<ValueField>({ value: '' })
  const [cnpjInvoice, setCnpjInvoice] = useState<ValueField>({ value: '' })
  const [office_type, setOfficeType] = useState<ValueField>({ value: '' })
  const [cep, setCep] = useState<ValueField>({ value: '' })
  const [paymentType, setPaymentType] = useState([''])
  const [street, setStreet] = useState<ValueField>({ value: '' })
  const [number, setNumber] = useState<ValueField>({ value: '' })
  const [complement, setComplement] = useState<ValueField>({ value: '' })
  const [state, setState] = useState<ValueField>({ value: '' })
  const [city, setCity] = useState<ValueField>({ value: '' })
  const [district, setDistrict] = useState<ValueField>({ value: '' })
  const [category, setCategory] = useState<ValueField>({ value: '' })
  const [paymentTypeTemp, setPaymentTypeTemp] = useState<IOption[]>([])
  const [categories, setCategories] = useState<IOption[]>([])
  const [disableDistrict, setDisableDistrict] = useState(true)
  const [paymentError, setPaymentError] = useState(false)

  const paymentTypes: IOption[] = getDefaultPaymentMethods().map((pm) => ({
    value: pm,
    label: translatePaymentMethod(pm)
  }))

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Canais de Venda', path: '/dashboard/canais-de-venda' },
    {
      description: 'Cadastro do Canal de Venda',
      path: '/dashboard/canais-de-venda'
    }
  ]

  useEffect(() => {
    getCategories().then((response) => {
      if (response && response.data) {
        const options: IOption[] = response.data.map((category) => ({
          value: category.type_saleschannel_id,
          label: category.name.toUpperCase()
        }))
        setCategories(options)
      }
    })
  }, [])

  function searchCNPJ() {
    clearInput()
    const cnpjNumbers = Masks.onlyNumbers(cnpj.value)
    if (cnpjNumbers.length === 14) {
      getDataCnpj(cnpjNumbers)
        .then((response) => {
          if (response && response.data) {
            const company = response.data
            const { estabelecimento } = response.data
            setName({ value: company.razao_social })
            setNameFantasy({ value: estabelecimento.nome_fantasia })
            setNumber({ value: estabelecimento.numero })
            setCity({ value: estabelecimento.cidade.nome })
            setStreet({
              value:
                estabelecimento.tipo_logradouro +
                ' ' +
                estabelecimento.logradouro
            })
            setDistrict({ value: estabelecimento.bairro })
            setState({ value: estabelecimento.estado.sigla })
            setCep({ value: estabelecimento.cep })
            setOfficeType({ value: estabelecimento.tipo })
            openFieldEmpty(estabelecimento.bairro)
          }
        })
        .catch((error) => {
          Toast.showError(
            'Falha as requisitar dados do CNPJ, aguarde alguns segundos!'
          )
          console.error(error)
        })
    }
  }

  function clearInput() {
    setName({ value: '' })
    setNameFantasy({ value: '' })
    setNumber({ value: '' })
    setCity({ value: '' })
    setStreet({ value: '' })
    setDistrict({ value: '' })
    setState({ value: '' })
    setCep({ value: '' })
    setOfficeType({ value: '' })
    setDisableDistrict(true)
  }

  function openFieldEmpty(data: string) {
    if (isEmpty(data)) {
      setDisableDistrict(false)
    }
  }

  function validateFields(): boolean {
    let isValid = true

    if (isEmpty(category.value)) {
      setCategory({ ...category, isInvalid: true })
      isValid = false
    }
    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    if (isEmpty(nameFantasy.value)) {
      setNameFantasy({ ...nameFantasy, isInvalid: true })
      isValid = false
    }

    if (paymentType[0] === '' || paymentType[0] === undefined) {
      setPaymentError(true)
    }

    if (!validateBr.cnpj(cnpj.value)) {
      setCnpj({ ...cnpj, isInvalid: true })
      isValid = false
    }

    if (!validateBr.cnpj(cnpjInvoice.value)) {
      setCnpjInvoice({ ...cnpjInvoice, isInvalid: true })
      isValid = false
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!validateFields()) return
    try {
      const cnpjOnlyNumbers = Masks.onlyNumbers(cnpj.value)
      const invoicingCnpj = Masks.onlyNumbers(cnpjInvoice.value)
      const cepOnlyNumbers = Masks.onlyNumbers(cep.value)

      const payload: Payload = {
        name: name.value,
        cnpj: cnpjOnlyNumbers,
        name_fantasy: nameFantasy.value,
        type_saleschannel_id: category.value,
        city: city.value,
        district: district.value,
        office_type: office_type.value,
        number: number.value,
        state: state.value,
        street: street.value,
        payment_type: paymentType,
        invoicing_cnpj: invoicingCnpj,
        cep: cepOnlyNumbers,
        complement: complement.value
      }

      const response = await postSalesChannel(payload)
      if (response.status === 201) {
        Toast.showSuccess('Canal de venda criado com sucesso!!!')
        navigate(`/dashboard/canais-de-venda`)
      } else {
        Toast.showError('Não foi possível criar o canal de venda')
      }
    } catch (error) {
      Toast.showError('Não foi possível criar o canal de venda')
    }
  }

  function handleChange(selectedOption: any) {
    setPaymentError(false)
    const payment = selectedOption.map((item: any) => item.value)
    setPaymentType(payment)
    setPaymentTypeTemp(selectedOption)
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Cadastro do Canal de Venda'} />
        </S.ContainerTitle>
        <S.ContainerCard onSubmit={handleSubmit}>
          <S.ContainerBlock>
            <S.TitleBlock>Dados do canal de venda</S.TitleBlock>
            <S.Row>
              <S.Col>
                <S.Label>CNPJ *</S.Label>
                <TextInput
                  maxLength={18}
                  value={cnpj.value}
                  onChange={(value) => setCnpj({ value: Masks.cnpj(value) })}
                  onBlur={searchCNPJ}
                  messageError={cnpj.isInvalid ? 'Campo vazio ou inválido' : ''}
                />
              </S.Col>
              <S.Col>
                <S.Label>Razão Social *</S.Label>
                <TextInput
                  disabled
                  maxLength={50}
                  value={name.value}
                  onChange={(value) => setName({ value })}
                  messageError={
                    name.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Nome Fantasia *</S.Label>
                <TextInput
                  maxLength={50}
                  value={nameFantasy.value}
                  onChange={(value) => setNameFantasy({ value })}
                  messageError={
                    nameFantasy.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
            </S.Row>

            <S.Row>
              <S.Col>
                <S.Label>Categoria *</S.Label>
                <Select
                  title={'Escolha uma Categoria'}
                  options={categories}
                  value={category.value}
                  onChange={(event) =>
                    setCategory({ value: event.target.value })
                  }
                  messageError={
                    category.isInvalid ? 'Campo deve ser selecionado' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Tipo de Pagamento *</S.Label>
                <S.CustonSelect
                  classNamePrefix="Select"
                  isMulti
                  closeMenuOnSelect={false}
                  options={paymentTypes}
                  onChange={handleChange}
                  value={paymentTypeTemp}
                  hideSelectedOptions={false}
                  tabSelectsValue
                />
                <S.MessageError>
                  {paymentError ? 'Campo deve ser selecionado' : ''}
                </S.MessageError>
              </S.Col>
              <S.Col>
                <S.Label>Tipo</S.Label>
                <TextInput disabled value={office_type.value} />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CNPJ de Faturamento *</S.Label>
                <TextInput
                  maxLength={18}
                  value={cnpjInvoice.value}
                  onChange={(value) =>
                    setCnpjInvoice({ value: Masks.cnpj(value) })
                  }
                  messageError={
                    cnpjInvoice.isInvalid ? 'Campo vazio ou inválido' : ''
                  }
                />
              </S.Col>
              <S.Col />
              <S.Col />
            </S.Row>
          </S.ContainerBlock>
          <S.ContainerBlock>
            <S.TitleBlock>Endereço</S.TitleBlock>
            <S.Row>
              <S.Col>
                <S.Label>CEP</S.Label>
                <TextInput disabled value={Masks.cep(cep.value)} />
              </S.Col>
              <S.Col>
                <S.Label>Logradouro *</S.Label>
                <TextInput disabled maxLength={50} value={street.value} />
              </S.Col>
              <S.ColFlex>
                <S.Col>
                  <S.Label>Número *</S.Label>
                  <TextInput
                    maxLength={10}
                    value={number.value}
                    disabled
                    onChange={(value) => setNumber({ value })}
                  />
                </S.Col>
                <S.Col>
                  <S.Label>Complemento</S.Label>
                  <TextInput
                    disabled
                    maxLength={15}
                    value={complement.value}
                    onChange={(value) => setComplement({ value })}
                  />
                </S.Col>
              </S.ColFlex>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Bairro</S.Label>
                <TextInput
                  disabled={disableDistrict}
                  maxLength={50}
                  value={district.value}
                  onChange={(value) => setDistrict({ value })}
                />
              </S.Col>

              <S.Col>
                <S.Label>Cidade</S.Label>
                <TextInput disabled maxLength={50} value={city.value} />
              </S.Col>
              <S.Col>
                <S.Label>Estado</S.Label>
                <TextInput disabled maxLength={10} value={state.value} />
              </S.Col>
            </S.Row>
          </S.ContainerBlock>
          <S.BoxButton>
            <DefaultButton
              label="Voltar"
              secondary
              type="button"
              loading={false}
              onClick={() => navigate('/dashboard/canais-de-venda')}
            />
            <DefaultButton label="Salvar" type="submit" loading={false} />
          </S.BoxButton>
        </S.ContainerCard>
      </S.Container>
    </S.ContainerPage>
  )
}

export default NewSalesChannel
