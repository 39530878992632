import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultButton from '../../../components/DefaultButton'
import { SearchInput } from '../../../components/SearchInput'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import { Title } from '../../../components/Title'
import * as S from './styles'
import { Table } from '../../../styles/Table'
import { limitCaracteres } from '../../../common/utils'
import { getResources } from '../../../services/authUserApi'
import { Resource } from '../../../models/Resource'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

const ResourceList = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [resources, setResources] = useState<Resource[]>([])

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Recursos', path: '/dashboard/recursos' }
  ]

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true)
        const response = await getResources()
        setIsLoading(false)
        setResources(response.data)
      } finally {
        setIsLoading(false)
      }
    }
    init()
  }, [])

  function filter(resource: Resource) {
    const hasName = resource.name.toLowerCase().includes(search.toLowerCase())
    return hasName
  }

  function handleToShow(resourceId: string) {
    navigate(`/dashboard/recursos/${resourceId}`)
  }

  function getContainerTable() {
    if (isLoading) {
      return <S.EmptyArea>Carregando...</S.EmptyArea>
    }

    const filteredResources = (resources || []).filter(filter)

    if (!filteredResources.length) {
      return <S.EmptyArea>Nenhum recurso encontrado</S.EmptyArea>
    }

    return (
      <Table enableShadow enableHover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Código</th>
          </tr>
        </thead>
        <tbody>
          {filteredResources.map((resource) => (
            <tr key={resource.id} onClick={() => handleToShow(resource.id)}>
              <td>{limitCaracteres(resource.name, 40, true)}</td>
              <td>{limitCaracteres(resource.description || '', 40, true)}</td>
              <td>
                <strong>{resource.code}</strong>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Recursos'} />
          {hasPermission(ResourceType.CREATE_RESOURCE) && (
            <DefaultButton
              label="Novo Recurso"
              onClick={() => navigate(`/dashboard/recursos/novo`)}
            />
          )}
        </S.ContainerTitle>
        <SearchInput
          placeholder="Busque pelo nome do recurso"
          onChange={setSearch}
          value={search}
        />
        <S.ContainerTable>{getContainerTable()}</S.ContainerTable>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ResourceList
