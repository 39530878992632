import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultButton from '../../../components/DefaultButton'
import { SearchInput } from '../../../components/SearchInput'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import { Title } from '../../../components/Title'
import * as S from './styles'
import { getPlans } from '../../../services/plansApi'
import TableArea from './TableArea'
import { Plan } from '../../../models/Plan'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'
import Toast from '../../../components/Toast'

const ListPlans = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [plans, setPlans] = useState<Plan[]>([])

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Planos', path: '/dashboard/planos' }
  ]

  useEffect(() => {
    loadPlan()
  }, [])

  async function loadPlan() {
    try {
      setErrorMessage('')
      setIsLoading(true)
      const { data: plans } = await getPlans(true)
      setPlans(plans)
    } catch (err) {
      Toast.showError(
        'Não estamos conseguindo listar os planos no momento, tente novamente em instantes!'
      )
    } finally {
      setIsLoading(false)
    }
  }

  function getContainerTable() {
    if (isLoading) {
      return <S.EmptyArea>Carregando...</S.EmptyArea>
    }

    if (!plans.length) {
      return <S.EmptyArea>Nenhum plano encontrado</S.EmptyArea>
    }

    return <TableArea plans={plans.filter(filter)} />
  }

  function filter(plan: Plan) {
    return plan.name.toLowerCase().includes(search.toLowerCase())
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Planos'} />
          {hasPermission(ResourceType.CREATE_PLAN) && (
            <DefaultButton
              label="Novo Plano"
              type="button"
              loading={false}
              onClick={() => navigate(`/dashboard/planos/novo`)}
            />
          )}
        </S.ContainerTitle>
        <SearchInput
          placeholder="Busque pelo nome do plano"
          onChange={setSearch}
          value={search}
          errorMessage={errorMessage}
        />
        <S.ContainerTable>{getContainerTable()}</S.ContainerTable>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ListPlans
