import { useEffect, useState } from 'react'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import TextInput from '../../../components/TextInput'
import * as S from './styles'
import DefaultButton from '../../../components/DefaultButton'
import { isEmpty, isNumber } from '../../../common/validators'
import Select, { IOption } from '../../../components/Select'
import { getCategories, postPlan } from '../../../services/plansApi'
import { Plan } from '../../../models/Plan'
import { useNavigate } from 'react-router-dom'
import TextArea from '../../../components/TextArea'
import { Masks } from '../../../common/masks'
import Toast from '../../../components/Toast'

type ValueField<T> = {
  value: string | T
  isInvalid?: boolean
}

const intervalsMonth: IOption[] = [
  { value: '6', label: '6' },
  { value: '12', label: '12' }
]

const clientTypes: IOption[] = [
  { value: 'b2c', label: 'B2C' },
  { value: 'b2b', label: 'B2B' }
]

const sections: ISections[] = [
  { description: 'Home', path: '/dashboard/home' },
  { description: 'Planos', path: '/dashboard/planos' },
  { description: 'Cadastro de Plano' }
]

const NewPlan = () => {
  const navigate = useNavigate()
  const [name, setName] = useState<ValueField<string>>({ value: '' })
  const [clientType, setClientType] = useState<ValueField<'B2C' | 'B2B'>>({
    value: ''
  })
  const [price, setPrice] = useState<ValueField<string>>({ value: '' })
  const [intervalMonth, setIntervalMonth] = useState<ValueField<string>>({
    value: ''
  })
  const [description, setDescription] = useState<ValueField<string>>({
    value: ''
  })
  const [category, setCategory] = useState<ValueField<string>>({ value: '' })
  const [categories, setCategories] = useState<IOption[]>([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    getCategories()
      .then((response) => {
        if (response && response.data) {
          const options: any = response.data.map((cat) => ({
            value: cat.type_saleschannel_id,
            label: cat.name
          }))
          setCategories(options)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  function validateFields(): boolean {
    let isValid = true

    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    if (isEmpty(clientType.value)) {
      setClientType({ ...clientType, isInvalid: true })
      isValid = false
    }

    if (isEmpty(price.value)) {
      setPrice({ ...price, isInvalid: true })
      isValid = false
    }
    if (Number(price.value) <= 0) {
      setPrice({ ...price, isInvalid: true })
      isValid = false
    }

    if (!isNumber(price.value)) {
      setPrice({ ...price, isInvalid: true })
      isValid = false
    }

    if (isEmpty(intervalMonth.value)) {
      setIntervalMonth({ ...intervalMonth, isInvalid: true })
      isValid = false
    }

    if (isEmpty(category.value)) {
      setCategory({ ...category, isInvalid: true })
      isValid = false
    }

    if (isEmpty(description.value)) {
      setDescription({ ...description, isInvalid: true })
      isValid = false
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!validateFields()) return

    try {
      setLoading(true)
      const payload: Plan = {
        name: name.value,
        client_type: clientType.value,
        price: Number(price.value),
        interval_month: Number(intervalMonth.value),
        type_saleschannel_ids: [category.value],
        description: description.value
      }

      const response = await postPlan(payload)

      if (response.status === 201) {
        Toast.showSuccess('Plano criado com sucesso!!!')
        goBack()
      } else {
        Toast.showError('Não foi possível criar o plano')
      }
    } catch (error: any) {
      Toast.showError('Não foi possível criar o plano')
    } finally {
      setLoading(false)
    }
  }

  function goBack() {
    navigate(`/dashboard/planos`)
  }

  return (
    <S.ContainerPage>
      <S.Container onSubmit={handleSubmit}>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Cadastro do Plano'} />
        </S.ContainerTitle>
        <S.ContainerCard>
          <S.Row>
            <S.Col>
              <S.Label>Nome do Plano *</S.Label>
              <TextInput
                maxLength={100}
                value={name.value}
                onChange={(value) => setName({ value: Masks.clearName(value) })}
                messageError={name.isInvalid ? 'Campo deve ser preenchido' : ''}
              />
            </S.Col>
            <S.Col>
              <S.Label>Cliente *</S.Label>
              <Select
                title="Selecione um tipo de cliente"
                options={clientTypes}
                value={clientType.value}
                onChange={(event) =>
                  setClientType({ value: event.target.value })
                }
                messageError={name.isInvalid ? 'Campo deve ser preenchido' : ''}
              />
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Preço *</S.Label>
              <TextInput
                type="number"
                value={price.value}
                onChange={(value) => setPrice({ value })}
                placeholder="Ex.: 118.80 ou 149.40"
                messageError={
                  price.isInvalid
                    ? 'Campo deve ser preenchido ou está inválido'
                    : ''
                }
              />
            </S.Col>
            <S.Col>
              <S.Label>Intervalo (meses) *</S.Label>
              <Select
                title="Selecione um intervalo"
                options={intervalsMonth}
                value={intervalMonth.value}
                onChange={(event) =>
                  setIntervalMonth({ value: event.target.value })
                }
              />
              <S.MessageError>
                {intervalMonth.isInvalid ? 'Campo vazio ou inválido' : ''}
              </S.MessageError>
            </S.Col>
            <S.Col>
              <S.Label>Tipo de Canal de Venda *</S.Label>
              <Select
                title="Selecione uma categoria"
                options={categories}
                value={category.value}
                onChange={(event) => setCategory({ value: event.target.value })}
              />
              <S.MessageError>
                {category.isInvalid ? 'Campo vazio ou inválido' : ''}
              </S.MessageError>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Descrição *</S.Label>
              <TextArea
                maxLength={50}
                value={description.value}
                onChange={(value) => setDescription({ value })}
                messageError={
                  description.isInvalid ? 'Campo deve ser preenchido' : ''
                }
              />
            </S.Col>
          </S.Row>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
          <div>
            <DefaultButton label="Salvar" type="submit" loading={loading} />
          </div>
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default NewPlan
