import api from '../configs/authUserApiConfigs'
import { CreateProfile, Profile } from '../models/Profile'
import { ResetUserPassword } from '../models/ResetUserPassword'
import { CreateResourceDTO, Resource } from '../models/Resource'
import { CreateUser, User } from '../models/User'

export async function getAllUsers(): Promise<{ data: User[] }> {
  return await api.get('/users')
}

export async function activeUser(userId: string): Promise<{ data: User }> {
  return await api.put(`/users/${userId}/active`)
}

export async function inactiveUser(userId: string): Promise<{ data: User }> {
  return await api.put(`/users/${userId}/inactive`)
}

export async function getUserById(userId: string): Promise<{ data: User }> {
  return await api.get(`/users/${userId}`)
}

export async function getProfiles(): Promise<{ data: Profile[] }> {
  return await api.get(`/profiles`)
}

export async function createProfile(data: CreateProfile): Promise<{ data: Profile }> {
  return await api.post(`/profiles`, data)
}

export async function updateProfile(profileId: string, data: CreateProfile): Promise<{ data: Profile }> {
  return await api.put(`/profiles/${profileId}`, data)
}

export async function getProfile(profileId: string): Promise<{ data: Profile }> {
  return await api.get(`/profiles/${profileId}`)
}

export async function createUser(user: CreateUser): Promise<{ data: User }> {
  return await api.post(`/users`, user)
}

export async function updateUser(id: string, user: CreateUser): Promise<{ data: User }> {
  return await api.put(`/users/${id}`, user)
}

export async function autoResetPassword(userId: string): Promise<{ data: { password: string } }> {
  return await api.put(`/users/${userId}/auto-reset-password`)
}

export async function resetPassword(userId: string, data: ResetUserPassword): Promise<{ data: { wasChanged: boolean } }> {
  return await api.put(`/users/${userId}/reset-password`, data)
}

export async function createResource(resource: CreateResourceDTO): Promise<{ data: Resource }> {
  return await api.post(`/resources`, resource)
}

export async function updateResource(resourceId: string, resource: CreateResourceDTO): Promise<{ data: Resource }> {
  return await api.put(`/resources/${resourceId}`, resource)
}

export async function getResources(): Promise<{ data: Resource[] }> {
  return await api.get(`/resources`)
}

export async function getResourceById(id: string): Promise<{ data: Resource }> {
  return await api.get(`/resources/${id}`)
}
