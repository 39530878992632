import { useNavigate } from 'react-router-dom'
import { formatCNPJ, limitCaracteres } from '../../../../common/utils'
import CheckboxToggling from '../../../../components/CheckboxToggling'
import { SalesChannel } from '../../../../models/SalesChannel'
import { Table } from '../../../../styles/Table'
import Toast from '../../../../components/Toast'
import { useEffect, useState } from 'react'
import {
  activeSalesChannel,
  getSalesChannels,
  inactiveSalesChannel
} from '../../../../services/plansApi'
import { BiEditAlt } from 'react-icons/bi'
import * as S from './styles'
import { Masks } from '../../../../common/masks'

type ISalesChannel = {
  loading?: boolean
} & SalesChannel

type Search = {
  searchSales: string
}
const ListSalesChannel = ({ searchSales }: Search) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [changingStatus, setChangingStatus] = useState(false)
  const [saleschannels, setSalesChannels] = useState<ISalesChannel[]>([])

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true)
        const { data: sales } = await getSalesChannels()
        setIsLoading(false)
        setSalesChannels(sales || [])
      } catch(err) {
        Toast.showError(
          'Não estamos conseguindo listar os Canais de Vendas no momento, tente novamente em instantes!'
        )
      } finally {
        setIsLoading(false)
      }
    }
    init()
  }, [])

  function firstUpperCase(text: string) {
    return text.substring(0, 1).toUpperCase().concat(text.substring(1))
  }

  function enableLoading(saleschannelId: string) {
    setSalesChannels(
      saleschannels.map((saleschannel) => ({
        ...saleschannel,
        loading: saleschannel.id === saleschannelId
      }))
    )
  }

  function disableLoading() {
    setSalesChannels(
      saleschannels.map((saleschannel) => ({
        ...saleschannel,
        loading: false
      }))
    )
  }

  function filter(saleschannel: SalesChannel) {
    const hasName = (saleschannel.name || '').toLowerCase().includes(searchSales.toLowerCase())
    const hasFantasyName = (saleschannel.name_fantasy || '').toLowerCase().includes(searchSales.toLowerCase())
    const hasCNPJ = (saleschannel.cnpj || '').includes(searchSales.toLowerCase())
    const hasCNPJWithMask = Masks.cnpj(saleschannel.cnpj || '').includes(searchSales.toLowerCase())
    const hasCNPJWithoutMask = Masks.onlyNumbers(saleschannel.cnpj || '').includes(searchSales.toLowerCase())
    return hasName || hasFantasyName || hasCNPJ || hasCNPJWithMask || hasCNPJWithoutMask
  }

  function changeStatusBySalesChannel(saleschannelId: string, active: boolean) {
    setSalesChannels(
      saleschannels.map((saleschannel) => ({
        ...saleschannel,
        active:
          saleschannel.id === saleschannelId ? active : saleschannel.active,
        loading: false
      }))
    )
  }

  async function handleActiveSalesChannel(saleschannelId: string) {
    try {
      if (changingStatus) return
      enableLoading(saleschannelId)
      setChangingStatus(true)
      const salesChannel = await activeSalesChannel(saleschannelId)
      changeStatusBySalesChannel(saleschannelId, salesChannel.data.active)
      Toast.showSuccess('Canal de Venda ativado!')
    } catch {
      Toast.showError('Não foi possível ativar o Canal de Venda!')
      disableLoading()
    } finally {
      setChangingStatus(false)
    }
  }

  async function handleInactiveSalesChannel(saleschannelId: string) {
    try {
      if (changingStatus) return
      enableLoading(saleschannelId)
      setChangingStatus(true)
      const salesChannel = await inactiveSalesChannel(saleschannelId)
      changeStatusBySalesChannel(saleschannelId, salesChannel.data.active)
      Toast.showSuccess('Canal de Venda inativado!')
    } catch {
      Toast.showError('Não foi possível inativar o Canal de Venda!')
      disableLoading()
    } finally {
      setChangingStatus(false)
    }
  }

  if (isLoading) {
    return <S.EmptyArea>Carregando...</S.EmptyArea>
  }

  const filteredSalesChannel = (saleschannels || []).filter(filter)

  if (!filteredSalesChannel.length) {
    return <S.EmptyArea>Nenhum Canal de Venda encontrado</S.EmptyArea>
  }

  return (
    <Table enableShadow enableHover>
      <thead>
        <tr>
          <th>Tipo</th>
          <th>CNPJ</th>
          <th>Razão Social</th>
          <th>Nome Fantasia</th>
          <th>Status</th>
          <th>Editar</th>
        </tr>
      </thead>
      <tbody>
        {filteredSalesChannel?.map((salesChannel: ISalesChannel) => (
          <tr key={salesChannel.id}>
            <td>
              {firstUpperCase(String(salesChannel.type_saleschannel.name))}
            </td>
            <td>{formatCNPJ(salesChannel.cnpj)}</td>
            <td>{limitCaracteres(salesChannel.name, 20, true)}</td>
            <td>{salesChannel.name_fantasy}</td>
            <td>
              {salesChannel.loading ? (
                <S.ChangingStatusText>Alterando...</S.ChangingStatusText>
              ) : (
                <CheckboxToggling
                  onClick={() =>
                    salesChannel.active
                      ? handleInactiveSalesChannel(salesChannel.id)
                      : handleActiveSalesChannel(salesChannel.id)
                  }
                  value={salesChannel.active}
                />
              )}
            </td>
            <td>
              <BiEditAlt
                size={25}
                onClick={() =>
                  navigate(`/dashboard/canais-de-venda/${salesChannel.id}`)
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ListSalesChannel
