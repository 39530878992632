import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Masks } from '../../../../common/masks'
import Breadcrumb, { ISections } from '../../../../components/Breadcrumb'
import Card from '../../../../components/Card'
import TextInput from '../../../../components/TextInput'
import { Address } from '../../../../models/Address'
import { getAddress, putAddress } from '../../../../services/accountsApi'
import Title from '../../../../components/Title'
import * as S from './styles'
import { isEmpty, validateCep } from '../../../../common/validators'
import { getAddressByCep } from '../../../../services/cep'
import DefaultButton from '../../../../components/DefaultButton'
import { useNavigate } from 'react-router-dom'
import Toast from '../../../../components/Toast'

type ValueField = {
  value: string
  isInvalid?: boolean
}

const EditDependentAddress = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [messageError, setMessageError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [zipCode, setZipCode] = useState<ValueField>({ value: '' })
  const [street, setStreet] = useState<ValueField>({ value: '' })
  const [number, setNumber] = useState<ValueField>({ value: '' })
  const [complement, setComplement] = useState<ValueField>({ value: '' })
  const [state, setState] = useState<ValueField>({ value: '' })
  const [city, setCity] = useState<ValueField>({ value: '' })
  const [district, setDistrict] = useState<ValueField>({ value: '' })
  const { subscriber_id, dependent_id } = useParams()

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    {
      description: 'Dados do Cliente',
      path: `/dashboard/clientes/${subscriber_id}`
    },
    {
      description: 'Editar endereço',
      path: `/dashboard/clientes/${subscriber_id}/dependentes/${dependent_id}/editar-endereco`
    }
  ]

  useEffect(() => {
    loadSubscriberAddress()
  }, [])

  useEffect(() => {
    searchZipCode()
  }, [zipCode])

  function searchZipCode() {
    const zipCodeNumbers = Masks.onlyNumbers(zipCode.value)
    if (zipCodeNumbers.length === 8) {
      getAddressByCep(zipCodeNumbers).then((response) => {
        if (response && response.data) {
          const address = response.data
          setCity({ value: address.localidade })
          setStreet({ value: address.logradouro })
          setDistrict({ value: address.bairro })
          setState({ value: address.uf })
          setNumber({ value: '' })
          setComplement({ value: '' })
        }
      })
    }
  }

  function loadSubscriberAddress() {
    if (!dependent_id) return
    getAddress(dependent_id)
      .then((response) => {
        const address = response.data
        setCity({ value: address.city })
        setStreet({ value: address.address_description })
        setDistrict({ value: address.district })
        setState({ value: address.state })
        setZipCode({ value: address.zip_code })
        setNumber({ value: address.address_number })
        setComplement({ value: address.complement })
      })
      .catch((_error) => {
        Toast.showError('Não foi possível carregar os dados do endereço!')
      })
  }

  function validateFields(): boolean {
    let isValid = true

    if (!validateCep(zipCode.value)) {
      setZipCode({ ...zipCode, isInvalid: true })
      isValid = false
    }

    if (isEmpty(street.value)) {
      setStreet({ ...street, isInvalid: true })
      isValid = false
    }

    if (isEmpty(state.value)) {
      setState({ ...state, isInvalid: true })
      isValid = false
    }

    if (isEmpty(city.value)) {
      setCity({ ...city, isInvalid: true })
      isValid = false
    }

    if (isEmpty(district.value)) {
      setDistrict({ ...district, isInvalid: true })
      isValid = false
    }

    return isValid
  }

  async function handleSubmit() {
    if (!validateFields()) return

    try {
      setMessageError('')
      setIsLoading(true)
      const data: Address = {
        address_description: street.value,
        address_number: number.value,
        complement: complement.value,
        district: district.value,
        city: city.value,
        state: state.value,
        zip_code: zipCode.value
      }
      await putAddress(data, String(dependent_id))
      setIsLoading(false)
      Toast.showSuccess('Endereço atualizado com sucesso!')
      navigate(
        `/dashboard/clientes/${subscriber_id}/dependentes/${dependent_id}`
      )
    } catch (error) {
      setIsLoading(false)
      const err = error as any
      setMessageError(err.response.data.message)
    }
  }

  function goBack() {
    const backToSubscriber =
      searchParams.get('backToSubscriber') === 'true'
        ? '?backToSubscriber=true'
        : ''
    navigate(
      `/dashboard/clientes/${subscriber_id}/dependentes/${dependent_id}${backToSubscriber}`
    )
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Editar endereço'} />
        </S.ContainerTitle>
        <S.ContainerCard>
          <Card>
            <S.Row>
              <S.Col>
                <S.HeaderTitle>Endereço</S.HeaderTitle>
                <S.SubTitle>Os campos com (*) são obrigatórios</S.SubTitle>
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CEP *</S.Label>
                <TextInput
                  maxLength={9}
                  value={zipCode.value}
                  onChange={(value) => setZipCode({ value: Masks.cep(value) })}
                  onBlur={searchZipCode}
                  messageError={
                    zipCode.isInvalid ? 'Campo vazio ou inválido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Logradouro *</S.Label>
                <TextInput
                  disabled
                  maxLength={50}
                  value={street.value}
                  onChange={(value) => setStreet({ value })}
                  messageError={
                    street.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col2>
                <S.Label>Número</S.Label>
                <TextInput
                  maxLength={10}
                  value={number.value}
                  onChange={(value) =>
                    setNumber({ value: Masks.onlyNumbers(value) })
                  }
                />
              </S.Col2>
              <S.Col2>
                <S.Label>Complemento</S.Label>
                <TextInput
                  maxLength={50}
                  value={complement.value}
                  onChange={(value) => setComplement({ value })}
                />
              </S.Col2>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Bairro *</S.Label>
                <TextInput
                  disabled
                  value={district.value}
                  onChange={(value) => setDistrict({ value })}
                  messageError={
                    district.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Cidade *</S.Label>
                <TextInput
                  disabled
                  value={city.value}
                  onChange={(value) => setCity({ value })}
                  messageError={
                    city.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Estado *</S.Label>
                <TextInput
                  disabled
                  value={state.value}
                  onChange={(value) => setState({ value })}
                  messageError={
                    state.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
            </S.Row>
          </Card>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton
            label="Voltar"
            secondary
            type="button"
            onClick={goBack}
          />
          <DefaultButton
            label="Salvar"
            type="submit"
            onClick={handleSubmit}
            loading={isLoading}
          />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default EditDependentAddress
