import { useEffect, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import Title from '../../../../components/Title'
import Toast from '../../../../components/Toast'
import { autoResetPassword } from '../../../../services/authUserApi'
import { MdContentCopy } from 'react-icons/md'
import * as S from './styles'
import DefaultButton from '../../../../components/DefaultButton'
import { AiOutlineCheck } from 'react-icons/ai'
import Colors from '../../../../common/colors'

type ICancelModal = {
  userId: string
  showModal: boolean
  onClose?: () => void
}

const ResetPasswordModal = ({
  showModal: sm,
  onClose,
  userId
}: ICancelModal) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('asd@sadsasdas')

  useEffect(() => {
    setShowModal(sm)
    setShowNewPassword(false)
    setNewPassword('')
  }, [sm])

  function handleClickOutsideModal(event: any) {
    if (event.target.id === 'outsideModal') {
      handleCloseModal()
    }
  }

  function handleCloseModal() {
    if (onClose) {
      onClose()
    }
  }

  async function handleConfirm() {
    try {
      if (loading) return
      setLoading(true)
      const {
        data: { password }
      } = await autoResetPassword(userId)
      setShowNewPassword(true)
      setNewPassword(password)
      setLoading(false)
    } catch {
      Toast.showError('Não fou possível redefinir a senha do usuário!')
    } finally {
      setLoading(false)
    }
  }

  function handleCopyPassword() {
    const element: any = document.getElementById('password')
    element?.select()
    document.execCommand('copy')
  }

  return (
    <S.ContainerModal
      id="outsideModal"
      onClick={handleClickOutsideModal}
      hidden={!showModal}
    >
      <S.Modal id="insideModal">
        <S.ModalBoxTitle>
          <Title title={'Gerar ou Redefinir senha'} />
          <MdOutlineClose size={24} onClick={handleCloseModal} />
        </S.ModalBoxTitle>
        <S.ModalBoxForm>
          {showNewPassword ? (
            <>
              <S.Label>
                <AiOutlineCheck size={25} color={Colors.success} /> Senha redefinida com sucesso!
              </S.Label>
              <S.CopyContainer>
                <input type="text" id="password" value={newPassword} />
                <MdContentCopy
                  size={20}
                  title="Copiar senha."
                  onClick={handleCopyPassword}
                />
              </S.CopyContainer>
            </>
          ) : (
            <>
              <S.Label>
                Tem certeza que deseja redefinir a senha do usuário?
              </S.Label>
              <S.ModalBoxActions>
                <DefaultButton
                  label="Cancelar"
                  type="button"
                  onClick={handleCloseModal}
                  loading={false}
                  secondary
                />
                <DefaultButton
                  label="Confirmar"
                  type="button"
                  onClick={handleConfirm}
                  loading={loading}
                />
              </S.ModalBoxActions>
            </>
          )}
        </S.ModalBoxForm>
      </S.Modal>
    </S.ContainerModal>
  )
}

export default ResetPasswordModal
