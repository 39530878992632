import { useEffect, useState } from 'react'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import DefaultButton from '../../../components/DefaultButton'
import { useNavigate, useParams } from 'react-router-dom'
import * as S from './styles'
import { MdClose, MdOutlineSettingsBackupRestore } from 'react-icons/md'
import { AiOutlineCheckCircle, AiOutlineEdit } from 'react-icons/ai'
import Badge from '../../../components/Badge'
import {
  activeUser,
  getUserById,
  inactiveUser
} from '../../../services/authUserApi'
import { Masks } from '../../../common/masks'
import Toast from '../../../components/Toast'
import { Profile } from '../../../models/Profile'
import ResetPasswordModal from './ResetPasswordModal'
import { getSalesChannel } from '../../../services/plansApi'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

const sections: ISections[] = [
  { description: 'Home', path: '/dashboard/home' },
  { description: 'Usuários', path: '/dashboard/usuarios' },
  { description: 'Dados do Usuário' }
]

const ShowUser = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [userId, setUserId] = useState('')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const [active, setActive] = useState(true)
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [salesChannelIds, setSalesChannelIds] = useState<string[]>([])
  const [changingStatus, setChangingStatus] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  useEffect(() => {
    async function init() {
      try {
        if (!id) return
        const response = await getUserById(id)
        if (response.data) {
          const { active, name, cpf, email, id, profiles, salesChannelIds } =
            response.data
          setUserId(id)
          setName(name)
          setCpf(Masks.cpf(cpf))
          setEmail(email)
          setActive(active)
          setProfiles(profiles || [])
          getSalesChannels(salesChannelIds || [])
        }
      } catch (error) {
        Toast.showError('Usuário não encontrado!')
      }
    }
    init()
  }, [])

  async function getSalesChannels(salesChannelIds: string[]) {
    try {
      let names = []
      for (let i = 0; i < salesChannelIds.length; i++) {
        const response = await getSalesChannel(salesChannelIds[i])
        if (response.data && response.data.name_fantasy) {
          names.push(response.data.name_fantasy)
        }
      }

      if (!names.length) {
        names = ['N/A']
      }

      setSalesChannelIds(names)
    } catch (error) {
      Toast.showError('Não foi possível carregar os canais de vendas!')
    }
  }

  function goBack() {
    navigate(`/dashboard/usuarios`)
  }

  async function handleActiveUser() {
    try {
      if (changingStatus) return
      setChangingStatus(true)
      const user = await activeUser(userId)
      setActive(user.data.active)
      Toast.showSuccess('Usuário ativado!')
    } catch {
      Toast.showError('Não fou possível ativar o usuário!')
    } finally {
      setChangingStatus(false)
    }
  }

  async function handleInactiveUser() {
    try {
      if (changingStatus) return
      setChangingStatus(true)
      const user = await inactiveUser(userId)
      setActive(user.data.active)
      Toast.showSuccess('Usuário inativado!')
    } catch {
      Toast.showError('Não fou possível inativar o usuário!')
    } finally {
      setChangingStatus(false)
    }
  }

  function handleResetPassword() {
    setShowCancelModal(true)
  }

  function handleToEdit() {
    if (!userId) return
    navigate(`/dashboard/usuarios/${userId}/editar`)
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Dados do Usuário'} />
        </S.ContainerTitle>
        <S.ContainerCard onSubmit={() => {}}>
          <S.Actions onClick={() => {}}>
            {hasPermission(ResourceType.RESET_PASSWORD_BACKOFFICE_USER) && (
              <S.Action onClick={handleResetPassword}>
                <MdOutlineSettingsBackupRestore size={20} />
                {'Gerar ou Redefinir senha'}
              </S.Action>
            )}
            {hasPermission(ResourceType.EDIT_BACKOFFICE_USER) && (
              <S.Action onClick={handleToEdit}>
                <AiOutlineEdit size={20} />
                {'Editar'}
              </S.Action>
            )}
            {active && hasPermission(ResourceType.ACTIVE_INACTIVE_BACKOFFICE_USER) && (
              <S.Action color="error" onClick={handleInactiveUser}>
                <MdClose size={20} />
                {'Inativar'}
              </S.Action>
            )}
            {!active && hasPermission(ResourceType.ACTIVE_INACTIVE_BACKOFFICE_USER) && (
              <S.Action color="success" onClick={handleActiveUser}>
                <AiOutlineCheckCircle size={20} />
                {'Ativar'}
              </S.Action>
            )}
          </S.Actions>
          <S.Row>
            <S.Col>
              <S.Label>Nome</S.Label>
              <S.Value>{name}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>CPF</S.Label>
              <S.Value>{cpf}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>E-mail</S.Label>
              <S.Value>{email}</S.Value>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Perfil de Acesso</S.Label>
              {profiles.map((prof, index) => (
                <S.Value key={index}>{prof.name}</S.Value>
              ))}
            </S.Col>
            <S.Col>
              <S.Label>Canais de Venda</S.Label>
              {salesChannelIds.map((salesChannel, index) => (
                <S.Value key={index}>{salesChannel}</S.Value>
              ))}
            </S.Col>
            <S.Col>
              <S.Label>Status</S.Label>
              <S.Value>
                {active ? (
                  <Badge title="Ativo" type="success" />
                ) : (
                  <Badge title="Inativo" type="error" />
                )}
              </S.Value>
            </S.Col>
          </S.Row>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
        </S.BoxButton>
      </S.Container>
      <ResetPasswordModal
        userId={userId}
        showModal={showCancelModal}
        onClose={() => setShowCancelModal(false)}
      />
    </S.ContainerPage>
  )
}

export default ShowUser
