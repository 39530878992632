import styled from 'styled-components'
import Colors from '../../../common/colors'

type IHeaderOption = {
  primary?: boolean
  red?: boolean
  green?: boolean
}

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`

export const ContainerCard = styled.div`
  background-color: ${Colors.white};
  box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
`

export const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`

export const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: ${Colors.neutralColor};
`

export const HeaderOptions = styled.div`
  display: flex;
`

export const HeaderOption = styled.div<IHeaderOption>`
  display: flex;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
  color: ${Colors.black};
  ${({ primary }) => !!primary && `color: ${Colors.primary};`}
  ${({ primary }) => !!primary && `text-decoration: underline;`}
  ${({ red }) => !!red && `color: ${Colors.error};`}
  ${({ green }) => !!green && `color: ${Colors.success};`}
`

export const HeaderOptionLabel = styled.p`
  font-weight: 450;
  margin-left: 8px;

  &:hover {
    text-decoration: underline;
  }
`

type IBodyCard = {
  hidden?: boolean
}

export const BodyCard = styled.div<IBodyCard>`
  ${({ hidden }) => !!hidden && `display: none;`}
`

export const Row = styled.div`
  display: flex;
  padding: 8px;
`

export const Col = styled.div`
  width: 100%;
  padding: 8px;

  input {
    width: 100%;
  }
`

export const Label = styled.div`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 8px;
`

export const Description = styled.div`
  word-break: break-word;
`

export const LinkDescription = styled.div`
  word-break: break-word;
  text-decoration: underline;
  color: ${Colors.primary};
  cursor: pointer;
`

export const LoadingArea = styled.p`
  margin-top: 16px;
  margin-left: 8px;
`
export const ContainerButton = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  button {
    width: 100%;
    max-width: 250px;
  }
`
