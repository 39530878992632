import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Masks } from '../../../common/masks'
import { isEmpty } from '../../../common/validators'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import DefaultButton from '../../../components/DefaultButton'
import TextArea from '../../../components/TextArea'
import TextInput from '../../../components/TextInput'
import { Title } from '../../../components/Title'
import Toast from '../../../components/Toast'
import {
  createResource,
  getResourceById,
  updateResource
} from '../../../services/authUserApi'
import * as S from './styles'

type ValueField = {
  value: string
  isInvalid?: boolean
  customMessage?: string
}

const ResourceNew = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<ValueField>({ value: '' })
  const [code, setCode] = useState<ValueField>({ value: '' })
  const [description, setDescription] = useState('')

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Recursos', path: '/dashboard/recursos' },
    { description: id ? 'Editar' : 'Novo' }
  ]

  useEffect(() => {
    async function init() {
      try {
        if (!id) return
        const response = await getResourceById(id)
        setName({ value: response.data.name })
        setCode({ value: response.data.code })
        setDescription(response.data.description || '')
      } catch {
        Toast.showError('Não foi possível carregar o Recurso no momento.')
      }
    }
    init()
  }, [])

  function goBack() {
    if (id) {
      navigate(`/dashboard/recursos/${id}`)
    } else {
      navigate(`/dashboard/recursos`)
    }
  }

  function validateFields(): boolean {
    let isValid = true

    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    if (isEmpty(code.value)) {
      setCode({ ...code, isInvalid: true })
      isValid = false
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    try {
      if (!validateFields()) return
      if (loading) return
      setLoading(true)

      const payload = {
        name: name.value,
        code: code.value,
        description
      }
      if (!!id) {
        const response = await updateResource(id, payload)
        Toast.showSuccess('Recurso atualizado com sucesso!')
        navigate(`/dashboard/recursos/${response.data.id}`)
      } else {
        const response = await createResource(payload)
        Toast.showSuccess('Recurso criado com sucesso!')
        navigate(`/dashboard/recursos/${response.data.id}`)
      }
    } catch (error) {
      Toast.showError('Não está sendo possível salvar o recurso!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.ContainerPage>
      <S.Container onSubmit={handleSubmit}>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={id ? 'Editar Recurso' : 'Cadastrar Recurso'} />
        </S.ContainerTitle>
        <S.ContainerCard>
          <S.Row>
            <S.Col>
              <S.HeaderTitle>Dados do recurso</S.HeaderTitle>
              <S.SubTitle>Os campos com (*) são obrigatórios</S.SubTitle>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Nome *</S.Label>
              <TextInput
                maxLength={100}
                value={name.value}
                onChange={(value) => {
                  setName({ value, isInvalid: false })
                }}
                messageError={name.isInvalid ? 'Campo obrigatório' : ''}
              />
            </S.Col>
            <S.Col>
              <S.Label>Código *</S.Label>
              <TextInput
                maxLength={100}
                value={code.value}
                placeholder="Ex.: CREATE_SUBSCRIPTION"
                onChange={(value) => {
                  value = Masks.upperCase(value)
                  value = Masks.formatCode(value)
                  setCode({ value, isInvalid: false })
                }}
                messageError={code.isInvalid ? 'Campo obrigatório' : ''}
                disabled={!!id}
              />
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Descrição</S.Label>
              <TextArea value={description} onChange={setDescription} />
            </S.Col>
          </S.Row>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
          <DefaultButton label="Salvar" type="submit" loading={loading} />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ResourceNew
