import { BsDownload } from 'react-icons/bs'
import * as S from './styles'
import {
  ContainerCard,
  HeaderOptions,
  HeaderCard,
  HeaderTitle,
  HeaderOption,
  HeaderOptionLabel
} from '../styles'
import { getBadgeByStatus } from '../../../../components/Badge'
import {
  convertUSDateInBRLDateTime,
  dateFileName,
  handleViewBlob,
  translatePaymentMethod
} from '../../../../common/utils'
import { getReceiptPayment } from '../../../../services/plansApi'
import { FiLoader } from 'react-icons/fi'
import { Subscription } from '../../../../models/Subscription'
import { PaymentMethodType } from '../../../../models/PaymentMethodType'

type SubscriptionWithSelectProp = {
  loading?: boolean
} & Subscription

type Props = {
  isLoading: boolean
  subscriptions?: SubscriptionWithSelectProp[]
}

const SubscriptionHistorySession = ({ isLoading, subscriptions }: Props) => {
  async function handleDownloadReceipt(subscriptions_id: string) {
    await getReceiptPayment(subscriptions_id)
      .then((res) => {
        const blob = res.data
        const fileName = dateFileName()
        handleViewBlob(blob, `receipt_payment_${fileName}.pdf`)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function handleDownloadContract(
    subscription_id: any,
    contract_file_id: any
  ): void {
    throw new Error('Function not implemented.')
  }

  return (
    <ContainerCard>
      <HeaderCard>
        <HeaderTitle>Histórico de assinaturas</HeaderTitle>
      </HeaderCard>
      {subscriptions?.map((sub, index) => (
        <S.ContainerItem key={index}>
          <S.ContainerHistoryData>
            <S.ContainerInfo>
              <S.Label>
                Plano: <S.Value>{sub.plan_id}</S.Value>
              </S.Label>
              <S.Label>
                Vigência do contrato:{' '}
                <S.Value>
                  {convertUSDateInBRLDateTime(sub.start_at) || 'N/A'} -{' '}
                  {convertUSDateInBRLDateTime(sub.end_at) || 'N/A'}
                </S.Value>
              </S.Label>
              <S.Label>
                Método de pagamento:{' '}
                <S.Value>{translatePaymentMethod(sub.payment_kind as PaymentMethodType)}</S.Value>
              </S.Label>
              <S.Label>
                Data de criação:{' '}
                <S.Value>
                  {convertUSDateInBRLDateTime(sub.created_at) || 'N/A'}
                </S.Value>
              </S.Label>
              <S.Label>
                Data de ativação:{' '}
                <S.Value>
                  {convertUSDateInBRLDateTime(sub.start_at) || 'N/A'}
                </S.Value>
              </S.Label>
              <S.Label>
                Data de cancelamento:{' '}
                <S.Value>
                  {convertUSDateInBRLDateTime(sub.canceled_at) || 'N/A'}
                </S.Value>
              </S.Label>
              <S.Label>
                Motivo de cancelamento:{' '}
                <S.Value>{sub.cancellation_description || 'N/A'}</S.Value>
              </S.Label>
              <HeaderOptions hideOptions={isLoading}>
                <HeaderOption
                  primary
                  onClick={() => handleDownloadReceipt(sub.subscription_id)}
                >
                  <BsDownload />
                  <HeaderOptionLabel>Baixar recibo</HeaderOptionLabel>
                </HeaderOption>
                <HeaderOption
                  primary
                  onClick={() =>
                    handleDownloadContract(
                      sub.subscription_id,
                      sub.contract_file_id
                    )
                  }
                >
                  {sub.loading ? (
                    <FiLoader className="loading" />
                  ) : (
                    <BsDownload />
                  )}
                  <HeaderOptionLabel>Baixar contrato</HeaderOptionLabel>
                </HeaderOption>
              </HeaderOptions>
            </S.ContainerInfo>
            <S.ContainerStatus>
              <p>Status</p>
              {getBadgeByStatus(sub.status)}
            </S.ContainerStatus>
          </S.ContainerHistoryData>
          {index === subscriptions.length - 1 ? '' : <S.SeparatorLine />}
        </S.ContainerItem>
      ))}
      {!isLoading && !subscriptions?.length && (
        <S.ContainerItem>
          <p>Assinante sem histórico de assinaturas</p>
        </S.ContainerItem>
      )}
    </ContainerCard>
  )
}

export default SubscriptionHistorySession
