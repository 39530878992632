import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2px;
`
export const Pagination = styled.ul`
  display: flex;
  list-style-type: none;
`
export const PaginationItem = styled.button`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 15px;
  min-width: 32px;
  border: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  &.selected {
    background-color: rgba(0, 100, 150, 0.08);
  }

  &:disabled {
    pointer-events: none;
    color: #aaa;

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`
