import styled from 'styled-components'
import Colors from '../../common/colors'

export const Container = styled.div`
  width: 200px;
  height: 8px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 100px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  &:before {
    content: '';
    position: absolute;
    left: -50%;
    height: 8px;
    width: 40%;
    background-color: ${Colors.success};
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  @keyframes lineAnim {
    0% {
      left: -40%;
    }

    50% {
      left: 20%;
      width: 80%;
    }

    100% {
      left: 100%;
      width: 100%;
    }
  }
`
