import { useEffect, useRef, useState } from 'react'
import { IoMdCloudUpload } from 'react-icons/io'
import { CgFileDocument } from 'react-icons/cg'
import { BiTrash } from 'react-icons/bi'
import Breadcrumb, { ISections } from '../../../../components/Breadcrumb'
import Card from '../../../../components/Card'
import DefaultButton from '../../../../components/DefaultButton'
import Title from '../../../../components/Title'
import * as S from './styles'
import LoaderLine from '../../../../components/LoaderLine'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserData } from '../../../../services/userData'
import {
  createPendingSubscription,
  getContractReport,
  getPlansBySalesChannelId,
  getSalesChannel,
  getSubscriptionById,
  updatePendingSubscription,
  uploadContractSubscription
} from '../../../../services/plansApi'
import { CreateSubscriptionRequest } from '../../../../models/CreateSubscriptionRequest'
import PlanOption from './PlanOption'
import { Subscription } from '../../../../models/Subscription'
import { UpdateSubscriptionRequest } from '../../../../models/CreateSubscriptionRequest copy'
import { dateFileName, handleDownloadBlob } from '../../../../common/utils'

type PlansType = {
  id: string
  title: string
  description: string
  selected?: boolean
}

const StepChoosePlan = () => {
  const navigate = useNavigate()
  const { id: subscriberId, subscription_id: subscriptionId } = useParams()
  const userData = getUserData()
  const inputFile = useRef<any>(null)
  const [loading, setLoading] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)
  const [loadingPlans, setLoadingPlans] = useState(false)
  const [requireUploadDocument, setRequireUploadDocument] = useState(true)
  const [loadingSavingData, setLoadingSavingData] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [messageError, setMessageError] = useState('')
  const [downloadMessageError, setDownloadMessageError] = useState('')
  const [enableFileError, setEnableFileError] = useState(false)
  const [plans, setPlans] = useState<PlansType[]>([])
  const [subscription, setSubscription] = useState<Subscription>()
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Cadastro do Titular (passo 2 de 3)' }
  ]

  useEffect(() => {
    async function init() {
      await loadPlansBySalesChannelId()
      await loadSubscription()
      await loadSalesChannel()
    }
    init()
  }, [])

  useEffect(() => {
    if (subscription) {
      choosePlan(subscription.plan_id)
    }
  }, [subscription])

  async function loadPlansBySalesChannelId() {
    try {
      if (loadingPlans || !userData?.salesChannelId) return
      setLoadingPlans(true)
      const response = await getPlansBySalesChannelId(userData.salesChannelId)
      setLoadingPlans(false)
      const plans: PlansType[] = (response.data as []).map(
        (data: any, index) => ({
          id: data.plan_id,
          title: data.name,
          description: data.description,
          selected: index === 0
        })
      )
      await setPlans(plans)
    } catch (error) {
      console.error(error)
      setLoadingPlans(false)
    }
  }

  async function loadSubscription() {
    try {
      if (!subscriptionId) return
      const response = await getSubscriptionById(subscriptionId)
      const { data } = response
      setSubscription(data)
    } catch (error) {
      console.error(error)
    }
  }

  async function loadSalesChannel() {
    try {
      if (!userData?.salesChannelId) return
      const response = await getSalesChannel(userData.salesChannelId)
      const { data } = response
      if (data && data.payment_type) {
        setRequireUploadDocument(!(data.payment_type || []).includes('link'))
      }
    } catch (error) {
      console.error(error)
    }
  }

  function choosePlan(id: string) {
    const newPlans = [...plans].map((plan) => ({
      ...plan,
      selected: plan.id === id
    }))
    setPlans(newPlans)
  }

  function handleRemoveDocument() {
    setFile(null)
  }

  function handleFile() {
    if (!file) {
      inputFile?.current?.click()
    }
  }

  function onChangeFile(event: any) {
    event.stopPropagation()
    event.preventDefault()
    const file = event.target.files[0]
    setEnableFileError(false)
    if (file?.type === 'application/pdf') {
      setLoadingFile(true)
      setFile(file)
      setTimeout(() => {
        setLoadingFile(false)
      }, 3000)
    } else {
      setEnableFileError(true)
    }
  }

  function getSelectedPlan(): PlansType | undefined {
    return plans?.find(({ selected }) => selected)
  }

  async function handleNextStep() {
    const plan = getSelectedPlan()

    if (!plan?.id) {
      setMessageError('Por favor, selecione um plano acima para continuar.')
      return
    }

    if (!subscriberId) {
      setMessageError('Assinante não encontrado.')
      return
    }

    if (!userData?.salesChannelId) {
      setMessageError('Canal de Vendas não encontrado para o usuário logado.')
      return
    }

    if (requireUploadDocument && !file) {
      setMessageError(
        'Por favor, selecione o contrato assinado pelo assinante!'
      )
      return
    }

    try {
      setLoadingSavingData(true)
      if (subscription) {
        const data: UpdateSubscriptionRequest = {
          plan_id: plan.id,
          saleschannel_id: userData?.salesChannelId
        }
        await updatePendingSubscription(subscription.subscription_id, data)
        if (requireUploadDocument) {
          await uploadContractSubscription(subscription.subscription_id, file)
        }
        navigate(
          `/dashboard/clientes/${subscriberId}/subscriptions/${subscription.subscription_id}/payment-method`
        )
      } else {
        const data: CreateSubscriptionRequest = {
          plan_id: plan?.id,
          saleschannel_id: userData?.salesChannelId,
          subscriber_id: subscriberId
        }
        const response = await createPendingSubscription(data)
        const { subscription_id } = response.data
        if (requireUploadDocument) {
          await uploadContractSubscription(subscription_id, file)
        }
        navigate(
          `/dashboard/clientes/${subscriberId}/subscriptions/${subscription_id}/payment-method`
        )
      }
    } catch (error) {
      setLoadingSavingData(false)
      setMessageError(
        'Não foi possível criar uma nova assinatura, verifique com o suporte para mais informações.'
      )
    }
  }

  function generateContractReport() {
    setLoading(true)
    const plan = getSelectedPlan()
    if (!plan) return
    if (!subscriberId) return

    setDownloadMessageError('')
    getContractReport(plan.id, subscriberId)
      .then((res) => {
        const blob = res.data
        const fileName = dateFileName()
        handleDownloadBlob(blob, `contrato_${fileName}.pdf`)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        setDownloadMessageError(
          'Não foi possível realizar o download no momento, tente novamente em instantes.'
        )
      })
  }

  function showPlans() {
    if (loadingPlans) {
      return <S.Loading>Carregando planos...</S.Loading>
    }

    if (!plans.length) {
      return (
        <S.Loading>
          Nenhum plano encontrado para o Canal de Vendas atual.
          <br />
          Procure o suporte para mais informações!
        </S.Loading>
      )
    }

    return plans.map(({ id, title, description, selected }) => (
      <PlanOption
        id={id}
        title={title}
        description={description}
        key={id}
        onClick={() => choosePlan(id)}
        isActive={selected}
      />
    ))
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Escolha um plano'} />
        </S.ContainerTitle>
        <Card>
          <p>Escolha um plano</p>
          <S.BodyCard>{showPlans()}</S.BodyCard>
        </Card>
        {requireUploadDocument && (
          <>
            <S.AlertMessage>
              * Para dar continuidade ao cadastro imprima o contrato e faça
              upload do contrato assinado.
            </S.AlertMessage>
            <S.ContainerBoxDownload>
              <S.ContainerBodyDownload>
                <DefaultButton
                  loading={loading}
                  label="IMPRIMIR CONTRATO"
                  type="button"
                  onClick={generateContractReport}
                />
                {downloadMessageError && (
                  <S.ErrorMessage>{downloadMessageError}</S.ErrorMessage>
                )}
              </S.ContainerBodyDownload>
            </S.ContainerBoxDownload>
            <Card>
              <p>Faça upload do Contrato Assinado aqui</p>
              <S.ContainerBoxSelectFile onClick={handleFile}>
                <IoMdCloudUpload size={25} />
                <p>
                  <span>Clique aqui</span> para selecionar o arquivo PDF. Serão
                  aceitos arquivos com tamanho máximo de 10 MB.
                </p>
                <S.InputFile ref={inputFile} onChange={onChangeFile} />
              </S.ContainerBoxSelectFile>
              <div>
                {enableFileError && (
                  <S.ErrorMessage>
                    Arquivo inválido, dese ser selecionado um arquivo PDF
                    válido!
                  </S.ErrorMessage>
                )}
              </div>
              <S.ContainerFile showContainerFile={!!file}>
                <S.ContainerFileName>
                  <CgFileDocument size={25} />
                  <p>{file?.name}</p>
                </S.ContainerFileName>
                <S.ContainerFileOptions>
                  {loadingFile && <LoaderLine />}
                  {!loadingFile && (
                    <BiTrash
                      size={25}
                      title="Excluir documento"
                      onClick={handleRemoveDocument}
                    />
                  )}
                </S.ContainerFileOptions>
              </S.ContainerFile>
            </Card>
          </>
        )}
        <S.BoxButton>
          {messageError && <p>{messageError}</p>}
          <DefaultButton
            label="Avançar"
            type="button"
            loading={loadingSavingData}
            onClick={handleNextStep}
          />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default StepChoosePlan
