import jwtDecode from 'jwt-decode'
import { encode, decode } from 'js-base64';
import { TOKEN_DATA_KEY, USER_DATA_KEY } from '../common/constants'
import { TokenData, UserData } from '../models/UserData'
import { ResourceType } from '../models/ResourceType';

export function saveUserData(accessToken: string) {
  try {
    const data: any = jwtDecode(accessToken)
    const userData: UserData = {
      id: data.id,
      email: data.email,
      name: data.name,
      cpf: data.cpf,
      username: data.preferred_username,
      salesChannelId: data.sales_channel_id,
      resources: data.resources
    }
    localStorage.setItem(USER_DATA_KEY, encode(JSON.stringify(userData)))
  } catch {
    console.error("Error to save token")
  }
}

export function hasPermission(resourceType: ResourceType): boolean {
  const userData = getUserData()
  if (!userData) return false
  return userData.resources.includes(resourceType.toString())
}

export function getUserData(): UserData | null {
  return JSON.parse(decode(localStorage.getItem(USER_DATA_KEY) || '{}'))
}

export function saveTokenData(data: any) {
  localStorage.setItem(TOKEN_DATA_KEY, encode(JSON.stringify(data)))
}

export function getTokenData(): TokenData | null {
  const tokenData: any = JSON.parse(
    decode(localStorage.getItem(TOKEN_DATA_KEY) || '{}')
  )
  return {
    accessToken: tokenData.accessToken,
    tokenType: tokenData.tokenType,
  }
}
