import * as S from './styles'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'

export interface IPagination {
  totalItems: number
  perPage: number
  offset: number
  currentPage: string
  setPage?: any
  setOffset?: any
}

const Pagination: React.FC<IPagination> = ({
  perPage,
  totalItems,
  offset,
  setOffset,
  setPage,
  currentPage
}) => {
  const MAX_ITEMS = 9
  const MAX_LEFT = (MAX_ITEMS - 1) / 2

  const location = useLocation()
  const navigate = useNavigate()

  const current = Number(currentPage)
  // const current = offset ? offset / perPage + 1 : 1
  const pages = Math.ceil(totalItems / perPage)
  const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1)
  const first = Math.min(Math.max(current - MAX_LEFT, 1), maxFirst)

  const onPageChange = (page: number) => {
    setOffset((page - 1) * perPage)
    setUrlQueryString(page)
    setPage(String(page))
  }

  const setUrlQueryString = (page: number) => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    navigate({
      search: qs.stringify({
        ...queryParams,
        page
      })
    })
  }

  return (
    <S.Container>
      <S.Pagination>
        <S.PaginationItem
          onClick={() => onPageChange(current - 1)}
          disabled={current === 1}
        >
          <IoIosArrowBack />
        </S.PaginationItem>
        {Array.from({ length: Math.min(MAX_ITEMS, pages) })
          .map((_, index) => index + first)
          .map((page) => (
            <S.PaginationItem
              key={page}
              onClick={() => onPageChange(page)}
              disabled={page === current ? true : false}
            >
              {page}
            </S.PaginationItem>
          ))}
        <S.PaginationItem
          onClick={() => onPageChange(current + 1)}
          disabled={current === pages}
        >
          <IoIosArrowForward />
        </S.PaginationItem>
      </S.Pagination>
    </S.Container>
  )
}

export default Pagination
