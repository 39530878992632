import { AxiosResponse } from 'axios'
import api from '../configs/accountsApiConfigs'
import { Address } from '../models/Address'
import { CustomerResponse } from '../models/CustomerResponse'
import { Dependent } from '../models/Dependent'
import { Gender } from '../models/Gender'
import { PaginationResponse } from '../models/PaginationResponse'
import { Subscriber } from '../models/Subscriber'

export async function getCustomers(
  search: string,
  page: number,
  perPage: number = 10
): Promise<{
  data: { data: CustomerResponse[]; pagination: PaginationResponse }
}> {
  return await api.get(
    `/customers?search=${search}&page=${page}&perPage=${perPage}`
  )
}

export async function getDependent(id: string): Promise<{ data: Dependent }> {
  return await api.get(`/dependents/${id}`)
}

export async function activeDependent(
  id: string
): Promise<{ data: Dependent }> {
  return await api.put(`/dependents/${id}/active`)
}

export async function inactiveDependent(
  id: string
): Promise<{ data: Dependent }> {
  return await api.put(`/dependents/${id}/inactive`)
}

export async function getSubscriber(id: string): Promise<{ data: Subscriber }> {
  return await api.get(`/subscribers/${id}`)
}

export async function getAddress(id: string): Promise<{ data: Address }> {
  return await api.get(`/subscribers-dependents/${id}/address`)
}

export async function newSubscriber(
  data: Subscriber
): Promise<{ data: Subscriber }> {
  return await api.post(`/subscribers`, data)
}

export async function updateSubscriber(
  id: string,
  data: Subscriber
): Promise<{ data: Subscriber }> {
  return await api.put(`/subscribers/${id}`, data)
}

export function getGenders(): Promise<AxiosResponse<Gender[], any>> {
  return api.get<Gender[]>(`/genders`)
}

export async function getSubscriberReport(id: string): Promise<any> {
  return await api.get(`/subscribers/${id}/report`, {
    responseType: 'blob'
  })
}

export async function getDependentReport(id: string): Promise<any> {
  return await api.get(`/dependents/${id}/report`, {
    responseType: 'blob'
  })
}

export async function putAddress(
  data: Address,
  id: string
): Promise<{ data: Address }> {
  return await api.put(`/subscribers-dependents/${id}/address`, data)
}

export async function newDependent(
  data: Omit<Dependent, 'active' | 'subscriber'>
): Promise<{ data: Dependent }> {
  return await api.post(`/dependents`, data)
}

export async function editDependent(
  dependentId: string,
  data: Omit<Dependent, 'active' | 'subscriber' | 'address' | 'id'>
): Promise<{ data: Dependent }> {
  return await api.put(`/dependents/${dependentId}`, data)
}
