import { PaymentMethodType } from "../models/PaymentMethodType"

export function limitCaracteres(
  text: string,
  length: number,
  addEllipsis?: boolean
) {
  const textLength = (text || '').length
  let str = (text || '').substring(0, length)
  if (textLength > str.length && addEllipsis) {
    str += '...'
  }
  return str
}

export function getInitialsName(name: string): string {
  if (!name) return name
  const parts = name.split(' ').filter((part) => !!part)
  if (parts.length > 1) {
    const [part1, part2] = parts
    return `${part1[0]}${part2[0]}`
  }
  return `${parts[0][0]}`
}

export function formatBirthSex(sex?: string) {
  switch (sex) {
    case 'M':
      return 'Masculino'
    case 'F':
      return 'Feminino'

    default:
      return ''
  }
}

export function formatCPF(cpf?: string) {
  if (!cpf) return null
  return cpf
    .replace(/[^\d]/g, '')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function formatCNPJ(cnpj?: string) {
  if (!cnpj) return null
  return cnpj
    .replace(/[^\d]/g, '')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function formatBirthDate(birthDate?: string) {
  if (!birthDate) return null
  return birthDate.split('-').reverse().join('/')
}

export function formatToUSDate(brlDate: string) {
  return brlDate.split('/').reverse().join('-')
}

export function convertDate(date: Date) {
  const year = date.getFullYear()
  const month =
    date.getUTCMonth() + 1 < 10
      ? ('0' + (date.getUTCMonth() + 1)).slice(-2)
      : date.getUTCMonth() + 1
  const day =
    date.getDate() < 10 ? ('0' + date.getDate()).slice(-2) : date.getDate() + 1
  return `${year}-${month}-${day}`
}

export function convertDateBR(date: Date) {
  const year = date.getFullYear()
  const month =
    date.getUTCMonth() + 1 < 9
      ? ('0' + (date.getUTCMonth() + 1)).slice(-2)
      : date.getUTCMonth() + 1
  const day =
    date.getDate() < 9 ? ('0' + date.getDate()).slice(-2) : date.getDate()
  return `${day}/${month}/${year}`
}

export function handleDownloadBlob(blob: Blob, fileName: string) {
  const a = document.createElement('a')
  document.body.appendChild(a)
  const blobUrl = window.URL.createObjectURL(blob)
  a.href = blobUrl
  a.download = fileName
  a.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(blobUrl)
    document.body.removeChild(a)
  }, 0)
}

export function handleViewBlob(blob: Blob, fileName: string) {
  const configPage = 'width=1000,height=1200'
  const newWindow = window.open('', fileName, configPage)

  const dataUrl = window.URL.createObjectURL(blob)
  const title = newWindow!.document.createElement('title')
  const iframe = newWindow!.document.createElement('embed')

  title.appendChild(document.createTextNode(fileName))
  newWindow!.document.head.appendChild(title)

  iframe.setAttribute('src', dataUrl + '#navpanes=0')
  iframe.setAttribute('width', '100%')
  iframe.setAttribute('height', '100%')

  newWindow!.document.body.appendChild(iframe)

  setTimeout(() => {
    window.URL.revokeObjectURL(dataUrl)
  }, 100)
}

export function getDefaultPaymentMethods(): PaymentMethodType[] {
  return ['cash', 'credit_card', 'debit_card', 'link']
}

export function translatePaymentMethod(paymentMethod?: PaymentMethodType) {
  switch (paymentMethod) {
    case 'cash':
      return 'Dinheiro'
    case 'credit_card':
      return 'Cartão de Crédito'
    case 'debit_card':
      return 'Cartão de Débito'
    case 'link':
      return 'Link de Pagamento (Vindi)'
    default:
      return 'N/A'
  }
}

/**
 *
 * @param value Ex.: 2022-11-04T20:09:20.316Z
 * @return Ex.: 04/11/2022 09:20
 */
export function convertUSDateInBRLDateTime(value?: string): string | undefined {
  try {
    if (!value) return value
    const [usDate, time] = value.split('T')
    const brlDate = usDate.split('-').reverse().join('/')
    const [hour, minutes] = time.split(':')
    return `${brlDate} ${hour}:${minutes}`
  } catch (error) {
    return value
  }
}

/**
 *
 * @param value Ex.: 2022-11-04T20:09:20.316Z
 * @return Ex.: 04/11/2022
 */
 export function convertUSDateInBRLDate(value?: string): string | undefined {
  try {
    if (!value) return value
    const [usDate] = value.split('T')
    return usDate.split('-').reverse().join('/')
  } catch (error) {
    return value
  }
}

export const dateFileName = (): string | undefined => {
  try {
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const fileName = `${day}${month}${year}${hours}${minutes}${seconds}`
    return fileName
  } catch (error) {
    console.error(error)
  }
}
