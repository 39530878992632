import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Masks } from '../../../common/masks'
import { isEmpty, isValidCpf } from '../../../common/validators'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import DefaultButton from '../../../components/DefaultButton'
import Select, { IOption } from '../../../components/Select'
import TextInput from '../../../components/TextInput'
import { Title } from '../../../components/Title'
import Toast from '../../../components/Toast'
import { Profile } from '../../../models/Profile'
import { SalesChannel } from '../../../models/SalesChannel'
import {
  createUser,
  getProfiles,
  getUserById,
  updateUser
} from '../../../services/authUserApi'
import { getSalesChannels } from '../../../services/plansApi'
import * as S from './styles'

type ValueField = {
  value: string
  isInvalid?: boolean
  customMessage?: string
}

type CustomSalesChannel = {
  selected?: boolean
} & SalesChannel

const NewUser = () => {
  const { id: userId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<ValueField>({ value: '' })
  const [cpf, setCpf] = useState<ValueField>({ value: '' })
  const [email, setEmail] = useState<ValueField>({ value: '' })
  const [profile, setProfile] = useState<ValueField>({ value: '' })

  const [searchSalesChannel, setSearchSalesChannel] = useState('')

  const [messageErrorSalesChannel, setMessageErrorSalesChannel] = useState('')

  const [showSalesChannelToChoose, setShowSalesChannelToChoose] =
    useState(false)
  const [salesChannels, setSalesChannels] = useState<CustomSalesChannel[]>([])
  const [salesChannelsAdded, setSalesChannelAdded] = useState<
    CustomSalesChannel[]
  >([])

  const [profilesOptions, setProfilesOptions] = useState<IOption[]>([])
  const [profiles, setProfiles] = useState<Profile[]>([])

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Usuários', path: '/dashboard/usuarios' },
    { description: 'Novo usuário' }
  ]

  useEffect(() => {
    let requests = [getProfiles(), getSalesChannels()]
    if (userId) {
      requests = [...requests, getUserById(userId)]
    }
    axios
      .all(requests)
      .then((responses) => {
        const [profilesResponse, salesChannelsResponse, userResponse] =
          responses
        console.log('profilesResponse', profilesResponse)
        console.log('salesChannelsResponse', salesChannelsResponse)
        console.log('userResponse', userResponse)

        if (profilesResponse && profilesResponse.data) {
          const profiles = profilesResponse.data || []
          const options: IOption[] = profiles.map(
            (profile: any) => ({
              value: profile.id,
              label: profile.name
            })
          )
          setProfilesOptions(options)
          setProfiles(profiles)
        } else {
          Toast.showError(
            'Não foi possível carregar os Perfis de Acesso, tente novamente em instantes.'
          )
        }

        let allSalesChannels: any[] = []
        if (salesChannelsResponse && salesChannelsResponse.data) {
          const salesChannels = salesChannelsResponse.data || []
          setSalesChannels(salesChannels)
          allSalesChannels = salesChannels
        } else {
          Toast.showError(
            'Não foi possível carregar os Canais de Venda, tente novamente em instantes.'
          )
        }

        if (userResponse && userResponse.data) {
          const { name, cpf, email, profiles, salesChannelIds } =
            userResponse.data
          setName({ value: name })
          setCpf({ value: Masks.cpf(cpf) })
          setEmail({ value: email })
          if (profiles && profiles.length) {
            setProfile({ value: profiles[0].id })
          }
          if (salesChannelIds && salesChannelIds.length && allSalesChannels.length) {
            addEditingManySalesChannels(salesChannelIds, allSalesChannels)
          }
        } else {
          Toast.showError(
            'Não foi possível carregar o Usuário, tente novamente em instantes.'
          )
        }
      })
      .catch((erros) => {
        console.error(erros)
        Toast.showError(
          'Não foi possível carregar todos os dados, tente novamente em instantes.'
        )
      })
  }, [])

  function addEditingManySalesChannels(salesChannelIds: string[], allSalesChannels: CustomSalesChannel[]) {
    const salesChannelSelected = allSalesChannels.filter((sc) => salesChannelIds.includes(sc.id))
    setSalesChannelAdded(salesChannelSelected)

    if (salesChannelSelected.length) {
      const salesChannelSelected = allSalesChannels.filter((sc) =>
        !salesChannelIds.includes(sc.id)
      )
      setSalesChannels(salesChannelSelected)
    }
  }

  useEffect(() => {
    const profileFound = profiles.find((prof) => prof.id === profile.value)
    const isSeller = !!profileFound?.isSeller
    setShowSalesChannelToChoose(isSeller)
  }, [profile])

  function goBack() {
    if (!!userId) {
      navigate(`/dashboard/usuarios/${userId}`)
    } else {
      navigate(`/dashboard/usuarios`)
    }
  }

  function validateFields(): boolean {
    let isValid = true

    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    const cpfValue = cpf.value.trim()
    if (isEmpty(cpfValue)) {
      setCpf({ ...cpf, isInvalid: true })
      isValid = false
    }

    const cpfCleaned = Masks.onlyNumbers(cpfValue)
    if (!isValidCpf(cpfCleaned)) {
      setCpf({ ...cpf, isInvalid: true, customMessage: 'CPF inválido' })
      isValid = false
    }

    if (isEmpty(profile.value)) {
      setProfile({ ...profile, isInvalid: true })
      isValid = false
    }

    if (showSalesChannelToChoose) {
      if (!salesChannelsAdded.length) {
        setMessageErrorSalesChannel('Adicione ao menos um Canal de Venda!')
        isValid = false
      }
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!validateFields()) return
    if (loading) return
    try {
      let salesChannelIds = showSalesChannelToChoose
        ? salesChannelsAdded.map((sca) => sca.id)
        : []
      const profilesIds = [profile.value]
      const data = {
        name: name.value,
        email: email.value,
        cpf: Masks.onlyNumbers(cpf.value.trim()),
        profilesIds,
        salesChannelIds
      }
      setLoading(true)
      let userIdToNavigate = null
      if (!!userId) {
        const response = await updateUser(userId, data)
        Toast.showSuccess('Usuário atualizado com sucesso!')
        userIdToNavigate = response.data.id
      } else {
        const response = await createUser(data)
        Toast.showSuccess('Usuário criado com sucesso!')
        userIdToNavigate = response.data.id
      }
      setLoading(false)
      navigate(`/dashboard/usuarios/${userIdToNavigate}`)
    } catch (error: any) {
      setLoading(false)
      const data = error.response.data
      if (data?.type === 'USER_ALREADY_EXISTS') {
        return Toast.showError('CPF já existe!')
      }
      if (!!userId) {
        Toast.showError(
          'Não estamos conseguindo editar o usuário no momento, tente novamente em instantes!'
        )
      } else {
        Toast.showError(
          'Não estamos conseguindo criar novos usuário no momento, tente novamente em instantes!'
        )
      }
    }
  }

  function handleSelectItemToAdd(id: string) {
    const newSalesChannels = salesChannels.map((salesChannel) => ({
      ...salesChannel,
      selected: salesChannel.id === id
    }))
    setSalesChannels(newSalesChannels)
  }

  function handleSelectItemToRemove(id: string) {
    const newSalesChannels = salesChannelsAdded.map((salesChannel) => ({
      ...salesChannel,
      selected: salesChannel.id === id
    }))
    setSalesChannelAdded(newSalesChannels)
  }

  function handleAddSalesChannel() {
    const [salesChannelSelected] = salesChannels.filter(
      (sales) => sales.selected
    )
    if (!salesChannelSelected) {
      Toast.showError('Selecione um Canal de Venda para adicionar!')
      return
    }

    let newSalesChannelsAddedSelected = [...salesChannelsAdded]
    newSalesChannelsAddedSelected.push(salesChannelSelected)
    newSalesChannelsAddedSelected = newSalesChannelsAddedSelected.map(
      (sales) => ({
        ...sales,
        selected: false
      })
    )
    setSalesChannelAdded(newSalesChannelsAddedSelected)

    const newSalesChannels = [...salesChannels].filter(
      (res) => res.id !== salesChannelSelected.id
    )
    setSalesChannels(newSalesChannels)
  }

  function handleRemoveSalesChannel() {
    const [salesChannelsSelected] = salesChannelsAdded.filter(
      (sales) => sales.selected
    )
    if (!salesChannelsSelected) {
      Toast.showError('Selecione um Canal de Venda para remover!')
      return
    }

    let newSalesChannels = [...salesChannels]
    newSalesChannels.push(salesChannelsSelected)
    newSalesChannels = newSalesChannels.map((sales) => ({
      ...sales,
      selected: false
    }))
    setSalesChannels(newSalesChannels)

    const newSalesChannelsSelected = [...salesChannelsAdded].filter(
      (sales) => sales.id !== salesChannelsSelected.id
    )
    setSalesChannelAdded(newSalesChannelsSelected)
  }

  function filterSalesChannels(
    customSalesChannel: CustomSalesChannel
  ): boolean {
    return customSalesChannel.name
      .toLowerCase()
      .includes(searchSalesChannel.toLowerCase())
  }

  return (
    <S.ContainerPage>
      <S.Container onSubmit={handleSubmit}>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title
            title={!!userId ? 'Editar Usuário' : 'Cadastrar Novo Usuário'}
          />
        </S.ContainerTitle>
        <S.ContainerCard>
          <S.Row>
            <S.Col>
              <S.Label>Nome *</S.Label>
              <TextInput
                maxLength={100}
                value={name.value}
                onChange={(value) => setName({ value: Masks.upperCase(value) })}
                messageError={name.isInvalid ? 'Campo obrigatório' : ''}
              />
            </S.Col>
            <S.Col>
              <S.Label>E-mail</S.Label>
              <TextInput
                maxLength={100}
                type="email"
                value={email.value}
                onChange={(value) => setEmail({ value })}
              />
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>CPF *</S.Label>
              <TextInput
                maxLength={100}
                value={cpf.value}
                onChange={(value) => setCpf({ value: Masks.cpf(value) })}
                messageError={
                  cpf.isInvalid ? cpf.customMessage || 'Campo obrigatório' : ''
                }
              />
            </S.Col>
            <S.Col>
              <S.Label>Perfil de Acesso *</S.Label>
              <Select
                title={'Escolha um Perfil de Acesso'}
                options={profilesOptions}
                value={profile.value}
                onChange={(value) => setProfile({ value: value.target.value })}
                messageError={profile.isInvalid ? 'Campo obrigatório' : ''}
              />
            </S.Col>
          </S.Row>
          {showSalesChannelToChoose && (
            <S.Row>
              <S.Col>
                <S.SubTitle>Todos os Canais de Venda:</S.SubTitle>
                <S.BoxListItem>
                  <S.TextInputContainer>
                    <TextInput
                      value={searchSalesChannel}
                      onChange={setSearchSalesChannel}
                      placeholder="Pesquise aqui pelo nome!"
                    />
                  </S.TextInputContainer>
                  <S.ContainerListItems>
                    {salesChannels
                      .filter(filterSalesChannels)
                      .map((salesChannel) => (
                        <S.ContainerItem
                          key={salesChannel.id}
                          selected={!!salesChannel.selected}
                          onClick={() => handleSelectItemToAdd(salesChannel.id)}
                        >
                          {salesChannel.name}
                        </S.ContainerItem>
                      ))}
                  </S.ContainerListItems>
                </S.BoxListItem>
              </S.Col>
              <S.Col>
                <S.ContainerActions>
                  <DefaultButton
                    label="Adicionar"
                    onClick={handleAddSalesChannel}
                  />
                  <DefaultButton
                    label="Remover"
                    secondary
                    onClick={handleRemoveSalesChannel}
                  />
                </S.ContainerActions>
              </S.Col>
              <S.Col>
                <S.SubTitle>Canais de Venda adicionados:</S.SubTitle>
                <S.BoxListItem>
                  <S.ContainerListItemsAdd>
                    {salesChannelsAdded.map((salesChannel) => (
                      <S.ContainerItem
                        key={salesChannel.id}
                        selected={!!salesChannel.selected}
                        onClick={() =>
                          handleSelectItemToRemove(salesChannel.id)
                        }
                      >
                        {salesChannel.name}
                      </S.ContainerItem>
                    ))}
                  </S.ContainerListItemsAdd>
                </S.BoxListItem>
                {messageErrorSalesChannel && (
                  <S.MessageError>{messageErrorSalesChannel}</S.MessageError>
                )}
              </S.Col>
            </S.Row>
          )}
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
          <DefaultButton label="Salvar" type="submit" loading={loading} />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default NewUser
