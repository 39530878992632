import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  background-color: #eeeeef;
`

export const Body = styled.div`
  display: flex;
  height: 100%;
`

export const Children = styled.div`
  width: 100%;
  overflow-y: auto;
`
