import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Masks } from '../../common/masks'
import { isEmpty, isValidCpf } from '../../common/validators'
import DefaultButton from '../../components/DefaultButton'
import TextInput from '../../components/TextInput'
import Toast from '../../components/Toast'
import { recoverPassword } from '../../services/publicApis'
import * as S from './styles'

const Recover = () => {
  const [cpf, setCpf] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(event: any) {
    try {
      event.preventDefault()
      setErrorMessage('')

      const cpfCleaned = Masks.onlyNumbers(cpf.trim())
      if (isEmpty(cpfCleaned)) {
        setErrorMessage('Preencha todos os campos')
        return
      }
      if (!isValidCpf(cpfCleaned)) {
        setErrorMessage('CPF inválido')
        return
      }

      setLoading(true)
      await recoverPassword(cpfCleaned)
      setLoading(false)
      Toast.showSuccess('Foi enviado um e-mail com a nova senha!')
      navigate('/login')
    } catch (error: any) {
      setLoading(false)
      const data = error.response.data
      if (data.type === 'USER_IS_INACTIVE') {
        setErrorMessage(
          'Usuário inativado, contate o suporte para mais informações!'
        )
      } else if (data.type === 'USER_DOES_NOT_HAVE_EMAIL') {
        setErrorMessage(
          'Usuário sem e-mail cadastrado, contate o suporte para mais informações!'
        )
      } else {
        setErrorMessage('Usuário inválidos!')
      }
    }
  }
  return (
    <S.Container>
      <S.FormContainer onSubmit={handleSubmit}>
        <S.Title>Recuperação de Senha</S.Title>
        <TextInput
          placeholder="CPF"
          onChange={(value) => setCpf(Masks.cpf(value))}
          value={cpf}
        />

        {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        <S.BoxButton>
          <DefaultButton label="Solicitar" type="submit" loading={loading} />
          <Link to="/login">Voltar</Link>
        </S.BoxButton>
      </S.FormContainer>
    </S.Container>
  )
}

export default Recover
