import styled from 'styled-components'
import Colors from '../../../common/colors'

export interface IItemMenu {
  active?: boolean
  center?: boolean
  isLink?: boolean
}

export const ItemMenuContainer = styled.div<IItemMenu>`
  height: 48px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  cursor: pointer;
  color: ${Colors.white};
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 6px;
  ${({ active }) =>
    !!active ? `background-color: rgba(255, 255, 255, 0.16);` : ``}
  ${({ active }) => (!!active ? `color: ${Colors.white};` : ``)}

  > svg {
    margin-right: 8px;
  }

  &:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.16);
    ${({ isLink }) => (!!isLink ? `color: #292E60;` : ``)}
    ${({ isLink }) => (!!isLink ? `background-color: transparent;` : ``)}
    ${({ isLink }) => (!!isLink ? `text-decoration: underline;` : ``)}
  }
`

export const ItemMenuName = styled.div`
  font-size: 14px;
  margin-left: 16px;
`

export const ContainerName = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerIcon = styled.span`
  display: flex;
  align-items: center;
  min-width: 25px;
`
