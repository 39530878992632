import styled from "styled-components";
import Colors from "../../common/colors";

interface IButton {
  secondary?: boolean;
}

export const Button = styled.button<IButton>`
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 8px;
  border-radius: 8px;
  border: 1px solid ${Colors.primary};
  background-color: ${Colors.primary};
  color: rgb(255, 255, 255);
  ${({ secondary }) => !!secondary ? `background-color: transparent;` : ``}
  ${({ secondary }) => !!secondary ? `color: ${Colors.primary};` : ``}

  svg {
    animation: rotate 1.5s linear infinite;

    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
  }


  &:hover {
    opacity: 0.9;
  }
`;
