import { WorkSalesChannelProvider } from './hooks/useSelectSalesChannel'
import RoutesApp from './routes'
import GlobalStyle from './styles/global'

function App() {
  return (
    <>
      <WorkSalesChannelProvider>
        <RoutesApp />
        <GlobalStyle />
      </WorkSalesChannelProvider>
    </>
  )
}

export default App
