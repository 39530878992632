import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Masks } from '../../../common/masks'
import { isEmpty, isEmptyArray } from '../../../common/validators'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import DefaultButton from '../../../components/DefaultButton'
import TextArea from '../../../components/TextArea'
import TextInput from '../../../components/TextInput'
import { Title } from '../../../components/Title'
import Toast from '../../../components/Toast'
import { Resource } from '../../../models/Resource'
import {
  createProfile,
  getProfile,
  getResources,
  updateProfile
} from '../../../services/authUserApi'
import * as S from './styles'

type ValueField = {
  value: string
  isInvalid?: boolean
  customMessage?: string
}

type CustomResource = {
  selected?: boolean
} & Resource

const AccessProfileListNew = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<ValueField>({ value: '' })
  const [description, setDescription] = useState('')
  const [isSeller, setIsSeller] = useState(false)
  const [resources, setResources] = useState<CustomResource[]>([])
  const [resourcesAdded, setResourcesAdded] = useState<CustomResource[]>([])
  const [selectOneResource, setSelectOneResource] = useState(false)

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Perfis de Acesso', path: '/dashboard/perfis-de-acesso' },
    { description: id ? 'Editar' : 'Novo' }
  ]

  useEffect(() => {
    async function init() {
      const resources = await loadResources()
      if (!!id) {
        await loadProfile(resources)
      }
    }
    init()
  }, [])

  async function loadResources() {
    try {
      const resources = await getResources()
      setResources(resources.data)
      return resources.data
    } catch {
      Toast.showError(
        'Não está sendo possível carregar os recursos no momento.'
      )
      return []
    }
  }

  async function loadProfile(resources?: Resource[]) {
    try {
      if (!id) return
      const response = await getProfile(id)
      setName({ value: response.data.name })
      setDescription(response.data.description)
      setIsSeller(response.data.isSeller)
      const resourcesEditing = response.data.resources || []
      const allResources = resources || []
      if (resourcesEditing.length && allResources.length) {
        const resourcesIds = resourcesEditing.map((res) => res.id)
        addEditingManyResources(resourcesIds, allResources)
      }
    } catch {
      Toast.showError(
        'Não está sendo possível carregar os recursos no momento.'
      )
    }
  }

  function goBack() {
    if (id) {
      navigate(`/dashboard/perfis-de-acesso/${id}`)
    } else {
      navigate(`/dashboard/perfis-de-acesso`)
    }
  }

  function handleSelectItemToAdd(id: string) {
    const newResources = resources.map((resource) => ({
      ...resource,
      selected: resource.id === id
    }))
    setResources(newResources)
  }

  function handleSelectItemToRemove(id: string) {
    const newResources = resourcesAdded.map((resource) => ({
      ...resource,
      selected: resource.id === id
    }))
    setResourcesAdded(newResources)
  }

  function addEditingManyResources(
    resourcesIds: string[],
    allResources: Resource[]
  ) {
    const resourcesSelected = allResources.filter((resource) =>
      resourcesIds.includes(resource.id)
    )
    setResourcesAdded(resourcesSelected)

    if (resourcesSelected.length) {
      const resourcesSelected = allResources.filter((resource) =>
        !resourcesIds.includes(resource.id)
      )
      setResources(resourcesSelected)
    }
  }

  function handleAddResource() {
    const [resource] = resources.filter((resource) => resource.selected)
    if (!resource) {
      Toast.showError('Selecione um recurso para adicionar!')
      return
    }

    let newResourcesSelected = [...resourcesAdded]
    newResourcesSelected.push(resource)
    newResourcesSelected = newResourcesSelected.map((newResource) => ({
      ...newResource,
      selected: false
    }))
    setResourcesAdded(newResourcesSelected)

    const newResources = [...resources].filter((res) => res.id !== resource.id)
    setResources(newResources)
  }

  function handleRemoveResource() {
    const [resource] = resourcesAdded.filter((resource) => resource.selected)
    if (!resource) {
      Toast.showError('Selecione um recurso para remover!')
      return
    }

    let newResources = [...resources]
    newResources.push(resource)
    newResources = newResources.map((newResource) => ({
      ...newResource,
      selected: false
    }))
    setResources(newResources)

    const newResourcesSelected = [...resourcesAdded].filter(
      (res) => res.id !== resource.id
    )
    setResourcesAdded(newResourcesSelected)
  }

  function validateFields(): boolean {
    let isValid = true
    setSelectOneResource(false)

    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    if (isEmptyArray(resourcesAdded)) {
      setSelectOneResource(true)
      isValid = false
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    try {
      if (!validateFields()) return
      if (loading) return
      setLoading(true)

      const resourcesIds = resourcesAdded.map((resource) => resource.id)
      const payload = {
        name: name.value,
        description,
        isSeller,
        resourcesIds
      }
      if (!!id) {
        const response = await updateProfile(id, payload)
        Toast.showSuccess('Recurso atualizado com sucesso!')
        navigate(`/dashboard/perfis-de-acesso/${response.data.id}`)
      } else {
        const response = await createProfile(payload)
        Toast.showSuccess('Recurso criado com sucesso!')
        navigate(`/dashboard/perfis-de-acesso/${response.data.id}`)
      }
    } catch (error) {
      Toast.showError('Não está sendo possível salvar o recurso!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.ContainerPage>
      <S.Container onSubmit={handleSubmit}>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={id ? 'Editar Perfil de Acesso' : 'Cadastrar Perfil de Acesso'} />
        </S.ContainerTitle>
        <S.ContainerCard>
          <S.Row>
            <S.Col>
              <S.HeaderTitle>Dados do perfil</S.HeaderTitle>
              <S.SubTitle>Os campos com (*) são obrigatórios</S.SubTitle>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Nome *</S.Label>
              <TextInput
                maxLength={100}
                value={name.value}
                onChange={(value) => setName({ value })}
                messageError={name.isInvalid ? 'Campo obrigatório' : ''}
              />
            </S.Col>
            <S.Col />
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Descrição</S.Label>
              <TextArea value={description} onChange={setDescription} />
            </S.Col>
          </S.Row>
          <S.Row>
            <S.ContainerCheckbox>
              <S.Checkbox
                checked={isSeller}
                onChange={() => setIsSeller(!isSeller)}
              />
              <S.CheckboxLabel>É um perfil de vendas?</S.CheckboxLabel>
            </S.ContainerCheckbox>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.HeaderTitle>
                Escolha as funcionalidades deste perfil de acesso *
              </S.HeaderTitle>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.SubTitle>Todos os recursos:</S.SubTitle>
              <S.ContainerListItem>
                {resources.map((resource) => (
                  <S.ContainerItem
                    key={resource.id}
                    selected={!!resource.selected}
                    onClick={() => handleSelectItemToAdd(resource.id)}
                  >
                    {resource.name}
                  </S.ContainerItem>
                ))}
              </S.ContainerListItem>
            </S.Col>
            <S.Col>
              <S.ContainerActions>
                <DefaultButton label="Adicionar" onClick={handleAddResource} />
                <DefaultButton
                  label="Remover"
                  secondary
                  onClick={handleRemoveResource}
                />
              </S.ContainerActions>
            </S.Col>
            <S.Col>
              <S.SubTitle>Recursos adicionados:</S.SubTitle>
              <S.ContainerListItem>
                {resourcesAdded.map((resource) => (
                  <S.ContainerItem
                    key={resource.id}
                    selected={!!resource.selected}
                    onClick={() => handleSelectItemToRemove(resource.id)}
                  >
                    {resource.name}
                  </S.ContainerItem>
                ))}
              </S.ContainerListItem>
              {selectOneResource && (
                <S.MessageError>Adicione ao menos um recurso.</S.MessageError>
              )}
            </S.Col>
          </S.Row>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
          <DefaultButton label="Salvar" type="submit" loading={loading} />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default AccessProfileListNew
