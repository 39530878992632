export const emissionOrgan = [
  {
    value: 'ABNC',
    label: 'ABNC - Academia Brasileira de Neurocirurgia'
  },
  {
    value: 'AGU',
    label: 'AGU - Advocacia-Geral da União'
  },
  {
    value: 'ANAC',
    label: 'ANAC - Agência Nacional de Aviação Civil'
  },
  {
    value: 'CAER',
    label: 'CAER - Clube de Aeronáutica'
  },
  {
    value: 'CAU',
    label: 'Conselho de Arquitetura e Urbanismo'
  },
  {
    value: 'CBM',
    label: 'Corpo de Bombeiro Militar'
  },
  {
    value: 'CRT',
    label: 'Conselho Regional dos Técnicos Industriais'
  },
  {
    value: 'CRTA',
    label: 'Conselho Regional de Técnicos de Administração'
  },
  {
    value: 'CTPS',
    label: 'Carteira de Trabalho e Previdência Social'
  },
  {
    value: 'CV',
    label: 'Cartório Civil'
  },
  {
    value: 'DELEMIG',
    label: 'Delegacia de Polícia de Imigração'
  },
  {
    value: 'DGPC',
    label: 'DGPC - Diretoria Geral da Polícia Civil'
  },
  {
    value: 'DIC',
    label: 'Diretoria de Identificação Civil'
  },
  {
    value: 'DICC',
    label: 'DIC - Diretoria de Identificação Civil e Criminal'
  },
  {
    value: 'DPF',
    label: 'DPF - Departamento de Polícia Federal'
  },
  {
    value: 'DPTC',
    label: 'DPTC - Departamento de Polícia Técnico Científica'
  },
  {
    value: 'FGTS',
    label: 'FGTS - Fundo de Garantia do Tempo de Serviço'
  },
  {
    value: 'FUNAI',
    label: 'Fundação Nacional do Índio'
  },
  {
    value: 'GEJSP',
    label: 'Gerência de Estado de Justiça, Segurança Pública e Cidadania'
  },
  {
    value: 'GEJSPC',
    label: 'Gerência de Estado de Justiça, Segurança Pública e Cidadania'
  },
  {
    value: 'GEJUSPC',
    label: 'Gerência de Estado de Justiça, Segurança Pública e Cidadania'
  },
  {
    value: 'I CLA',
    label: 'Carteira de Identidade Classista'
  },
  {
    value: 'IIACM',
    label: 'Instituto de Identificação Aderson Conceição de Melo'
  },
  {
    value: 'IICC',
    label: 'Instituto de Identificação Civil e Criminal'
  },
  {
    value: 'MTPS',
    label: 'Ministério do Trabalho e Previdência Social'
  },
  {
    value: 'NUMIG',
    label: 'Núcleo de Polícia de Imigração'
  },
  {
    value: 'OAB',
    label: 'Ordem dos Advogados do Brasil'
  },
  {
    value: 'OMB',
    label: 'Ordens dos Músicos do Brasil'
  },
  {
    value: 'PC',
    label: 'PC - Polícia Civil'
  },
  {
    value: 'PF',
    label: 'PF - Polícia Federal'
  },
  {
    value: 'PM',
    label: 'PM - Polícia Militar'
  },
  {
    value: 'SCC',
    label: 'SCC - Secretaria de Estado da Casa Civil'
  },
  {
    value: 'SECC',
    label: 'SECC - Secretaria de Estado da Casa Civil'
  },
  {
    value: 'SEDS',
    label: 'SEDS - Secretaria de Estado da Defesa Social'
  },
  {
    value: 'SEGUP',
    label:
      'SEGUP - Secretaria de Estado da Segurança Pública e da Defesa Social'
  },
  {
    value: 'SEJSP',
    label: 'SEJSP - Secretaria de Estado de Justiça e Segurança Pública'
  },
  {
    value: 'SEJUSP',
    label: 'SEJUSP - Secretaria de Estado de Justiça e Segurança Pública'
  },
  {
    value: 'SEPC',
    label: 'SEPC - Secretaria de Estado da Polícia Civil'
  },
  {
    value: 'SES',
    label: 'SES - Secretaria de Estado da Segurança'
  },
  {
    value: 'SESC',
    label: 'SESC - Secretaria de Estado da Segurança e Cidadania'
  },
  {
    value: 'SESEG',
    label: 'SESEG - Secretaria Estadual de Segurança'
  },
  {
    value: 'SESP',
    label: 'SESP - Secretaria de Estado da Segurança Pública'
  },
  {
    value: 'SESPDC',
    label: 'Secretaria de Estado de Segurança Publica e Defesa do Cidadão'
  },
  {
    value: 'SESPDS',
    label: 'Secretaria de Estado de Segurança Pública e Defesa Social'
  },
  {
    value: 'SGPC',
    label: 'Superintendência Geral de Polícia Civil'
  },
  {
    value: 'SGPJ',
    label: 'Superintendência Geral de Polícia Judiciária'
  },
  {
    value: 'SIM',
    label: 'Serviço de Identificação da Marinha'
  },
  {
    value: 'SJ',
    label: 'Secretaria da Justiça'
  },
  {
    value: 'SJCDH',
    label: 'Secretaria da Justiça e dos Direitos Humanos'
  },
  {
    value: 'SJDS',
    label: 'Secretaria Coordenadora de Justiça e Defesa Social'
  },
  {
    value: 'SJS',
    label: 'Secretaria da Justiça e Segurança'
  },
  {
    value: 'SJTC',
    label: 'Secretaria da Justiça do Trabalho e Cidadania'
  },
  {
    value: 'SJTS',
    label: 'Secretaria da Justiça do Trabalho e Segurança'
  },
  {
    value: 'SNJ',
    label: 'Secretaria Nacional de Justiça / Departamento de Estrangeiros'
  },
  {
    value: 'SPMAF',
    label: 'Serviço de Polícia Marítima, Aérea e de Fronteiras'
  },
  {
    value: 'SSP',
    label: 'SSP - Secretaria de Segurança Pública'
  },
  {
    value: 'TJAEM',
    label: 'Tribunal Arbitral e Mediação dos Estados Brasileiros'
  },
  {
    value: 'XXX',
    label: 'Orgão Estrangeiro'
  },
  {
    value: 'ZZZ',
    label: 'Outro'
  }
]
