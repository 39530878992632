import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultButton from '../../../components/DefaultButton'
import { SearchInput } from '../../../components/SearchInput'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import { Title } from '../../../components/Title'
import * as S from './styles'
import { Table } from '../../../styles/Table'
import { Profile } from '../../../models/Profile'
import { limitCaracteres } from '../../../common/utils'
import { getProfiles } from '../../../services/authUserApi'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

const AccessProfileList = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [profiles, setProfiles] = useState<Profile[]>([])

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Perfis de Acesso', path: '/dashboard/perfis-de-acesso' }
  ]

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true)
        const profiles = await getProfiles()
        setIsLoading(false)
        setProfiles(profiles.data)
      } finally {
        setIsLoading(false)
      }
    }
    init()
  }, [])

  function filter(profile: Profile) {
    const hasName = profile.name.toLowerCase().includes(search.toLowerCase())
    return hasName
  }

  function handleToShow(profileId: string) {
    navigate(`/dashboard/perfis-de-acesso/${profileId}`)
  }

  function getContainerTable() {
    if (isLoading) {
      return <S.EmptyArea>Carregando...</S.EmptyArea>
    }

    const filteredProfiles = (profiles || []).filter(filter)

    if (!filteredProfiles.length) {
      return <S.EmptyArea>Nenhum perfil de acesso encontrado</S.EmptyArea>
    }

    return (
      <Table enableShadow enableHover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Perfil de vendedor?</th>
          </tr>
        </thead>
        <tbody>
          {filteredProfiles.map((profile) => (
            <tr key={profile.id} onClick={() => handleToShow(profile.id)}>
              <td>{limitCaracteres(profile.name, 40, true)}</td>
              <td>{limitCaracteres(profile.description, 40, true)}</td>
              <td>
                <strong>{profile.isSeller ? 'Sim' : 'Não'}</strong>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Perfis de Acesso'} />
          {hasPermission(ResourceType.CREATE_ACCESS_PROFILE) && (
            <DefaultButton
              label="Novo Perfil"
              type="button"
              loading={false}
              onClick={() => navigate(`/dashboard/perfis-de-acesso/novo`)}
            />
          )}
        </S.ContainerTitle>
        <SearchInput
          placeholder="Busque pelo nome do perfil de acesso"
          onChange={setSearch}
          value={search}
        />
        <S.ContainerTable>{getContainerTable()}</S.ContainerTable>
      </S.Container>
    </S.ContainerPage>
  )
}

export default AccessProfileList
