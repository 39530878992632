import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import TextInput from '../../../components/TextInput'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { Masks } from '../../../common/masks'
import DefaultButton from '../../../components/DefaultButton'
import { IOption } from '../../../components/Select'
import {
  activeSalesChannel,
  getSalesChannel,
  inactiveSalesChannel,
  putSalesChannel
} from '../../../services/plansApi'
import { useNavigate, useParams } from 'react-router-dom'
import { MdCheck, MdClose, MdEdit } from 'react-icons/md'
import Toast from '../../../components/Toast'
import {
  getDefaultPaymentMethods,
  translatePaymentMethod
} from '../../../common/utils'

const NewSalesChannel = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [messageError, setMessageError] = useState('')
  const [salesChannelId, setSalesChannelId] = useState('')
  const [name, setName] = useState('')
  const [status, setStatus] = useState(false)
  const [nameFantasy, setNameFantasy] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [cnpjInvoice, setCnpjInvoice] = useState('')
  const [office_type, setOfficeType] = useState('')
  const [cep, setCep] = useState('')
  const [paymentType, setPaymentType] = useState([''])
  const [paymentError, setPaymentError] = useState(false)
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [district, setDistrict] = useState('')
  const [category, setCategory] = useState('')
  const [paymentTypeTemp, setPaymentTypeTemp] = useState<IOption[]>([])
  const [inactivating, setInactivating] = useState(false)
  const [editing, setEditing] = useState(false)

  const paymentTypes: IOption[] = getDefaultPaymentMethods().map((pm) => ({
    value: pm,
    label: translatePaymentMethod(pm)
  }))

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Canais de Venda', path: '/dashboard/canais-de-venda' },
    {
      description: 'Cadastro do Canal de Venda',
      path: '/dashboard/canais-de-venda'
    }
  ]

  useEffect(() => {
    getSalesChannelById()
  }, [])

  function handleChange(selectedOption: any) {
    const payment = selectedOption.map((item: any) => item.value)
    setPaymentType(payment)
    setPaymentTypeTemp(selectedOption)
    setPaymentError(false)
  }

  function handlePayment(items: string[]) {
    const value = paymentTypes.filter((pm) => items.includes(pm.value))
    handleChange(value)
    setPaymentError(false)
    return
  }

  async function getSalesChannelById() {
    try {
      if (!id) return
      const response = await getSalesChannel(id)
      const { data } = response
      setSalesChannelId(data.id)
      setStatus(data.active)
      setName(data.name)
      setNameFantasy(data.name_fantasy)
      setCnpj(data.cnpj)
      setCnpjInvoice(data.invoicing_cnpj)
      setCep(data.cep)
      handlePayment(data.payment_type)
      setOfficeType(data.office_type)
      setCategory(data.type_saleschannel.name)
      setStreet(data.street)
      setDistrict(data.district)
      setNumber(data.number)
      setCity(data.city)
      setState(data.state)
      setComplement(data.complement)
    } catch (error) {
      console.error(error)
    }
  }

  function validateFields(): boolean {
    let isValid = true

    if (!Array.isArray(paymentType)) {
      isValid = false
    }

    if (paymentType.length === 0) {
      setPaymentError(true)
      isValid = false
    }

    return isValid
  }

  async function handleEditSalesChannel(event: any) {
    setEditing(true)
    navigate(`/dashboard/canais-de-venda/${salesChannelId}/edit`)
  }

  async function handleChangePayment() {
    try {
      setMessageError('')
      if (editing) {
        if (!validateFields() || paymentError) return

        const response = await putSalesChannel(salesChannelId, {
          id: salesChannelId,
          payment_type: paymentType
        })
        if (response.status === 200) {
          Toast.showSuccess('Canal de Venda alterado com Sucesso!')
          navigate(`/dashboard/canais-de-venda`)
        }
      }
    } catch (error) {
      Toast.showError('Não foi possível alterar o Canal de Venda!')
      const err = error as any
      setMessageError(err.response.data.error.description)
    }
  }

  async function handleStatusSalesChannel() {
    try {
      if (!status) {
        await activeSalesChannel(salesChannelId)
        setStatus(true)
        Toast.showSuccess('Canal de Venda ativado!')
      } else {
        await inactiveSalesChannel(salesChannelId)
        Toast.showError('Canal de Venda inativado!')
        setStatus(false)
      }
    } catch {
      Toast.showError('Não foi possível alterar o Canal de Venda!')
    }
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Cadastro do Canal de Venda'} />
        </S.ContainerTitle>
        <S.ContainerCard>
          <S.ContainerBlock>
            <S.Row>
              <S.Col>Dados do canal de venda</S.Col>
              <S.Col></S.Col>
              <S.Col>
                <S.BoxOption>
                  {status && (
                    <S.InactiveSalesChannel onClick={handleStatusSalesChannel}>
                      <S.InactiveSalesChannelOption>
                        <MdClose size={20} />
                        {inactivating
                          ? 'Inativando....'
                          : 'Inativar Canal de Venda'}
                      </S.InactiveSalesChannelOption>
                    </S.InactiveSalesChannel>
                  )}
                  {!status && (
                    <S.InactiveSalesChannel onClick={handleStatusSalesChannel}>
                      <S.ActiveSalesChannelOption>
                        <MdCheck size={20} />
                        {inactivating
                          ? 'Ativando....'
                          : 'Ativar Canal de Venda'}
                      </S.ActiveSalesChannelOption>
                    </S.InactiveSalesChannel>
                  )}

                  {editing === false && (
                    <S.EditSalesChannel onClick={handleEditSalesChannel}>
                      <S.EditSalesChannelOption>
                        <MdEdit size={20} />
                        {editing ? '' : 'Editar'}
                      </S.EditSalesChannelOption>
                    </S.EditSalesChannel>
                  )}
                </S.BoxOption>
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CNPJ</S.Label>
                <TextInput disabled value={Masks.cnpj(cnpj)} />
              </S.Col>
              <S.Col>
                <S.Label>Razão Social</S.Label>
                <TextInput disabled value={name} />
              </S.Col>
              <S.Col>
                <S.Label>Nome Fantasia</S.Label>
                <TextInput disabled value={nameFantasy} />
              </S.Col>
            </S.Row>

            <S.Row>
              <S.Col>
                <S.Label>Categoria</S.Label>
                <TextInput disabled value={category} />
              </S.Col>
              <S.Col>
                <S.Label>Tipo de Pagamento *</S.Label>
                <S.CustonSelect
                  isDisabled={!editing}
                  classNamePrefix="Select"
                  isMulti
                  closeMenuOnSelect={false}
                  options={paymentTypes}
                  onChange={handleChange}
                  value={paymentTypeTemp}
                  hideSelectedOptions={false}
                />
                {paymentError && (
                  <S.MessageError>
                    {paymentError ? 'É preciso preencher ao menos 1 opção' : ''}
                  </S.MessageError>
                )}
              </S.Col>
              <S.Col>
                <S.Label>Tipo</S.Label>
                <TextInput disabled value={office_type} />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CNPJ de Faturamento *</S.Label>
                <TextInput disabled value={Masks.cnpj(cnpjInvoice)} />
              </S.Col>
              <S.Col></S.Col>
              <S.Col></S.Col>
            </S.Row>
          </S.ContainerBlock>
          <S.ContainerBlock>
            <S.Row>
              <S.Col>Endereço</S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CEP</S.Label>
                <TextInput disabled value={Masks.cep(cep)} />
              </S.Col>
              <S.Col>
                <S.Label>Logradouro</S.Label>
                <TextInput disabled maxLength={50} value={street} />
              </S.Col>
              <S.ColFlex>
                <S.Col>
                  <S.Label>Número</S.Label>
                  <TextInput disabled value={number} />
                </S.Col>
                <S.Col>
                  <S.Label>Complemento</S.Label>
                  <TextInput disabled value={complement} />
                </S.Col>
              </S.ColFlex>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Bairro</S.Label>
                <TextInput disabled value={district} />
              </S.Col>

              <S.Col>
                <S.Label>Cidade</S.Label>
                <TextInput disabled value={city} />
              </S.Col>
              <S.Col>
                <S.Label>Estado</S.Label>
                <TextInput disabled value={state} />
              </S.Col>
            </S.Row>
          </S.ContainerBlock>
          <S.BoxButton>
            <DefaultButton
              label="Voltar"
              secondary
              type="button"
              loading={false}
              onClick={() => navigate('/dashboard/canais-de-venda')}
            />
            <S.BoxButton>
              {messageError && (
                <S.MessageErrorButton>{messageError}</S.MessageErrorButton>
              )}
              <DefaultButton
                label="Salvar"
                type="button"
                onClick={() => handleChangePayment()}
                loading={false}
              />
            </S.BoxButton>
          </S.BoxButton>
        </S.ContainerCard>
      </S.Container>
    </S.ContainerPage>
  )
}

export default NewSalesChannel
