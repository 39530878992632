import * as S from './styles'

export type ICheckboxToggling = {
  onClick: (value: boolean) => void
  value: boolean
}

const CheckboxToggling = ({ onClick, value }: ICheckboxToggling) => {
  return (
    <S.Container
      onClick={() => onClick(!value)}
      isActive={value}
      className='noselect'
    >
      {value ? '' : <S.Circle isActive={value} />}
      <S.DescriptionBox>
        <S.Description isActive={value}>
          {value ? 'Ativo' : 'Inativo'}
        </S.Description>
      </S.DescriptionBox>
      {value ? <S.Circle isActive={value} /> : ''}
    </S.Container>
  )
}

export default CheckboxToggling
