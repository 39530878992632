import styled from 'styled-components'
import Colors from '../../../../common/colors'
import TextArea from '../../../../components/TextArea'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const ContainerCard = styled.div`
  margin-top: 32px;
`

export const HeaderTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: ${Colors.neutralColor};
`

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: ${Colors.neutralColor};
`

export const Row = styled.div`
  display: flex;
  padding: 8px;
`

export const Col = styled.div`
  width: 100%;
  padding: 8px;

  input {
    width: 100%;
  }
`
export const Col2 = styled.div`
  width: 50%;
  padding: 8px;

  input {
    width: 100%;
  }
`
export const Label = styled.div``

export const ContainerText = styled.div`
  margin-top: 6px;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 10px;
`

export const BoxButton = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  button {
    width: 100%;
    max-width: 120px;
  }
`
