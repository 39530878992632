import styled from 'styled-components'
import Colors from '../../../../common/colors'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const BoxButton = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 100%;
    max-width: 250px;
  }
`

export const BoxErrorButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px;
`

export const Form = styled.form`
  p {
    margin-bottom: 8px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
  }
`

export const Row = styled.div`
  display: flex;
`

export const Col = styled.div`
  width: 100%;
  margin-right: 8px;

  input {
    width: 100%;
  }
`

export const Label = styled.div`
  margin-top: 10px;
`

export const ContainerDescription = styled.div`
  margin-top: 16px;
`
