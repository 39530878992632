import { Container, Input, ContainerInput, ErrorMessage } from './styles'
import { TbSearch } from 'react-icons/tb'
import Colors from '../../common/colors'
import { useEffect, useState } from 'react'
import { useDebounce } from './useDebounce'

export interface ISearchInput {
  placeholder?: string
  value?: string
  errorMessage?: string
  onChange?: (event: string) => void
}

export const SearchInput: React.FC<ISearchInput> = ({
  placeholder,
  onChange,
  value,
  errorMessage
}) => {
  const [displayValue, setDisplayValue] = useState(value)
  const debouncingChange = useDebounce(onChange, 500)

  useEffect(() => {
    setDisplayValue(value)
  }, [value])

  function handleChange(event: any) {
    setDisplayValue(event.target.value)
    debouncingChange(event.target.value)
  }

  return (
    <Container>
      <ContainerInput>
        <TbSearch size={25} color={Colors.primary} />
        <Input
          placeholder={placeholder}
          onChange={handleChange}
          value={displayValue}
        />
      </ContainerInput>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}
