import React from 'react'

import { Input, MessageError, Container } from './styles'

export interface ITextInput {
	maxLength?: number
	placeholder?: string
	value?: string
	type?: 'text' | 'password' | 'number' | 'email'
	messageError?: string
	onChange?: (event: string) => void
	onBlur?: () => void
	disabled?: boolean
}

const TextInput: React.FC<ITextInput> = ({
	maxLength,
	placeholder,
	onChange,
	messageError,
	value,
	type,
	disabled = false,
	onBlur,
}) => {
	return (
		<Container>
			<Input
				disabled={disabled}
				maxLength={maxLength}
				placeholder={placeholder}
				onChange={e => onChange && onChange(e.target.value)}
				value={value}
				type={type}
				onBlur={onBlur}
			/>
			{messageError && <MessageError>{messageError}</MessageError>}
		</Container>
	)
}

export default TextInput
