import { useState } from 'react'
import { TabItems, TabContainer } from './styles'

export interface ITabs {
  items: string[]
  onTab?: () => string
}

const Tabs: React.FC<ITabs> = ({ items, onTab }) => {
  const [activeTab, setActiveTab] = useState(items[0])

  const switchTabs = (tab: any) => {
    setActiveTab(tab)
  }
  return (
    <TabContainer>
      {items?.map((item, index) => (
        <TabItems
          key={index}
          activeTab={activeTab === item}
          onClick={() => switchTabs(item)}
        >
          {item}
        </TabItems>
      ))}
    </TabContainer>
  )
}

export default Tabs
