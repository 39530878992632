import Breadcrumb, { ISections } from '../../components/Breadcrumb'
import { Title } from '../../components/Title'
import { GoLocation } from 'react-icons/go'

import * as S from './styles'
import { useEffect, useState } from 'react'
import { getUserById } from '../../services/authUserApi'
import { getUserData } from '../../services/userData'
import Toast from '../../components/Toast'
import { getSalesChannels } from '../../services/plansApi'
import { limitCaracteres } from '../../common/utils'
import {
  IWorkSalesChannel,
  useSelectWorkSalesChannel
} from '../../hooks/useSelectSalesChannel'

type IWorkSalesChannelProp = {
  selected: boolean
} & IWorkSalesChannel

const Home = () => {
  const [salesChannels, setSalesChannels] = useState<IWorkSalesChannelProp[]>(
    []
  )
  const [loading, setLoading] = useState(false)
  const [salesChannelId, setSalesChannelId] = useSelectWorkSalesChannel()
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Escolha um Canal de Venda' }
  ]

  useEffect(() => {
    async function init() {
      try {
        setLoading(true)
        const user = getUserData()
        if (!user) return

        const response = await getUserById(user.id)
        if (response.data && response.data.salesChannelIds) {
          const salesChannelIds = response.data.salesChannelIds || []
          const { data: salesChannels } = await getSalesChannels()
          const allSalesChannels = salesChannels || []
          const salesChannelFiltered = allSalesChannels.filter((sc: any) =>
            salesChannelIds.includes(sc.id)
          )
          const newSalesChannels = salesChannelFiltered.map((sc: any) => ({
            id: sc.id,
            name: limitCaracteres(sc.name, 20, true),
            address: `${sc.city} - ${sc.state}`,
            selected: salesChannelId?.id === sc.id
          }))
          setSalesChannels(newSalesChannels)
        }
      } catch (error) {
        Toast.showError(
          'Não foi possível carregar os Canais de Vendas disponíveis!'
        )
      } finally {
        setLoading(false)
      }
    }
    init()
  }, [])

  function handleSelectItem(id: string) {
    const newSalesChannels = salesChannels.map((sc) => ({
      ...sc,
      selected: sc.id === id
    }))
    setSalesChannels(newSalesChannels)
    const wSalesChannel = salesChannels.find((sc) => sc.id === id)
    if (wSalesChannel) {
      setSalesChannelId(wSalesChannel)
    }
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Escolha um Canal de Venda'} />
        </S.ContainerTitle>
        <S.ContainerItems>
          {loading && 'Carregando...'}
          {!loading &&
            !salesChannels.length &&
            'Nenhum Canal de Venda vinculado ao usuário, contate o suporte para mais informações!'}
          {salesChannels.map((salesChannel) => (
            <S.ContainerItem
              key={salesChannel.id}
              selected={salesChannel.selected}
              onClick={() => handleSelectItem(salesChannel.id)}
            >
              <GoLocation />
              <S.ContainerBody>
                <S.Name>{salesChannel.name}</S.Name>
                <S.Address>{salesChannel.address}</S.Address>
              </S.ContainerBody>
            </S.ContainerItem>
          ))}
        </S.ContainerItems>
      </S.Container>
    </S.ContainerPage>
  )
}

export default Home
