import { useEffect, useState } from 'react'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import DefaultButton from '../../../components/DefaultButton'
import { useNavigate, useParams } from 'react-router-dom'
import * as S from './styles'
import { getProfile, getResourceById } from '../../../services/authUserApi'
import Toast from '../../../components/Toast'
import { AiOutlineEdit } from 'react-icons/ai'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

const sections: ISections[] = [
  { description: 'Home', path: '/dashboard/home' },
  { description: 'Recursos', path: '/dashboard/recursos' },
  { description: 'Show' }
]

const ResourceShow = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    async function init() {
      try {
        if (!id) return
        const response = await getResourceById(id)
        if (response.data) {
          const { name, description, code } = response.data
          setName(name)
          setDescription(description || '')
          setCode(code)
        }
      } catch (error) {
        Toast.showError('Recurso não encontrado!')
      }
    }
    init()
  }, [])

  function goBack() {
    navigate(`/dashboard/recursos`)
  }

  function handleToEdit() {
    if (!id) return
    navigate(`/dashboard/recursos/${id}/editar`)
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Dados do recurso'} />
        </S.ContainerTitle>
        <S.ContainerCard onSubmit={() => {}}>
          <S.Actions onClick={() => {}}>
            {hasPermission(ResourceType.EDIT_RESOURCE) && (
              <S.Action onClick={handleToEdit}>
                <AiOutlineEdit size={20} />
                {'Editar'}
              </S.Action>
            )}
          </S.Actions>
          <S.Row>
            <S.Col>
              <S.Label>Nome</S.Label>
              <S.Value>{name}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>Código</S.Label>
              <S.Value>{code}</S.Value>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Descrição</S.Label>
              <S.Value>{description}</S.Value>
            </S.Col>
          </S.Row>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ResourceShow
