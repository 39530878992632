import * as S from './styles'

type PlansType = {
  id: string
  title: string
  description: string
  onClick: (id: string) => void
  isActive?: boolean
}

const PlanOption = ({ id, title, description, isActive, onClick }: PlansType) => {
  return (
    <S.Container
      onClick={() => onClick(id)}
      isActive={isActive}
      className="noselect"
    >
      <p>{title}</p>
      <p>{description}</p>
    </S.Container>
  )
}

export default PlanOption
