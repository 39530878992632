import {
  convertUSDateInBRLDate,
  formatBirthSex,
  formatCPF
} from '../../../../common/utils'
import { MdOutlineEdit } from 'react-icons/md'
import * as S from '../styles'
import { Subscriber } from '../../../../models/Subscriber'
import { useNavigate } from 'react-router-dom'
import { hasPermission } from '../../../../services/userData'
import { ResourceType } from '../../../../models/ResourceType'

type Props = {
  isLoading: boolean
  subscriber?: Subscriber
}

const SubscriberDataSession = ({ isLoading, subscriber }: Props) => {
  const navigate = useNavigate()

  function handleEdit() {
    if (!subscriber?.id) return
    navigate(`/dashboard/clientes/${subscriber.id}/editar-dados-pessoais`)
  }

  return (
    <S.ContainerCard>
      <S.HeaderCard>
        <S.HeaderTitle>Dados pessoais</S.HeaderTitle>
        <S.HeaderOptions>
          {hasPermission(ResourceType.EDIT_SUBSCRIBER_PERSONAL_DATA) && (
            <S.HeaderOption onClick={handleEdit}>
              <MdOutlineEdit />
              <S.HeaderOptionLabel>Editar</S.HeaderOptionLabel>
            </S.HeaderOption>
          )}
        </S.HeaderOptions>
      </S.HeaderCard>
      {isLoading && <S.LoadingArea>Carregando...</S.LoadingArea>}
      <S.BodyCard hidden={isLoading}>
        <S.Row>
          <S.Col>
            <S.Label>Nome</S.Label>
            <S.Description>{subscriber?.name}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Nome social</S.Label>
            <S.Description>{subscriber?.social_name}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>CPF</S.Label>
            <S.Description>{formatCPF(subscriber?.cpf)}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Gênero</S.Label>
            <S.Description>{subscriber?.gender.description}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Sexo Biológico</S.Label>
            <S.Description>
              {formatBirthSex(subscriber?.birth_sex)}
            </S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Data de Nascimento</S.Label>
            <S.Description>
              {convertUSDateInBRLDate(subscriber?.birth)}
            </S.Description>
          </S.Col>
          <S.Col>
            <S.Label>E-mail</S.Label>
            <S.Description>{subscriber?.email}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Telefone</S.Label>
            <S.Description>{subscriber?.phone}</S.Description>
          </S.Col>
        </S.Row>
      </S.BodyCard>
    </S.ContainerCard>
  )
}

export default SubscriberDataSession
