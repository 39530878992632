import { useNavigate } from 'react-router-dom'
import { limitCaracteres } from '../../../../common/utils'
import { Masks } from '../../../../common/masks'
import Badge from '../../../../components/Badge'
import { Plan } from '../../../../models/Plan'
import { Table } from '../../../../styles/Table'

export enum TipoPlan {
  Anual = 12,
  Semestral = 6,
  Trimestral = 3,
  Bienal = 24
}

type TableArea = {
  plans: Plan[]
}

const TableArea = ({ plans }: TableArea) => {
  const navigate = useNavigate()

  function handleSelectPlan(id?: string) {
    navigate(`/dashboard/planos/${id}`)
  }

  return (
    <Table enableShadow enableHover>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Vigência (em meses)</th>
          <th>Preço</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {plans?.map((plan: Plan) => (
          <tr key={plan.plan_id} onClick={() => handleSelectPlan(plan.plan_id)}>
            <td>{limitCaracteres(plan.name, 30, true)}</td>
            <td>{plan.interval_month}</td>
            <td>{Masks.currency(String(plan.price))} </td>
            <td>
              {plan.status === 'active' ? (
                <Badge title="Ativo" type="success" />
              ) : (
                <Badge title="Inativo" type="error" />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default TableArea
