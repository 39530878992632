import { useState, useCallback, createContext, useContext } from 'react'

export type IWorkSalesChannel = {
  id: string
  name: string
  address: string
}

type Types = [IWorkSalesChannel | null, (wSalesChannel: IWorkSalesChannel | null) => void]

const KEY_NAME = '@backoffice:wSalesChannel'

const WorkSalesChannelContext = createContext<Types>([null, () => {}])

export const WorkSalesChannelProvider = ({ children }: any) => {
  const initialValues = JSON.parse(localStorage.getItem(KEY_NAME) || 'null')
  const [data, setData] = useState<IWorkSalesChannel | null>(initialValues)

  return (
    <WorkSalesChannelContext.Provider value={[data, setData]}>
      {children}
    </WorkSalesChannelContext.Provider>
  )
}

export function useSelectWorkSalesChannel(): Types {
  const [salesChannelId, updateSalesChannelId] = useContext(
    WorkSalesChannelContext
  )

  const setWorkSalesChannel = useCallback((wSalesChannel: IWorkSalesChannel | null) => {
    localStorage.setItem(KEY_NAME, JSON.stringify(wSalesChannel))
    updateSalesChannelId(wSalesChannel)
  }, [])

  return [salesChannelId, setWorkSalesChannel]
}
