import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultButton from '../../../components/DefaultButton'
import { SearchInput } from '../../../components/SearchInput'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import { Title } from '../../../components/Title'
import * as S from './styles'
import { Table } from '../../../styles/Table'
import { User } from '../../../models/User'
import {
  limitCaracteres,
  formatCPF,
  convertUSDateInBRLDateTime
} from '../../../common/utils'
import CheckboxToggling from '../../../components/CheckboxToggling'
import { BiEditAlt } from 'react-icons/bi'
import {
  activeUser,
  getAllUsers,
  inactiveUser
} from '../../../services/authUserApi'
import Toast from '../../../components/Toast'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

type IUser = {
  loading?: boolean
} & User

const UsersList = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [changingStatus, setChangingStatus] = useState(false)
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState<IUser[]>([])

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Usuários', path: '/dashboard/usuarios' }
  ]

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true)
        const users = await getAllUsers()
        setIsLoading(false)
        setUsers(users.data)
      } finally {
        setIsLoading(false)
      }
    }
    init()
  }, [])

  function filter(user: User) {
    const hasName = user.name.toLowerCase().includes(search.toLowerCase())
    const hasEmail = (user.email || '')
      .toLowerCase()
      .includes(search.toLowerCase())
    const hasCpf = user.cpf.toLowerCase().includes(search.toLowerCase())
    return hasName || hasCpf || hasEmail
  }

  function enableLoading(userId: string) {
    setUsers(
      users.map((user) => ({
        ...user,
        loading: user.id === userId
      }))
    )
  }

  function disableLoading() {
    setUsers(
      users.map((user) => ({
        ...user,
        loading: false
      }))
    )
  }

  function changeStatusByUser(userId: string, active: boolean) {
    setUsers(
      users.map((user) => ({
        ...user,
        active: user.id === userId ? active : user.active,
        loading: false
      }))
    )
  }

  async function handleActiveUser(userId: string) {
    try {
      if (changingStatus) return
      enableLoading(userId)
      setChangingStatus(true)
      const user = await activeUser(userId)
      changeStatusByUser(userId, user.data.active)
      Toast.showSuccess('Usuário ativado!')
    } catch {
      Toast.showError('Não fou possível ativar o usuário!')
      disableLoading()
    } finally {
      setChangingStatus(false)
    }
  }

  async function handleInactiveUser(userId: string) {
    try {
      if (changingStatus) return
      enableLoading(userId)
      setChangingStatus(true)
      const user = await inactiveUser(userId)
      changeStatusByUser(userId, user.data.active)
      Toast.showSuccess('Usuário inativado!')
    } catch {
      Toast.showError('Não fou possível inativar o usuário!')
      disableLoading()
    } finally {
      setChangingStatus(false)
    }
  }

  function handleNavigateToShow(event: any, user: IUser) {
    if (event.target.localName === 'td') {
      navigate(`/dashboard/usuarios/${user.id}`)
    }
  }

  function getContainerTable() {
    if (isLoading) {
      return <S.EmptyArea>Carregando...</S.EmptyArea>
    }

    const filteredUsers = (users || []).filter(filter)

    if (!filteredUsers.length) {
      return <S.EmptyArea>Nenhum usuário encontrado</S.EmptyArea>
    }

    return (
      <Table enableShadow enableHover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>E-mail</th>
            <th>Data de criação</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id} onClick={(e) => handleNavigateToShow(e, user)}>
              <td>{limitCaracteres(user.name, 30, true)}</td>
              <td>{formatCPF(user.cpf)}</td>
              <td>
                {user.email ? limitCaracteres(user.email, 30, true) : 'N/A'}
              </td>
              <td>{convertUSDateInBRLDateTime(user.created_at)}</td>
              <td>
                {user.loading ? (
                  <S.ChangingStatusText>Alterando...</S.ChangingStatusText>
                ) : (
                  <CheckboxToggling
                    onClick={() =>
                      user.active
                        ? handleInactiveUser(user.id)
                        : handleActiveUser(user.id)
                    }
                    value={user.active}
                  />
                )}
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Usuários'} />
          {hasPermission(ResourceType.NEW_BACKOFFICE_USER) && (
            <DefaultButton
              label="Novo Usuário"
              type="button"
              loading={false}
              onClick={() => navigate(`/dashboard/usuarios/novo`)}
            />
          )}
        </S.ContainerTitle>
        <SearchInput
          placeholder="Busque pelo Nome, CPF ou E-mail do usuário"
          onChange={setSearch}
          value={search}
        />
        <S.ContainerTable>{getContainerTable()}</S.ContainerTable>
      </S.Container>
    </S.ContainerPage>
  )
}

export default UsersList
