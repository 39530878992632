import { useEffect, useState } from 'react'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import DefaultButton from '../../../components/DefaultButton'
import { useNavigate, useParams } from 'react-router-dom'
import * as S from './styles'
import { getProfile } from '../../../services/authUserApi'
import Toast from '../../../components/Toast'
import { AiOutlineEdit } from 'react-icons/ai'
import { Resource } from '../../../models/Resource'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

const sections: ISections[] = [
  { description: 'Home', path: '/dashboard/home' },
  { description: 'Perfis de Acesso', path: '/dashboard/perfis-de-acesso' },
  { description: 'Dados do Perfil de Acesso' }
]

const AccessProfileShow = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isSeller, setIsSeller] = useState('')
  const [resources, setResources] = useState<Resource[]>([])

  useEffect(() => {
    async function init() {
      try {
        if (!id) return
        const response = await getProfile(id)
        if (response.data) {
          const { name, description, isSeller, resources } = response.data
          setName(name)
          setDescription(description)
          setIsSeller(isSeller ? 'Sim' : 'Não')
          setResources(resources || [])
        }
      } catch (error) {
        Toast.showError('Perfil de acesso não encontrado!')
      }
    }
    init()
  }, [])

  function goBack() {
    navigate(`/dashboard/perfis-de-acesso`)
  }

  function handleToEdit() {
    if (!id) return
    navigate(`/dashboard/perfis-de-acesso/${id}/editar`)
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Dados do Perfil de Acesso'} />
        </S.ContainerTitle>
        <S.ContainerCard onSubmit={() => {}}>
          <S.Actions onClick={() => {}}>
            {hasPermission(ResourceType.EDIT_ACCESS_PROFILE) && (
              <S.Action onClick={handleToEdit}>
                <AiOutlineEdit size={20} />
                {'Editar'}
              </S.Action>
            )}
          </S.Actions>
          <S.Row>
            <S.Col>
              <S.Label>Nome</S.Label>
              <S.Value>{name}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>É perfil de vendedor?</S.Label>
              <S.Value>{isSeller}</S.Value>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Descrição</S.Label>
              <S.Value>{description}</S.Value>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Recursos</S.Label>
              {!resources.length && (
                <S.Value>Nenhum recurso para esse perfil de acesso.</S.Value>
              )}
              {resources.map((res) => (
                <S.Value key={res.id}>
                  {res.name} <strong>({res.code})</strong>
                </S.Value>
              ))}
            </S.Col>
          </S.Row>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default AccessProfileShow
