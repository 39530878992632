import styled from 'styled-components'
import Colors from '../../../../common/colors'

export const ContainerHistoryData = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerInfo = styled.div`
  width: 100%;
`

export const SeparatorLine = styled.hr`
  background-color: ${Colors.neutralColorLight};
  height: 1px;
  border: none;
  margin-top: 20px;
`

export const ContainerItem = styled.div`
  padding: 8px;
`

export const ContainerStatus = styled.div`
  p {
    text-align: center;
    font-weight: bold;
    margin-bottom: 8px;
  }
`

export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
`

export const Value = styled.span`
  font-weight: normal;
`
