import { AxiosResponse } from 'axios'
import api from '../configs/plansApiConfigs'
import { Category } from '../models/Category'
import { CompleteSubscriptionRequest } from '../models/CompleteSubscriptionRequest'
import { CreateSubscriptionRequest } from '../models/CreateSubscriptionRequest'
import { UpdateSubscriptionRequest } from '../models/CreateSubscriptionRequest copy'
import { Plan } from '../models/Plan'
import { SalesChannel } from '../models/SalesChannel'

export async function getSalesChannels(): Promise<any> {
  return await api.get(`/backoffice/saleschannels`)
}

export async function getCategories(): Promise<AxiosResponse<Category[], any>> {
  return await api.get<Category[]>(`/backoffice/types/saleschannels`)
}

export async function getCategoryById(
  id: string
): Promise<AxiosResponse<Category, any>> {
  return await api.get<Category>(`/backoffice/types/saleschannels/${id}`)
}

export async function postSalesChannel(
  saleschannel: any
): Promise<AxiosResponse<any>> {
  return await api.post(`/backoffice/saleschannels`, saleschannel)
}

export async function getSalesChannel(id: string): Promise<AxiosResponse<any>> {
  return await api.get(`/backoffice/saleschannels/${id}`)
}

export async function postPlan(plans: Plan): Promise<AxiosResponse<any>> {
  return await api.post(`/backoffice/plans`, plans)
}

export async function getPlans(all?: boolean): Promise<AxiosResponse<any>> {
  return await api.get(`/backoffice/plans?all=${!!all}`)
}

export async function getPlansById(
  planId: string
): Promise<AxiosResponse<any>> {
  return await api.get(`/backoffice/plans/${planId}`)
}

export async function inactivePlan(
  planId: string
): Promise<AxiosResponse<any>> {
  return await api.put(`/backoffice/plans/${planId}/inactivate`)
}

export async function getContractReport(
  planId: string,
  subscriberId: string
): Promise<any> {
  return await api.get(
    `/backoffice/plans/${planId}/subscribers/${subscriberId}/contract`,
    {
      responseType: 'blob'
    }
  )
}

export async function getContractSignedBySubscription(
  subscriberId: string
): Promise<any> {
  return await api.get(`/backoffice/subscriptions/${subscriberId}/contract`, {
    responseType: 'blob'
  })
}

export async function getReceiptPayment(subscriptionId: string): Promise<any> {
  return await api.get(
    `/backoffice/subscriptions/${subscriptionId}/receipt-payment`,
    {
      responseType: 'blob'
    }
  )
}

export async function getPlansBySalesChannelId(
  id: string
): Promise<AxiosResponse<any>> {
  return await api.get(`/backoffice/plans/saleschannels/${id}`)
}

export async function createPendingSubscription(
  data: CreateSubscriptionRequest
): Promise<AxiosResponse<any>> {
  return await api.post(`/backoffice/subscriptions`, data)
}

export async function updatePendingSubscription(
  subscriptionId: string,
  data: UpdateSubscriptionRequest
): Promise<AxiosResponse<any>> {
  return await api.put(`/backoffice/subscriptions/${subscriptionId}`, data)
}

export async function completeSubscription(
  subscriptionId: string,
  data: CompleteSubscriptionRequest
): Promise<AxiosResponse<any>> {
  return await api.post(
    `/backoffice/subscriptions/${subscriptionId}/complete`,
    data
  )
}

export async function getSubscriptionById(
  subscriptionId: string
): Promise<AxiosResponse<any>> {
  return await api.get(`/backoffice/subscriptions/${subscriptionId}`)
}

export async function getSubscriptionsBySubscriberId(
  subscriberId: string
): Promise<AxiosResponse<any>> {
  return await api.get(`/backoffice/subscriptions/subscribers/${subscriberId}`)
}

export async function getSubscriptionsBySubscribersIds(ids: string[]): Promise<AxiosResponse<any[]>> {
  const subIds = ids.join(',')
  return await api.get(`/backoffice/subscriptions?subscriberIds=${subIds}`)
}

export async function uploadContractSubscription(
  subscriptionId: string,
  file: any
): Promise<AxiosResponse<any>> {
  const formData = new FormData()
  formData.append('file', file)
  return await api.post(
    `/backoffice/subscriptions/${subscriptionId}/contract`,
    formData
  )
}

export async function sendPaymentLink(
  subscriptionId: string
): Promise<AxiosResponse<any>> {
  return await api.post(
    `/backoffice/subscriptions/${subscriptionId}/send-payment-link`,
    {}
  )
}

export async function cancelSubscription(
  subscriptionId: string,
  description: string
): Promise<AxiosResponse<any>> {
  return await api.put(`/backoffice/subscriptions/${subscriptionId}/cancel`, {
    description
  })
}

export async function putSalesChannel(
  id: string,
  data: any
): Promise<AxiosResponse<SalesChannel>> {
  return await api.put(`/backoffice/saleschannels/${id}`, data)
}

export async function activeSalesChannel(
  id: string
): Promise<AxiosResponse<SalesChannel>> {
  return await api.put(`/backoffice/saleschannels/${id}/status/active`)
}

export async function inactiveSalesChannel(
  id: string
): Promise<AxiosResponse<SalesChannel>> {
  return await api.put(`/backoffice/saleschannels/${id}/status/inactive`)
}
