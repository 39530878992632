import { AxiosRequestConfig } from "axios";
import { getTokenData } from "../services/userData";

export function requestBase(config: AxiosRequestConfig<any>) {
  if (config.headers) {
    const tokenData = getTokenData()
    const authorization = `${tokenData?.tokenType} ${tokenData?.accessToken}`
    config.headers['Authorization'] = authorization
  }
  return config
}

export function responseError(error: any) {
  const response = error.response
  if ((response.status === 403 && response.data?.message === 'Access denied') || response.status === 401) {
    localStorage.clear()
    return window.location.replace('/login')
  }
  return Promise.reject(error)
}
