import { MdOutlineEdit, MdAdd } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { Address } from '../../../../models/Address'
import { ResourceType } from '../../../../models/ResourceType'
import { hasPermission } from '../../../../services/userData'
import * as S from '../styles'

type Props = {
  isLoading: boolean
  address?: Address
}

const AddressSession = ({ isLoading, address }: Props) => {
  const navigate = useNavigate()
  const { id } = useParams()

  function handleEdit() {
    navigate(`/dashboard/clientes/${id}/editar-endereco`)
  }

  return (
    <S.ContainerCard>
      <S.HeaderCard>
        <S.HeaderTitle>Endereço</S.HeaderTitle>
        <S.HeaderOptions>
          {hasPermission(ResourceType.EDIT_SUBSCRIBER_ADDRESS) && (
            <S.HeaderOption>
              <MdOutlineEdit />
              <S.HeaderOptionLabel onClick={handleEdit}>
                Editar
              </S.HeaderOptionLabel>
            </S.HeaderOption>
          )}
        </S.HeaderOptions>
      </S.HeaderCard>
      {isLoading && <S.LoadingArea>Carregando...</S.LoadingArea>}
      <S.BodyCard hidden={isLoading}>
        <S.Row>
          <S.Col>
            <S.Label>CEP</S.Label>
            <S.Description>{address?.zip_code}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Rua/Avenida</S.Label>
            <S.Description>{address?.address_description}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Número</S.Label>
            <S.Description>{address?.address_number}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Complemento</S.Label>
            <S.Description>{address?.complement}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Bairro</S.Label>
            <S.Description>{address?.district}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Cidade</S.Label>
            <S.Description>{address?.city}</S.Description>
          </S.Col>
          <S.Col>
            <S.Label>Estado</S.Label>
            <S.Description>{address?.state}</S.Description>
          </S.Col>
        </S.Row>
      </S.BodyCard>
    </S.ContainerCard>
  )
}

export default AddressSession
