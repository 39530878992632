import styled from 'styled-components'
import Colors from '../../common/colors'

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.primary};
  width: 100%;
  max-width: 300px;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 6px;
`

export const MenuHeader = styled.div``

export const ImgHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 24px;

  img {
    max-width: 250px;
    text-align: center;
  }
`

export const ImgCircle = styled.div`
  height: 52px;
  width: 52px;
  background-color: ${Colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AppTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${Colors.white};
  margin-left: 16px;
`

export const ExitBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.white};
  margin-left: 24px;
  margin-right: 24px;
  border-top: 1px solid #E0E0E0;
  margin-top: 32px;
  padding-top: 32px;
  padding-bottom: 16px;

  svg {
    cursor: pointer;
  }
`

export const BoxImage = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: ${Colors.white};
  color: ${Colors.primary};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BoxData = styled.div`
  display: flex;
  align-items: center;
`
export const Username = styled.p`
  margin-left: 8px;
`

export const MenuFooter = styled.div``
