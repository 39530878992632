import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { checkPasswordComplexity, isEmpty } from '../../../common/validators'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import DefaultButton from '../../../components/DefaultButton'
import TextInput from '../../../components/TextInput'
import { Title } from '../../../components/Title'
import Toast from '../../../components/Toast'
import { ResetUserPassword } from '../../../models/ResetUserPassword'
import { resetPassword } from '../../../services/authUserApi'
import { getUserData } from '../../../services/userData'
import * as S from './styles'

const ChangePassword = () => {
  const navigate = useNavigate()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [loading, setLoading] = useState(false)

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Meu perfil', path: '/dashboard/meu-perfil' },
    { description: 'Alterar senha' }
  ]

  function goBack() {
    navigate(`/dashboard/meu-perfil`)
  }

  function validateFields(): boolean {
    const oldPasswordValid = oldPassword.trim()
    const newPasswordValid = newPassword.trim()
    const newPasswordConfirmationValid = newPasswordConfirmation.trim()

    if (
      isEmpty(oldPasswordValid) ||
      isEmpty(newPasswordValid) ||
      isEmpty(newPasswordConfirmationValid)
    ) {
      Toast.showError('Preencha todos os campos!')
      return false
    }

    if (newPasswordValid != newPasswordConfirmationValid) {
      Toast.showError('Novas senhas não coincidem!')
      return false
    }

    if (!checkPasswordComplexity(newPasswordValid)) {
      Toast.showError('Nova senha muito fraca!')
      return false
    }

    return true
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    try {
      if (loading) return
      if (!validateFields()) return
      const user = getUserData()
      if (!user) return

      setLoading(true)
      const data: ResetUserPassword = {
        oldPassword: oldPassword.trim(),
        newPassword: newPassword.trim(),
        newPasswordConfirmation: newPasswordConfirmation.trim()
      }
      const response = await resetPassword(user.id, data)
      setLoading(false)
      if (response.data.wasChanged) {
        Toast.showSuccess('Senha alterada com sucesso!')
        goBack()
      } else {
        Toast.showError(
          'Não foi possível alterar a senha, verifique a senha antiga e tente novamente!'
        )
      }
    } catch (error) {
      setLoading(false)
      Toast.showError(
        'Não foi possível alterar a senha, verifique a senha antiga e tente novamente!'
      )
    }
  }

  return (
    <S.ContainerPage>
      <S.Container onSubmit={handleSubmit}>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Alterar senha'} />
        </S.ContainerTitle>
        <S.ContainerCard>
          <S.Row>
            <S.Col>
              <S.Label>Senha antiga *</S.Label>
              <TextInput
                maxLength={50}
                value={oldPassword}
                onChange={setOldPassword}
                type="password"
              />
            </S.Col>
            <S.Col />
            <S.Col />
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Nova senha *</S.Label>
              <TextInput
                maxLength={50}
                value={newPassword}
                onChange={setNewPassword}
                type="password"
              />
            </S.Col>
            <S.Col>
              <S.Label>Repita a nova senha *</S.Label>
              <TextInput
                maxLength={50}
                value={newPasswordConfirmation}
                onChange={setNewPasswordConfirmation}
                type="password"
              />
            </S.Col>
            <S.Col />
          </S.Row>
          <S.RulesBox>
            <strong>Regras para as novas senhas:</strong>
            <S.Ul>
              <li>Dever conter pelo menos oito caracteres;</li>
              <li>Incluir caracteres especiais {'({`^};-_)($%#(@.*!&etc.)'}</li>
              <li>
                Incluir letras (minúscula e maiúscula)
                (abcdfghijklmnopqrstuvxyzABCDEFG...);
              </li>
              <li>Incluir números (0123456789);</li>
            </S.Ul>
          </S.RulesBox>
        </S.ContainerCard>
        <S.BoxButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
          <DefaultButton label="Alterar" type="submit" loading={loading} />
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ChangePassword
