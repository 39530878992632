import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DrawerMenu from '../components/DrawerMenu'
import Login from '../pages/Login'
import Contract from '../pages/Contract'
import Recover from '../pages/Recover'

const RoutesApp: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recovery" element={<Recover />} />
      <Route path="/sign-contract" element={<Contract />} />
      <Route path="/dashboard/*" element={<DrawerMenu />} />
    </Routes>
  </BrowserRouter>
)

export default RoutesApp
