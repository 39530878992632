import styled from 'styled-components'
import Colors from '../../common/colors'

export const Container = styled.div`
  /* background-color: black; */
  display: flex;

  img {
    margin-left: 8px;
    margin-right: 8px;
  }
`

type ISection = {
  isActive?: boolean
}

export const Section = styled.p<ISection>`
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: ${Colors.neutralColor};
  opacity: 0.7;
  ${({ isActive }) => (!!isActive ? `opacity: 1;` : ``)}
`
