import styled from 'styled-components'
import Colors from '../../common/colors'

type IContainer = {
  isActive: boolean
}

export const Container = styled.div<IContainer>`
  height: 44px;
  width: 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 500px;
  background-color: ${Colors.neutralColorLight};
  ${({ isActive }) => isActive && `background-color: ${Colors.success};`}
  transition: all 1s;
  cursor: pointer;
`

export const Circle = styled.div<IContainer>`
  height: 44px;
  width: 44px;
  min-width: 44px;
  border-radius: 50%;
  background-color: ${Colors.white};
  border: 4px solid ${Colors.neutralColorLight};
  ${({ isActive }) => isActive && `border: 4px solid ${Colors.success};`}
  transition: border 1s;
`

export const DescriptionBox = styled.div`
  width: 100%;
`

export const Description = styled.p<IContainer>`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${Colors.white};
  ${({ isActive }) => isActive && `text-align: end;`}
`
