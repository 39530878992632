import styled from 'styled-components'
import Colors from '../../common/colors'

export const ContainerPage = styled.div`
  height: 100%;
  background-color: ${Colors.white};
`

export const NavMenu = styled.nav`
  height: 70px;
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 6px;
`

export const Body = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`

export const BodyContainer = styled.div`
  width: 100%;
  max-width: 1000px;
`

export const Title = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`

export const ContainerContract = styled.div`
  margin-top: 16px;
  height: 500px;
  border: 1px solid rgb(188, 189, 189);
  border-radius: 6px;
  overflow: auto;
  padding: 16px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 16px;

  button {
    width: 100%;
    max-width: 300px;
  }
`

export const CheckContractContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  background-color: #f9f9f9;
  cursor: pointer;
`

type Check = {
  checked: boolean
}

export const Check = styled.div<Check>`
  min-height: 10px;
  min-width: 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 16px;
  margin-right: 8px;
  border: 1px solid ${Colors.primary};

  ${({ checked }) => checked && `background-color: ${Colors.primary};`}
  ${({ checked }) => !checked && `background-color: ${Colors.white};`}
`

export const LoadingContract = styled.div``

export const ContractHTML = styled.div``

export const MsgError = styled.p`
  font-size: 18px;
  color: ${Colors.error};
`
