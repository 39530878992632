import * as S from './styles'

export type IOption = {
  value: string
  label: string
}

export type ISelect = {
  options: IOption[]
  value: string
  onChange?: (value: any) => void
  messageError?: string
  title?: string
}

const Select = ({ options, value, onChange, messageError, title }: ISelect) => {
  return (
    <S.Container>
      <S.Select value={value} onChange={(event) => onChange && onChange(event)}>
        <S.Option value="" disabled>
          {title}
        </S.Option>
        {options.map((opt, index) => (
          <option key={opt.value + index} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </S.Select>
      {messageError && <S.MessageError>{messageError}</S.MessageError>}
    </S.Container>
  )
}

export default Select
