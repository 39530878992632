import styled from 'styled-components'
import Colors from '../../common/colors'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Title = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 24px;
`

export const FormContainer = styled.form`
  height-min: 300px;
  width: 100%;
  max-width: 400px;
  padding: 50px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 6px;

  input {
    width: 100%;
    margin-top: 16px;
  }
`
export const BoxButton = styled.p`
  width: 100%;

  button {
    width: 100%;
    margin-top: 22px;
  }

  a {
    text-align: center;
    color: ${Colors.primary};
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
  }
`

export const ErrorMessage = styled.p`
  color: ${Colors.error};
  font-size: 12px;
  margin-top: 8px;
`
