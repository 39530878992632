import styled from 'styled-components'
import Colors from '../../common/colors'

export type TypeBadge = 'success' | 'error' | 'warning' | 'primary'

type IWrapper = {
  type?: TypeBadge
}

export const Wrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
  display: flex;
`
export const ItemBadge = styled.span<IWrapper>`
  border-color: ${Colors.clearWhite};
  border-radius: 15px;
  padding: 2px 10px;
  color: ${Colors.clearWhite};
  background-color: ${Colors.neutralColorLight};
  ${({ type }) => type === 'success' && `background-color: ${Colors.success};`}
  ${({ type }) => type === 'error' && `background-color: ${Colors.error};`}
  ${({ type }) => type === 'warning' && `background-color: ${Colors.warning};`}
  ${({ type }) => type === 'primary' && `background-color: ${Colors.primary};`}
`
