import styled from 'styled-components'
import Colors from '../../../common/colors'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.form`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const ContainerCard = styled.div`
  background-color: ${Colors.white};
  box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
`

export const Row = styled.div`
  display: flex;
  padding: 8px;
`

export const Col = styled.div`
  width: 100%;
  padding: 8px;

  input {
    width: 100%;
  }
`
export const Col2 = styled.div`
  width: 50%;
  padding: 8px;

  input {
    width: 100%;
  }
`

export const Label = styled.div`
  margin-top: 10px;
`

export const BoxButton = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button {
      max-width: 120px;
    }
  }

  button {
    width: 100%;
    max-width: 120px;
  }

  p {
    margin-right: 16px;
    color: ${Colors.error};
  }
`

export const MessageError = styled.span`
  color: ${Colors.error};
  font-size: 10px;
`

export const HeaderTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: ${Colors.neutralColor};
`

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: ${Colors.neutralColor};
`

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  button {
    margin-top: 16px;
    width: 120px;
  }
`

export const ContainerListItem = styled.div`
  height: 300px;
  border: 1px solid rgb(188, 189, 189);
  border-radius: 6px;
  overflow: auto;
`

export const ContainerCheckbox = styled.div`
  margin-left: 8px;

  label {
    margin-left: 8px;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox', id: 'is_seller' })``

export const CheckboxLabel = styled.label.attrs({ htmlFor: 'is_seller' })``

type IContainerItem = {
  selected: boolean
}

export const ContainerItem = styled.div<IContainerItem>`
  cursor: pointer;
  padding: 8px;
  ${({ selected }) => selected && `background-color: ${Colors.basicGray};`}

  &:hover {
    background-color: ${Colors.basicGray};
  }
`
