import { useNavigate, useLocation } from 'react-router-dom'
import { TbHome } from 'react-icons/tb'
import { IoMdExit, IoMdClipboard } from 'react-icons/io'
import { MdOutlinePersonSearch } from 'react-icons/md'
import LogoCA from '../../assets/ca-logo.svg'
import * as S from './styles'
import ItemMenu from './ItemMenu'
import { getUserData, hasPermission } from '../../services/userData'
import { getInitialsName, limitCaracteres } from '../../common/utils'
import ItemMenuGroupAdmin from './ItemMenuGroupAdmin'
import { BsPerson } from 'react-icons/bs'
import { ResourceType } from '../../models/ResourceType'
import { GoLocation } from 'react-icons/go'
import { useSelectWorkSalesChannel } from '../../hooks/useSelectSalesChannel'

const Menu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const userData = getUserData()
  const [wSalesChannel, updateSalesChannelId] = useSelectWorkSalesChannel()

  function handleLogout() {
    localStorage.clear()
    updateSalesChannelId(null)
    navigate('/login')
  }

  return (
    <S.MenuContainer>
      <S.MenuHeader>
        <S.ImgHeader>
          <S.ImgCircle>
            <img src={LogoCA} alt="Logo do Cartão Aliança" />
          </S.ImgCircle>
          <S.AppTitle>Cartão Aliança</S.AppTitle>
        </S.ImgHeader>
        <ItemMenu
          active={location.pathname.includes('/dashboard/home')}
          name={'Home'}
          icon={<TbHome size={25} />}
          onClick={() => navigate('/dashboard/home')}
        />
        {hasPermission(ResourceType.SHOW_CLIENTS) && (
          <ItemMenu
            active={location.pathname.includes('/dashboard/clientes')}
            name={'Clientes'}
            icon={<MdOutlinePersonSearch size={25} />}
            onClick={() => navigate('/dashboard/clientes')}
          />
        )}
        <ItemMenuGroupAdmin name={'Admin'} />
      </S.MenuHeader>
      <S.MenuFooter>
        {wSalesChannel && (
          <ItemMenu
            active={false}
            name={wSalesChannel.name}
            icon={<GoLocation size={25} />}
            onClick={() => navigate('/dashboard/home')}
          />
        )}
        <ItemMenu
          active={location.pathname.includes('/dashboard/meu-perfil')}
          name="Meu perfil"
          icon={<BsPerson size={25} />}
          onClick={() => navigate('/dashboard/meu-perfil')}
        />
        <S.ExitBox>
          <S.BoxData>
            <S.BoxImage>{getInitialsName(userData?.name || '')}</S.BoxImage>
            <S.Username title={userData?.name}>
              {limitCaracteres(userData?.name || '', 12, true)}
            </S.Username>
          </S.BoxData>
          <IoMdExit size={25} onClick={handleLogout} title="Sair" />
        </S.ExitBox>
      </S.MenuFooter>
    </S.MenuContainer>
  )
}

export default Menu
