import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DefaultButton from '../../components/DefaultButton'
import { SearchInput } from '../../components/SearchInput'
import Breadcrumb, { ISections } from '../../components/Breadcrumb'
import Tabs from '../../components/Tabs'
import { Title } from '../../components/Title'
import * as S from './styles'
import { getCustomers } from '../../services/accountsApi'
import * as yup from 'yup'
import Pagination from '../../components/Pagination'
import qs from 'qs'
import { getSubscriptionsBySubscribersIds } from '../../services/plansApi'
import Toast from '../../components/Toast'
import { CustomerResponse, TypeDataClient } from '../../models/CustomerResponse'
import { Table } from '../../styles/Table'
import { formatCPF, limitCaracteres } from '../../common/utils'
import Badge, { getBadgeByStatus } from '../../components/Badge'
import { isEmptyArray } from '../../common/validators'
import { hasPermission } from '../../services/userData'
import { ResourceType } from '../../models/ResourceType'

type CustomCustomerResponse = {
  loadingStatus?: boolean
} & CustomerResponse

const schema = yup.object().shape({
  search: yup.string().required().min(4)
})

const Clients = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [customers, setCustomers] = useState<CustomCustomerResponse[]>([])
  const [offset, setOffset] = useState(0)
  const [perPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState('')
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' }
  ]

  useEffect(() => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    const searchQuery: string = String(queryString.search)
    const pageQuery: string = String(queryString.page)
    setPage(pageQuery === 'undefined' ? '1' : pageQuery)
    setSearch(searchQuery === 'undefined' ? '' : searchQuery)
  }, [])

  useEffect(() => {
    if (!!search) {
      loadCustomers()
    }
  }, [search, offset, page])

  async function loadCustomers() {
    try {
      const isValid = schema.isValidSync({ search })
      if (!isValid) {
        setErrorMessage('Preencha no mínimo 4 caracteres')
        return
      }

      setErrorMessage('')
      navigate({
        search: qs.stringify({
          search,
          page
        })
      })
      setIsLoading(true)
      const response = await getCustomers(search, Number(page), perPage)
      const { data: customers, pagination } = response.data
      setCustomers(
        customers.map((customer) => ({ ...customer, loadingStatus: true }))
      )
      setTotalItems(pagination.total_items)
      loadSubscriptionStatus(customers)
    } finally {
      setIsLoading(false)
    }
  }

  async function loadSubscriptionStatus(customers: CustomCustomerResponse[]) {
    try {
      if (isEmptyArray(customers)) return

      const subscribersId: string[] = await customers
        .filter((customer) => customer.client_type === 'SUBSCRIBER')
        .map((customer) => customer.subscriber_id)
      const { data: subscriptions } = await getSubscriptionsBySubscribersIds(
        subscribersId
      )
      if (isEmptyArray(subscriptions)) {
        setCustomers(
          customers.map((customer) => ({ ...customer, loadingStatus: false }))
        )
        return
      }

      const newCustomers = customers.map((customer) => {
        const [subscription] = subscriptions.filter(
          (sub: any) => sub.subscriber_id === customer.subscriber_id
        )
        if (subscription) {
          customer.status = subscription.status
        }
        return customer
      })
      setCustomers(
        newCustomers.map((customer) => ({ ...customer, loadingStatus: false }))
      )
    } catch {
      setCustomers(
        customers.map((customer) => ({ ...customer, loadingStatus: false }))
      )
      Toast.showError(
        'Não está sendo possível realizar a consulta dos Status da assinatura, tente novamente em instantes.'
      )
    }
  }

  function handleSelectClient(
    subscriberId: string,
    dependentId: string,
    userType: TypeDataClient
  ) {
    if (userType === 'SUBSCRIBER') {
      navigate(`/dashboard/clientes/${subscriberId}`)
    } else if (userType === 'DEPENDENT') {
      navigate(`/dashboard/clientes/${subscriberId}/dependentes/${dependentId}`)
    }
  }

  function translateTypeData(type: TypeDataClient) {
    switch (type) {
      case 'SUBSCRIBER':
        return (
          <S.SubscriberTypeClient title="Assinante">
            Assinante
          </S.SubscriberTypeClient>
        )
      case 'DEPENDENT':
        return (
          <S.DependentTypeClient title="Dependente">
            Dependente
          </S.DependentTypeClient>
        )
      default:
        return ''
    }
  }

  function getBadgeStatus(customer: CustomCustomerResponse) {
    if (customer.loadingStatus) {
      return 'Carregando...'
    }

    if (customer.client_type === 'DEPENDENT' && customer.active === false) {
      return <Badge title="Inativo" type="error" />
    }

    return getBadgeByStatus(customer.status)
  }

  function getContainerTable() {
    if (isLoading) {
      return <S.EmptyArea>Carregando...</S.EmptyArea>
    }

    const isValidSearch = schema.isValidSync({ search })
    if (isValidSearch && !customers.length) {
      return <S.EmptyArea>Nenhum cliente encontrado</S.EmptyArea>
    }

    return (
      <Table enableShadow enableHover>
        <thead>
          <tr>
            <th>Tipo cliente</th>
            <th>Nome</th>
            <th>Nome social</th>
            <th>CPF</th>
            <th>E-mail</th>
            <th>Status assinatura</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer, index) => (
            <tr
              key={index}
              onClick={() =>
                handleSelectClient(
                  customer.subscriber_id,
                  customer.dependent_id,
                  customer.client_type
                )
              }
            >
              <td>{translateTypeData(customer.client_type)}</td>
              <td>{limitCaracteres(customer.name, 40, true)}</td>
              <td>{limitCaracteres(customer.social_name, 40, true) || '-'}</td>
              <td>{formatCPF(customer.cpf)}</td>
              <td>{customer.email}</td>
              <td>{getBadgeStatus(customer)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Clientes'} />
          {hasPermission(ResourceType.CREATE_NEW_SUBSCRIBER) && (
            <DefaultButton
              label="Novo assinante"
              type="button"
              loading={false}
              onClick={() => navigate(`/dashboard/clientes/novo`)}
            />
          )}
        </S.ContainerTitle>
        <S.ContainerTabs>
          <Tabs items={['Pessoa Física']} />
        </S.ContainerTabs>
        <SearchInput
          placeholder="Busque por nome, e-mail ou CPF"
          onChange={setSearch}
          value={search}
          errorMessage={errorMessage}
        />
        <S.ContainerTable>
          {getContainerTable()}
          <S.ContainerPagination>
            {totalItems > perPage && (
              <Pagination
                totalItems={totalItems}
                offset={offset}
                setOffset={setOffset}
                perPage={perPage}
                setPage={setPage}
                currentPage={page}
              />
            )}
          </S.ContainerPagination>
        </S.ContainerTable>
      </S.Container>
    </S.ContainerPage>
  )
}

export default Clients
