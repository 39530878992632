import styled from 'styled-components'
import Colors from '../../../../common/colors'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const BodyCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`

export const Loading = styled.div`
  text-align: center;
  width: 100%;
`

export const AlertMessage = styled.p`
  font-weight: 500;
  font-size: 18px;
  margin-top: 16px;
`

export const ContainerBoxDownload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
`

export const ContainerBodyDownload = styled.div`
  text-align: center;
`

export const ContainerBoxSelectFile = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 16px;
  border: 1px solid ${Colors.primary};
  border-radius: 8px;
  color: ${Colors.primary};
  margin-top: 16px;
  cursor: pointer;

  p {
    margin-left: 8px;
  }

  span {
    font-weight: bold;
  }

  &:hover {
    opacity: 0.8;
  }
`

type ContainerFileType = {
  showContainerFile?: boolean
}

export const ContainerFile = styled.div<ContainerFileType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-top: 16px;
  ${({ showContainerFile }) => !showContainerFile && `display: none;` }
`

export const ContainerFileName = styled.div`
  display: flex;

  p {
    margin-left: 16px;
  }
`

export const ContainerFileOptions = styled.div`
  display: flex;

  svg {
    cursor: pointer;
  }

  &:hover {
    color: ${Colors.primary};
  }
`

export const InputFile = styled.input.attrs(props => ({
  type: 'file',
}))`
  display: none;
`

export const BoxButton = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 100%;
    max-width: 200px;
  }

  p {
    margin-right: 16px;
    color: ${Colors.error};
  }
`

export const ErrorMessage = styled.p`
  margin-top: 16px;
  margin-right: 16px;
  color: ${Colors.error};
`
