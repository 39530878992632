import { Masks } from './masks'

export function validatePhone(phone: string): boolean {
  phone = Masks.onlyNumbers(phone)
  return [10, 11].includes(phone.length)
}

export function validateCep(cep: string): boolean {
  cep = Masks.onlyNumbers(cep)
  return cep.length === 8
}

export function isEmpty(text: string) {
  return !text
}

export function isEmptyArray(values: any[]) {
  return !values || !values.length
}

export function isNotEmptyArray(values: any[]) {
  return values && values.length
}

export function isNumber(value: string | number) {
  return !!Number(value)
}

export function isValidCpf(cpf: string): boolean {
  const cpfNumber = cpf.replace(/[^\d]+/g, '')
  if (cpfNumber != cpf) return false
  if (cpfNumber.length !== 11 || !!cpfNumber.match(/(\d)\1{10}/)) return false

  const cpfArray = cpfNumber.split('')
  const validator = cpfArray
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el)

  const toValidate = (pop: number) =>
    cpfArray
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el)
  const rest = (count: number, pop: number) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
}

export function checkPasswordComplexity(password: string): boolean {
  const specialCaracteres = '({`^};-_)($%#(@.*!&etc.)'.split('')
  if (!password) return false
  if (password.length < 8) return false
  const passwordSplitted = password.split('')
  const hasSpecialCaracteres = !!passwordSplitted.filter((p) =>
    specialCaracteres.includes(p)
  ).length
  if (!hasSpecialCaracteres) return false
  const hasUpper = /[A-Z]/.test(password)
  if (!hasUpper) return false
  const hasLower = /[a-z]/.test(password)
  if (!hasLower) return false
  const hasNumber = !!Masks.onlyNumbers(password)
  if (!hasNumber) return false
  return true
}

export function limitDate(date: string) {
  const currentAge = getCurrentAge(date)
  if (currentAge < 18 || currentAge > 110) return false

  return true
}

export function limitDateDependent(date: string) {
  const currentAge = getCurrentAge(date)
  if (currentAge < 0 || currentAge > 110) return false

  return true
}

export const getCurrentAge = (birth: string) => {
  const today = new Date()
  const birthDate = new Date(birth)

  let ageDiff = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    ageDiff--
  }
  return ageDiff
}
