import { useEffect, useState } from 'react'
import { BiMailSend } from 'react-icons/bi'
import { BsDownload } from 'react-icons/bs'
import { IoMdAdd } from 'react-icons/io'
import { MdOutlineEdit } from 'react-icons/md'
import { FiLoader } from 'react-icons/fi'
import { RiCloseLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import {
  convertUSDateInBRLDateTime,
  dateFileName,
  handleDownloadBlob,
  handleViewBlob,
  translatePaymentMethod
} from '../../../../common/utils'
import { getBadgeByStatus } from '../../../../components/Badge'
import { Subscription } from '../../../../models/Subscription'
import {
  getContractSignedBySubscription,
  getPlansById,
  getReceiptPayment,
  sendPaymentLink
} from '../../../../services/plansApi'
import { Table } from '../../../../styles/Table'
import * as S from '../styles'
import CancelModal from './CancelModal'
import Toast from '../../../../components/Toast'
import { PaymentMethodType } from '../../../../models/PaymentMethodType'
import { hasPermission } from '../../../../services/userData'
import { ResourceType } from '../../../../models/ResourceType'

type Props = {
  isLoading: boolean
  subscriberId?: string
  blockSubscriptionActions: boolean
  subscription?: Subscription
  onReloadSubscriptions?: () => void
}

const SubscriptionSession = ({
  isLoading,
  subscriberId,
  subscription,
  onReloadSubscriptions,
  blockSubscriptionActions,
}: Props) => {
  const navigate = useNavigate()
  const [planName, setPlanName] = useState('')
  const [sendingEmail, setSendingEmail] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [downloadingContract, setDownloadingContract] = useState(false)

  useEffect(() => {
    async function init() {
      if (!subscription?.plan_id) return
      const response = await getPlansById(subscription?.plan_id)
      setPlanName(response.data.name)
    }
    init()
  }, [subscription])

  function handleNewSubscription() {
    if (subscriberId) {
      navigate(`/dashboard/clientes/${subscriberId}/subscriptions`)
    }
  }

  function handleEditSubscription() {
    if (subscriberId && subscription?.subscription_id) {
      navigate(
        `/dashboard/clientes/${subscriberId}/subscriptions/${subscription?.subscription_id}/edit`
      )
    }
  }

  function handleDownloadReceipt() {
    getReceiptPayment(String(subscription?.subscription_id))
      .then((res) => {
        const blob = res.data
        const fileName = dateFileName()
        handleViewBlob(blob, `receipt_payment_${fileName}.pdf`)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function handleDownloadContract() {
    if (!subscription?.subscription_id) return
    if (downloadingContract) return

    setDownloadingContract(true)
    getContractSignedBySubscription(subscription?.subscription_id)
      .then((res) => {
        const blob = res.data
        const fileName = dateFileName()
        handleDownloadBlob(blob, `contrato_${fileName}.pdf`)
        setDownloadingContract(false)
        Toast.showSuccess('Download realizado com sucesso!')
      })
      .catch((_) => {
        setDownloadingContract(false)
        Toast.showError('Não foi possível realizar o download do arquivo!')
      })
  }

  function handleCancelSubscription() {
    setShowCancelModal(true)
  }

  function handleSendPaymentMethod() {
    if (subscriberId && subscription?.subscription_id) {
      setSendingEmail(true)
      sendPaymentLink(subscription?.subscription_id)
        .then(() => {
          Toast.showSuccess('E-mail enviado com sucesso!')
          setSendingEmail(false)
        })
        .catch(() => {
          Toast.showError(
            'Desculpe, mas não está sendo possível enviar o e-mail no momento!'
          )
          setSendingEmail(false)
        })
    }
  }

  function handleConfirmedCancel() {
    setShowCancelModal(false)
    onReloadSubscriptions && onReloadSubscriptions()
  }

  function showCreateNewSubscriptionOption() {
    if (
      !subscription ||
      (['canceled', 'expired'].includes(subscription.status) &&
        hasPermission(ResourceType.CREATE_SUBSCRIPTION))
    ) {
      return (
        <S.HeaderOption primary onClick={handleNewSubscription}>
          <IoMdAdd />
          <S.HeaderOptionLabel>Nova assinatura</S.HeaderOptionLabel>
        </S.HeaderOption>
      )
    }
  }

  function showSendEmailOption() {
    if (
      subscription &&
      ['pending'].includes(subscription.status) &&
      subscription.payment_kind === 'link' &&
      hasPermission(ResourceType.RESEND_SUBSCRIPTION_LINK)
    ) {
      return (
        <S.HeaderOption primary onClick={handleSendPaymentMethod}>
          {sendingEmail ? <FiLoader className="loading" /> : <BiMailSend />}
          <S.HeaderOptionLabel>Reenviar link de pagamento</S.HeaderOptionLabel>
        </S.HeaderOption>
      )
    }
  }

  function showDownloadReceiptOption() {
    if (subscription && ['active'].includes(subscription.status)) {
      return (
        <S.HeaderOption primary onClick={handleDownloadReceipt}>
          <BsDownload />
          <S.HeaderOptionLabel>Baixar recibo</S.HeaderOptionLabel>
        </S.HeaderOption>
      )
    }
  }

  function showDownloadContractOption() {
    if (subscription && ['active'].includes(subscription.status)) {
      return (
        <S.HeaderOption primary onClick={handleDownloadContract}>
          {downloadingContract ? (
            <FiLoader className="loading" />
          ) : (
            <BsDownload />
          )}
          <S.HeaderOptionLabel>Baixar contrato</S.HeaderOptionLabel>
        </S.HeaderOption>
      )
    }
  }

  function showCancelSubscriptionOption() {
    if (
      subscription &&
      ['active', 'pending'].includes(subscription.status) &&
      subscription.payment_kind !== 'link' &&
      hasPermission(ResourceType.CANCEL_SUBSCRIPTION)
    ) {
      return (
        <S.HeaderOption onClick={handleCancelSubscription}>
          <RiCloseLine />
          <S.HeaderOptionLabel>Cancelar assinatura</S.HeaderOptionLabel>
        </S.HeaderOption>
      )
    }
  }

  function showEditSubscriptionOption() {
    if (subscription && ['pending'].includes(subscription.status)) {
      return (
        <S.HeaderOption onClick={handleEditSubscription}>
          <MdOutlineEdit />
          <S.HeaderOptionLabel>Editar</S.HeaderOptionLabel>
        </S.HeaderOption>
      )
    }
  }

  return (
    <S.ContainerCard>
      <S.HeaderCard>
        <S.HeaderTitle>Assinatura</S.HeaderTitle>
        <S.HeaderOptions hideOptions={isLoading || blockSubscriptionActions}>
          {showCreateNewSubscriptionOption()}
          {showSendEmailOption()}
          {showDownloadReceiptOption()}
          {showDownloadContractOption()}
          {showCancelSubscriptionOption()}
          {showEditSubscriptionOption()}
        </S.HeaderOptions>
      </S.HeaderCard>
      {isLoading && <S.LoadingArea>Carregando...</S.LoadingArea>}
      <S.BodyCard hidden={isLoading || !subscription}>
        <Table>
          <thead>
            <tr>
              <th>Plano</th>
              <th>Método de pagamento</th>
              <th>Vigência do contrato</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{planName || 'N/A'}</td>
              <td>
                {translatePaymentMethod(
                  subscription?.payment_kind as PaymentMethodType
                )}
              </td>
              <td>
                {convertUSDateInBRLDateTime(subscription?.start_at) || 'N/A'} -{' '}
                {convertUSDateInBRLDateTime(subscription?.end_at) || 'N/A'}
              </td>
              <td>{getBadgeByStatus(subscription?.status)}</td>
            </tr>
          </tbody>
        </Table>
      </S.BodyCard>
      <CancelModal
        showModal={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirmed={handleConfirmedCancel}
        subscriptionId={subscription?.subscription_id}
      />
    </S.ContainerCard>
  )
}

export default SubscriptionSession
