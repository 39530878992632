import styled from 'styled-components'
import Colors from '../../../common/colors'
import DataPicker from 'react-datepicker'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`

export const ContainerCard = styled.form`
  background-color: ${Colors.white};
  box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
`

export const Row = styled.div`
  display: flex;
  padding: 8px;
`

export const Col = styled.div`
  width: 100%;
  padding: 8px;

  input {
    width: 100%;
  }
`

export const Label = styled.strong`
  margin-top: 10px;
`

export const Value = styled.div`
  margin-top: 10px;
`

export const BoxButton = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button {
      max-width: 120px;
    }
  }

  button {
    width: 100%;
    max-width: 120px;
  }

  p {
    margin-right: 16px;
    color: ${Colors.error};
  }
`

export const WrapperDate = styled(DataPicker)`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid rgb(188, 189, 189);
  box-sizing: border-box;
  border-radius: 4px;

  &:focus {
    border: 1px solid ${Colors.primary};
    box-shadow: inset 1px 2px 4px rgba(158, 63, 135, 0.01),
      0px 0px 8px rgba(158, 63, 135, 0.2);
  }

  &:disabled {
    border: 1px solid ${Colors.neutralColorLight};
    background-color: rgb(225, 225, 225);
  }
`

export const MessageError = styled.span`
  color: ${Colors.error};
  font-size: 10px;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
`

type IAction = {
  color?: 'error' | 'primary' | 'warning' | 'success'
}

export const Action = styled.div<IAction>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.primary};
  ${({ color }) => color === 'error' && `color: ${Colors.error};`}
  ${({ color }) => color === 'warning' && `color: ${Colors.warning};`}
  ${({ color }) => color === 'success' && `color: ${Colors.success};`}

  svg {
    margin-right: 4px;
  }

  & + & {
    margin-left: 16px;
  }

  &:hover {
    opacity: 0.7;
  }
`
