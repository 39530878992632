import React from 'react'

import { Input, MessageError, Container } from './styles'

export interface ITextArea {
	maxLength?: number
  rows?: number
	placeholder?: string
	value?: string
	messageError?: string
	onChange?: (event: string) => void
	onBlur?: () => void
}

const TextArea: React.FC<ITextArea> = ({
	maxLength,
	placeholder,
	onChange,
	messageError,
	value,
	onBlur,
  rows = 5
}) => {
	return (
		<Container>
			<Input
				maxLength={maxLength}
				placeholder={placeholder}
				onChange={e => onChange && onChange(e.target.value)}
				value={value}
				onBlur={onBlur}
        rows={rows}
			/>
			{messageError && <MessageError>{messageError}</MessageError>}
		</Container>
	)
}

export default TextArea
