import styled from 'styled-components'
import Colors from '../../common/colors'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`

export const ContainerItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`

type IContainerItem = {
  selected: boolean
}

export const ContainerItem = styled.div<IContainerItem>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  max-width: 276px;
  cursor: pointer;
  padding: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  border: 1px solid #CDCDCD;
  ${({selected}) => selected && `border: 1px solid ${Colors.primary};`}

  svg {
    font-size: 32px;
    color: #b6b5b5;
    ${({selected}) => selected && `color: ${Colors.primary};`}
  }

  &:hover {
    box-shadow: 0px 3px 11px -4px rgba(22, 34, 51, 0.15);
    filter: drop-shadow(0px -3px 17px rgba(0, 0, 0, 0.15));
  }
`

export const ContainerBody = styled.div`
  margin-left: 16px;
`

export const Name = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
`

export const Address = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 175.5%;
`
