import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import { Title } from '../../../components/Title'
import * as S from './styles'
import { MdCheck, MdClose, MdOutlineEdit } from 'react-icons/md'
import { useEffect, useState } from 'react'
import {
  getDependentReport,
  getDependent,
  activeDependent,
  inactiveDependent
} from '../../../services/accountsApi'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Dependent } from '../../../models/Dependent'
import {
  formatBirthDate,
  formatBirthSex,
  formatCPF,
  handleDownloadBlob
} from '../../../common/utils'
import DefaultButton from '../../../components/DefaultButton'
import Badge from '../../../components/Badge'
import Toast from '../../../components/Toast'

const ShowDependent = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const [changingStatus, setChangingStatus] = useState(false)
  const [dependent, setDependent] = useState<Dependent>()
  const { subscriber_id, dependent_id } = useParams()
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Dados do Dependente' }
  ]

  useEffect(() => {
    if (!dependent_id) return
    setIsLoading(true)
    getDependent(dependent_id)
      .then((response) => {
        setIsLoading(false)
        setDependent(response.data)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error)
      })
  }, [])

  function generateDependentReport() {
    if (!dependent_id || !dependent) return
    setIsDownloading(true)
    getDependentReport(dependent_id)
      .then((res) => {
        setIsDownloading(false)
        const blob = res.data
        handleDownloadBlob(blob, `Relatório LGPD - ${dependent.name}.pdf`)
      })
      .catch((error) => {
        setIsDownloading(false)
        console.error(error)
      })
  }

  async function handleChangeDependentStatus() {
    if (!dependent) return
    if (changingStatus) return

    try {
      setChangingStatus(true)
      if (dependent.active) {
        await inactiveDependent(String(dependent.id))
      } else {
        await activeDependent(String(dependent.id))
      }
      setChangingStatus(false)
      setDependent({
        ...dependent,
        active: !dependent.active
      })
    } catch (error) {
      setChangingStatus(false)

      const err = error as any
      const response = err.response
      if (response && response.data && response.data.message) {
        Toast.showError(err.response.data.message)
        return
      }

      Toast.showError(
        'Não foi possível atualizar o status do dependente, tente novamente em instantes.'
      )
    }
  }

  function handleSubscriber() {
    if (!subscriber_id) return
    navigate(`/dashboard/clientes/${subscriber_id}`)
  }

  function goBack() {
    const backToSubscriber = searchParams.get('backToSubscriber') === 'true'
    if (backToSubscriber) {
      handleSubscriber()
    } else {
      navigate(`/dashboard/clientes`)
    }
  }

  function handleEditAddress() {
    const backToSubscriber =
      searchParams.get('backToSubscriber') === 'true'
        ? '?backToSubscriber=true'
        : ''
    navigate(
      `/dashboard/clientes/${subscriber_id}/dependentes/${dependent_id}/editar-endereco${backToSubscriber}`
    )
  }

  function handleEditPersonalData() {
    const backToSubscriber =
      searchParams.get('backToSubscriber') === 'true'
        ? '?backToSubscriber=true'
        : ''
    navigate(
      `/dashboard/clientes/${subscriber_id}/dependentes/${dependent_id}/editar-dados-pessoais${backToSubscriber}`
    )
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Dados do Dependente'} />
        </S.ContainerTitle>
        <S.ContainerCard>
          <S.HeaderCard>
            <S.HeaderTitle>Dados pessoais</S.HeaderTitle>
            <S.HeaderOptions>
              {dependent && (
                <>
                  <S.HeaderOption
                    red={dependent?.active}
                    green={!dependent?.active}
                    onClick={handleChangeDependentStatus}
                  >
                    {dependent?.active && <MdClose size={20} />}
                    {!dependent?.active && <MdCheck size={20} />}
                    <S.HeaderOptionLabel>
                      {dependent?.active
                        ? 'Inativar dependente'
                        : 'Ativar dependente'}
                    </S.HeaderOptionLabel>
                  </S.HeaderOption>
                  <S.HeaderOption onClick={handleEditPersonalData}>
                    <MdOutlineEdit />
                    <S.HeaderOptionLabel>Editar</S.HeaderOptionLabel>
                  </S.HeaderOption>
                </>
              )}
            </S.HeaderOptions>
          </S.HeaderCard>
          {isLoading && <S.LoadingArea>Carregando...</S.LoadingArea>}
          <S.BodyCard hidden={isLoading}>
            <S.Row>
              <S.Col>
                <S.Label>Nome</S.Label>
                <S.Description>{dependent?.name}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Nome social</S.Label>
                <S.Description>{dependent?.social_name || 'N/A'}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Gênero</S.Label>
                <S.Description>{dependent?.gender}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Sexo Biológico</S.Label>
                <S.Description>
                  {formatBirthSex(dependent?.birth_sex)}
                </S.Description>
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Data de Nascimento</S.Label>
                <S.Description>
                  {formatBirthDate(dependent?.birth)}
                </S.Description>
              </S.Col>
              <S.Col>
                <S.Label>E-mail</S.Label>
                <S.Description>{dependent?.email}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Telefone</S.Label>
                <S.Description>{dependent?.phone}</S.Description>
              </S.Col>
              <S.Col />
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CPF</S.Label>
                <S.Description>
                  {dependent?.cpf ? formatCPF(dependent?.cpf) : 'N/A'}
                </S.Description>
              </S.Col>
              <S.Col>
                <S.Label>RG</S.Label>
                <S.Description>{dependent?.rg || 'N/A'}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Órgão Expedidor</S.Label>
                <S.Description>
                  {dependent?.issuing_agency || 'N/A'}
                  {dependent?.issuing_state || ''}
                </S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Status</S.Label>
                <S.Description>
                  {dependent?.active ? (
                    <Badge title="Ativo" type="success" />
                  ) : (
                    <Badge title="Inativo" type="error" />
                  )}
                </S.Description>
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Titular</S.Label>
                <S.LinkDescription onClick={handleSubscriber}>
                  {dependent?.subscriber.name || 'N/A'}
                </S.LinkDescription>
              </S.Col>
            </S.Row>
          </S.BodyCard>
        </S.ContainerCard>
        <S.ContainerCard>
          <S.HeaderCard>
            <S.HeaderTitle>Endereço</S.HeaderTitle>
            <S.HeaderOptions onClick={handleEditAddress}>
              <S.HeaderOption>
                <MdOutlineEdit />
                <S.HeaderOptionLabel>Editar</S.HeaderOptionLabel>
              </S.HeaderOption>
            </S.HeaderOptions>
          </S.HeaderCard>
          {isLoading && <S.LoadingArea>Carregando...</S.LoadingArea>}
          <S.BodyCard hidden={isLoading}>
            <S.Row>
              <S.Col>
                <S.Label>CEP</S.Label>
                <S.Description>{dependent?.address?.zip_code}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Rua/Avenida</S.Label>
                <S.Description>
                  {dependent?.address?.address_description}
                </S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Número</S.Label>
                <S.Description>
                  {dependent?.address?.address_number}
                </S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Complemento</S.Label>
                <S.Description>{dependent?.address?.complement}</S.Description>
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Bairro</S.Label>
                <S.Description>{dependent?.address?.district}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Cidade</S.Label>
                <S.Description>{dependent?.address?.city}</S.Description>
              </S.Col>
              <S.Col>
                <S.Label>Estado</S.Label>
                <S.Description>{dependent?.address?.state}</S.Description>
              </S.Col>
              <S.Col />
            </S.Row>
          </S.BodyCard>
        </S.ContainerCard>
        <S.ContainerButton>
          <DefaultButton label="Voltar" secondary onClick={goBack} />
          <DefaultButton
            label="Exportar Informações LGPD"
            onClick={generateDependentReport}
            loading={isDownloading}
          />
        </S.ContainerButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ShowDependent
