import axios from 'axios'
import { Masks } from '../common/masks'

type InfoCompanyCnpj = {
	cnpj: string
	razao_social: string

	estabelecimento: {
		tipo: string
		nome_fantasia: string
		cep: string
		numero: string
		tipo_logradouro: string
		logradouro: string
		complemento: string
		bairro: string
		cidade: {
			nome: string
		}

		estado: {
			sigla: string
		}
	}
}

export async function getDataCnpj(cnpj: string) {
	cnpj = Masks.onlyNumbers(cnpj)
	return await axios.get<InfoCompanyCnpj>(
		`https://publica.cnpj.ws/cnpj/${cnpj}`
	)
}
