import React, { useEffect, useState } from 'react'
import { FiSettings } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ResourceType } from '../../../models/ResourceType'
import { hasPermission } from '../../../services/userData'
import ItemMenu from '../ItemMenu'

interface IItemSubMenus {
  name: string
}

const ItemMenuGroupAdmin: React.FC<IItemSubMenus> = ({ name }) => {
  const navigate = useNavigate()
  const [arrowOpened, setArrowOpened] = useState(false)
  const userMenuActive = location.pathname.includes('/dashboard/usuarios')
  const salesChannelMenuActive = location.pathname.includes(
    '/dashboard/canais-de-venda'
  )
  const planMenuActive = location.pathname.includes('/dashboard/planos')
  const accessProfileActive = location.pathname.includes(
    '/dashboard/perfis-de-acesso'
  )
  const resourcesMenuActive = location.pathname.includes('/dashboard/recursos')

  useEffect(() => {
    setArrowOpened(
      userMenuActive ||
        salesChannelMenuActive ||
        planMenuActive ||
        accessProfileActive ||
        resourcesMenuActive
    )
  }, [location.pathname])

  return (
    <>
      <ItemMenu
        active={false}
        name={name}
        onClick={() => setArrowOpened(!arrowOpened)}
        icon={<FiSettings size={25} />}
        enableArrow
        arrowOpen={arrowOpened}
      />
      {arrowOpened && (
        <>
          {hasPermission(ResourceType.SHOW_SALES_CHANNEL) && (
            <ItemMenu
              active={salesChannelMenuActive}
              name={'Canais de Venda'}
              onClick={() => navigate('/dashboard/canais-de-venda')}
            />
          )}
          {hasPermission(ResourceType.SHOW_PLAN) && (
            <ItemMenu
              active={planMenuActive}
              name={'Planos'}
              onClick={() => navigate('/dashboard/planos')}
            />
          )}
          {hasPermission(ResourceType.SHOW_BACKOFFICE_USERS) && (
            <ItemMenu
              active={userMenuActive}
              name={'Usuários'}
              onClick={() => navigate('/dashboard/usuarios')}
            />
          )}
          {hasPermission(ResourceType.SHOW_ACCESS_PROFILE) && (
            <ItemMenu
              active={accessProfileActive}
              name={'Perfis de Acesso'}
              onClick={() => navigate('/dashboard/perfis-de-acesso')}
            />
          )}
          {hasPermission(ResourceType.SHOW_RESOURCE) && (
            <ItemMenu
              active={resourcesMenuActive}
              name={'Recursos'}
              onClick={() => navigate('/dashboard/recursos')}
            />
          )}
        </>
      )}
    </>
  )
}

export default ItemMenuGroupAdmin
