import { ItemBadge, TypeBadge, Wrapper } from './styles'

type IBadge = {
  title: string
  type?: TypeBadge
}

const Badge = ({ title, type }: IBadge) => {
  return (
    <Wrapper>
      <ItemBadge type={type}>{title}</ItemBadge>
    </Wrapper>
  )
}

export function getBadgeByStatus(status?: string) {
  switch (status) {
    case 'active':
      return <Badge title="Ativo" type="success" />
    case 'pending':
      return <Badge title="Pendente" type="warning" />
    case 'canceled':
      return <Badge title="Cancelada" type="error" />
    case 'expired':
        return <Badge title="Expirada" />
    default:
      return <Badge title="N/A" />
  }
}

export function getBadgeByActiveStatus(active?: boolean) {
  switch (active) {
    case true:
      return <Badge title="Ativo" type="success" />
    case false:
      return <Badge title="Inativo" />
    default:
      return <Badge title="N/A" />
  }
}

export default Badge
