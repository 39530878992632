import Breadcrumb, { ISections } from '../../../../components/Breadcrumb'
import Title from '../../../../components/Title'
import TextInput from '../../../../components/TextInput'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { Masks } from '../../../../common/masks'
import { getAddressByCep } from '../../../../services/cep'
import DefaultButton from '../../../../components/DefaultButton'
import { validateBr } from 'js-brasil'
import {
  isEmpty,
  limitDate,
  validateCep,
  validatePhone
} from '../../../../common/validators'
import Select, { IOption } from '../../../../components/Select'
import { getGenders, newSubscriber } from '../../../../services/accountsApi'
import { Subscriber } from '../../../../models/Subscriber'
import { formatToUSDate } from '../../../../common/utils'
import { useNavigate } from 'react-router-dom'
import Card from '../../../../components/Card'

type ValueField = {
  value: string
  isInvalid?: boolean
}

const StepNewSubscriber = () => {
  const [messageError, setMessageError] = useState('')
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<ValueField>({ value: '' })
  const [socialName, setSocialName] = useState<ValueField>({ value: '' })
  const [cpf, setCpf] = useState<ValueField>({ value: '' })
  const [email, setEmail] = useState<ValueField>({ value: '' })
  const [phone, setPhone] = useState<ValueField>({ value: '' })
  const [birthDate, setBirthDate] = useState<ValueField>({ value: '' })
  const [biologicalSex, setBiologicalSex] = useState<ValueField>({ value: '' })
  const [sex, setSex] = useState<ValueField>({ value: '' })
  const [zipCode, setZipCode] = useState<ValueField>({ value: '' })
  const [street, setStreet] = useState<ValueField>({ value: '' })
  const [number, setNumber] = useState<ValueField>({ value: '' })
  const [complement, setComplement] = useState<ValueField>({ value: '' })
  const [state, setState] = useState<ValueField>({ value: '' })
  const [city, setCity] = useState<ValueField>({ value: '' })
  const [district, setDistrict] = useState<ValueField>({ value: '' })
  const [genders, setGenders] = useState<IOption[]>([])
  const navigate = useNavigate()

  const biologicalSexes: IOption[] = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' }
  ]
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Cadastro do Titular (passo 1 de 3)' }
  ]

  useEffect(() => {
    searchZipCode()
  }, [zipCode])

  useEffect(() => {
    getGenders().then((response) => {
      if (response && response.data) {
        const options: IOption[] = response.data.map((gender) => ({
          value: gender.code,
          label: gender.description
        }))
        setGenders(options)
      }
    })
  }, [])

  function searchZipCode() {
    const zipCodeNumbers = Masks.onlyNumbers(zipCode.value)
    if (zipCodeNumbers.length === 8) {
      getAddressByCep(zipCodeNumbers)
        .then((response) => {
          if (response && response.data) {
            const address = response.data
            setCity({ value: address.localidade })
            setStreet({ value: address.logradouro })
            setDistrict({ value: address.bairro })
            setState({ value: address.uf })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  function validateFields(): boolean {
    let isValid = true

    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    if (!validateBr.cpf(cpf.value)) {
      setCpf({ ...cpf, isInvalid: true })
      isValid = false
    }

    if (isEmpty(email.value)) {
      setEmail({ ...email, isInvalid: true })
      isValid = false
    }

    if (!validatePhone(phone.value)) {
      setPhone({ ...phone, isInvalid: true })
      isValid = false
    }

    if (isEmpty(biologicalSex.value)) {
      setBiologicalSex({ ...biologicalSex, isInvalid: true })
      isValid = false
    }

    if (isEmpty(sex.value)) {
      setSex({ ...sex, isInvalid: true })
      isValid = false
    }

    if (!validateCep(zipCode.value)) {
      setZipCode({ ...zipCode, isInvalid: true })
      isValid = false
    }

    if (isEmpty(street.value)) {
      setStreet({ ...street, isInvalid: true })
      isValid = false
    }

    if (isEmpty(number.value)) {
      setNumber({ ...number, isInvalid: true })
      isValid = false
    }

    if (isEmpty(state.value)) {
      setState({ ...state, isInvalid: true })
      isValid = false
    }

    if (isEmpty(city.value)) {
      setCity({ ...city, isInvalid: true })
      isValid = false
    }

    if (isEmpty(district.value)) {
      setDistrict({ ...district, isInvalid: true })
      isValid = false
    }

    if (isEmpty(birthDate.value) || !limitDate(birthDate.value)) {
      setBirthDate({ ...birthDate, isInvalid: true })
      isValid = false
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!validateFields()) return

    try {
      setMessageError('')
      setLoading(true)
      const data: Subscriber = {
        name: name.value,
        social_name: socialName.value,
        cpf: Masks.onlyNumbers(cpf.value),
        email: email.value,
        phone: Masks.onlyNumbers(phone.value),
        birth: formatToUSDate(birthDate.value),
        birth_sex: biologicalSex.value,
        gender: sex.value,
        address: {
          address_description: street.value,
          address_number: number.value,
          complement: complement.value,
          district: district.value,
          city: city.value,
          state: state.value,
          zip_code: zipCode.value
        }
      }
      const response = await newSubscriber(data)
      navigate(`/dashboard/clientes/${response.data.id}/subscriptions`)
    } catch (error) {
      setLoading(false)
      const err = error as any
      setMessageError(err.response.data.message)
    }
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Cadastro do titular'} />
        </S.ContainerTitle>
        <Card>
          <S.Form onSubmit={handleSubmit}>
            <S.Row>
              <S.Col>
                <S.Label>Nome Completo *</S.Label>
                <TextInput
                  maxLength={50}
                  value={name.value}
                  onChange={(value) =>
                    setName({ value: Masks.inputName(value).toUpperCase() })
                  }
                  messageError={
                    name.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Nome Social</S.Label>
                <TextInput
                  maxLength={50}
                  value={socialName.value}
                  onChange={(value) =>
                    setSocialName({
                      value: Masks.inputName(value).toUpperCase()
                    })
                  }
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CPF *</S.Label>
                <TextInput
                  maxLength={50}
                  value={cpf.value}
                  onChange={(value) => setCpf({ value: Masks.cpf(value) })}
                  messageError={cpf.isInvalid ? 'Campo vazio ou inválido' : ''}
                />
              </S.Col>
              <S.Col>
                <S.Label>E-mail *</S.Label>
                <TextInput
                  maxLength={50}
                  value={email.value}
                  onChange={(value) => setEmail({ value })}
                  messageError={
                    email.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Celular *</S.Label>
                <TextInput
                  value={phone.value}
                  onChange={(value) => setPhone({ value: Masks.phone(value) })}
                  messageError={
                    phone.isInvalid ? 'Campo vazio ou inválido' : ''
                  }
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Data de Nascimento *</S.Label>
                <TextInput
                  placeholder="DD/MM/YYYY"
                  value={birthDate.value}
                  onChange={(value) =>
                    setBirthDate({ value: Masks.dateDDMMYYYY(value) })
                  }
                  messageError={
                    birthDate.isInvalid
                      ? 'Campo vazio ou limite de data inválido'
                      : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Sexo Biológico *</S.Label>
                <Select
                  title="Escolha um Sexo"
                  options={biologicalSexes}
                  value={biologicalSex.value}
                  onChange={(event) =>
                    setBiologicalSex({ value: event.target.value })
                  }
                  messageError={
                    biologicalSex.isInvalid ? 'Campo deve ser selecionado' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Gênero *</S.Label>
                <Select
                  title="Escolha um Gênero"
                  options={genders}
                  value={sex.value}
                  onChange={(event) => setSex({ value: event.target.value })}
                  messageError={
                    sex.isInvalid ? 'Campo deve ser selecionado' : ''
                  }
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CEP *</S.Label>
                <TextInput
                  value={zipCode.value}
                  onChange={(value) => setZipCode({ value: Masks.cep(value) })}
                  onBlur={searchZipCode}
                  messageError={
                    zipCode.isInvalid ? 'Campo vazio ou inválido' : ''
                  }
                />
              </S.Col>
              <S.Col></S.Col>
              <S.Col></S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Logradouro *</S.Label>
                <TextInput
                  maxLength={50}
                  value={street.value}
                  onChange={(value) => setStreet({ value })}
                  messageError={
                    street.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Número *</S.Label>
                <TextInput
                  maxLength={50}
                  value={number.value}
                  onChange={(value) =>
                    setNumber({ value: Masks.onlyNumbers(value) })
                  }
                  messageError={
                    number.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Complemento</S.Label>
                <TextInput
                  maxLength={50}
                  value={complement.value}
                  onChange={(value) => setComplement({ value })}
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Estado *</S.Label>
                <TextInput
                  maxLength={50}
                  value={state.value}
                  onChange={(value) => setState({ value })}
                  messageError={
                    state.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Cidade *</S.Label>
                <TextInput
                  maxLength={50}
                  value={city.value}
                  onChange={(value) => setCity({ value })}
                  messageError={
                    city.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Bairro *</S.Label>
                <TextInput
                  maxLength={50}
                  value={district.value}
                  onChange={(value) => setDistrict({ value })}
                  messageError={
                    district.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
            </S.Row>
            <S.BoxButton>
              {messageError && <p>{messageError}</p>}
              <DefaultButton
                label="Salvar e avançar"
                type="submit"
                loading={loading}
              />
            </S.BoxButton>
          </S.Form>
        </Card>
      </S.Container>
    </S.ContainerPage>
  )
}

export default StepNewSubscriber
