import styled from 'styled-components'
import Colors from '../../../common/colors'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const Form = styled.form``

export const Row = styled.div`
  display: flex;
  padding: 8px;
`

export const Col = styled.div`
  width: 100%;
  padding: 1px 8px;
  input {
    width: 100%;
  }
`
export const Col2 = styled.div`
  width: 75%;
  padding: 1px 8px;
  input {
    width: 100%;
  }
`

export const Label = styled.div`
  margin-top: 10px;
`

export const BoxButton = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 100%;
    max-width: 200px;
  }

  p {
    margin-right: 16px;
    color: ${Colors.error};
  }
`
