import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import { Title } from '../../../components/Title'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Subscriber } from '../../../models/Subscriber'
import {
  getSubscriber,
  getSubscriberReport
} from '../../../services/accountsApi'
import { handleDownloadBlob } from '../../../common/utils'
import DefaultButton from '../../../components/DefaultButton'
import { getSubscriptionsBySubscriberId } from '../../../services/plansApi'
import DependentsSession from './DependentsSession'
import AddressSession from './AddressSession'
import SubscriptionHistorySession from './SubscriptionHistorySession'
import SubscriberDataSession from './SubscriberDataSession'
import SubscriptionSession from './SubscriptionSession'
import { Subscription } from '../../../models/Subscription'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'
import Toast from '../../../components/Toast'

const ShowSubscriber = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [exportingData, setExportingData] = useState(false)
  const [blockSubscriptionActions, setBlockSubscriptionActions] = useState(false)
  const [subscriber, setSubscriber] = useState<Subscriber>()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [subscription, setSubscription] = useState<Subscription>()
  const { id } = useParams()
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Dados do Assinante' }
  ]

  useEffect(() => {
    loadSubscriber()
    loadSubscriptions()
  }, [])

  function loadSubscriptions() {
    if (!id) return
    setIsLoading(true)
    setBlockSubscriptionActions(false)
    getSubscriptionsBySubscriberId(id)
      .then((response) => {
        setBlockSubscriptionActions(false)
        setIsLoading(false)
        if (response.data && response.data.length) {
          const [lastSub, ...othersSubs] = response.data
          setSubscription(lastSub)
          setSubscriptions(othersSubs)
        }
      })
      .catch((error) => {
        console.error(error)
        setBlockSubscriptionActions(true)
        setIsLoading(false)
        Toast.showError(
          'Não foi possível carregar os dados de assinaturas, tente novamente em instantes!'
        )
      })
  }

  function loadSubscriber() {
    if (!id) return
    setIsLoading(true)
    getSubscriber(id)
      .then((response) => {
        setIsLoading(false)
        setSubscriber(response.data)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error)
        Toast.showError(
          'Não foi possível carregar os dados do Assinante, tente novamente em instantes!'
        )
      })
  }

  function generateSubscriberReport() {
    if (!id || !subscriber) return

    setExportingData(true)
    getSubscriberReport(id)
      .then((res) => {
        setExportingData(false)
        const blob = res.data
        handleDownloadBlob(blob, `Relatório LGPD - ${subscriber.name}.pdf`)
      })
      .catch((error) => {
        console.error(error)
        setExportingData(false)
        Toast.showError(
          'Não foi possível realizar o download do relatório, tente novamente em instantes!'
        )
      })
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Dados do Assinante'} />
        </S.ContainerTitle>
        <SubscriptionSession
          isLoading={isLoading}
          subscriberId={subscriber?.id}
          subscription={subscription}
          onReloadSubscriptions={loadSubscriptions}
          blockSubscriptionActions={blockSubscriptionActions}
        />
        <SubscriberDataSession isLoading={isLoading} subscriber={subscriber} />
        <AddressSession isLoading={isLoading} address={subscriber?.address} />
        <DependentsSession
          isLoading={isLoading}
          dependents={subscriber?.dependents}
        />
        <SubscriptionHistorySession
          isLoading={isLoading}
          subscriptions={subscriptions}
        />
        <S.ContainerButton>
          {hasPermission(ResourceType.EXPORT_LGPD_DATA) && (
            <DefaultButton
              label="Exportar Informações LGPD"
              type="button"
              onClick={generateSubscriberReport}
              loading={exportingData}
            />
          )}
        </S.ContainerButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ShowSubscriber
