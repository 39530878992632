import React from 'react'
import * as S from './styles'
import chevronsRight from '../../assets/icons/chevrons-right.svg'
import { useNavigate } from 'react-router-dom'

export type ISections = {
  description: string
  path?: string
}

type IBreadcrumb = {
  sections: ISections[]
}

const Breadcrumb = ({ sections }: IBreadcrumb) => {
  const navigate = useNavigate()

  function addSection(section: ISections, isLast: boolean, index: number) {
    return (
      <React.Fragment key={index}>
        <S.Section
          isActive={isLast}
          onClick={() => section.path && navigate(section.path)}
        >
          {section.description}
        </S.Section>
        {!isLast && <img src={chevronsRight} alt='Setas para a direita' />}
      </React.Fragment>
    )
  }

  return (
    <S.Container>
      {sections.map((el, index, array) =>
        addSection(el, index === array.length - 1, index)
      )}
    </S.Container>
  )
}

export default Breadcrumb
