import styled from 'styled-components'

interface ITabs {
  activeTab: boolean
}

export const TabContainer = styled.div``

export const TabItems = styled.label<ITabs>`
  font-size: 18px;
  font-weight: 400;
  color: #000;
  cursor: pointer;
  ${({ activeTab }) => (!!activeTab ? `color: #933c85;` : ``)}
  ${({ activeTab }) => (!!activeTab ? `border-bottom: 2px solid #933c85;` : ``)}

  &:hover {
    opacity: 0.9;
  }

  & + & {
    margin-left: 26px;
  }
`
