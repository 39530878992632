import qs from 'qs'
import { useEffect, useState } from 'react'
import logo from '../../assets/logo-home.png'
import { useLocation } from 'react-router-dom'
import * as S from './styles'
import {
  getContractPublic,
  createSubscriptionGateway
} from '../../services/publicApis'
import DefaultButton from '../../components/DefaultButton'
import Toast from '../../components/Toast'

const Contract = () => {
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [html, setHtml] = useState('')
  const [loadingContract, setLoadingContract] = useState(true)
  const [error, setError] = useState(false)
  const location = useLocation()

  const { subscriptionId } = qs.parse(location.search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    getContract()
  }, [])

  function getContract() {
    getContractPublic(String(subscriptionId))
      .then((res) => {
        setHtml(res.data)
        setLoadingContract(false)
        setError(false)
      })
      .catch((error) => {
        console.error(error)
        setLoadingContract(false)
        setError(true)
        Toast.showError(
          'Não estamos conseguindo carregar o contrato no momento. Por favor, tente novamente em instantes.'
        )
      })
  }

  async function handleSubmit() {
    try {
      if (!checked) {
        Toast.showError('É necessário Ler e Aceitar o Contrato pra prosseguir!')
        return
      }
      setLoading(true)
      const { data } = await createSubscriptionGateway(String(subscriptionId))
      window.location.href = `${data.paymentUrl}`
    } catch (error) {
      const err = error as any
      Toast.showError(err.response.data.error.description)
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.ContainerPage>
      <S.NavMenu>
        <img src={logo} alt="Logo" />
      </S.NavMenu>
      <S.Body>
        <S.BodyContainer>
          <S.Title>
            Termos e condições do Contrato de Serviço do Cartão Aliança
          </S.Title>
          <S.ContainerContract>
            {loadingContract ? (
              <S.LoadingContract>Carregando contrato...</S.LoadingContract>
            ) : (
              <S.ContractHTML>
                {error ? (
                  <div>
                    <S.MsgError>
                      Não estamos conseguindo carregar o contrato no momento.
                    </S.MsgError>
                    <S.MsgError>
                      Por favor, tente novamente em instantes.
                    </S.MsgError>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                )}
              </S.ContractHTML>
            )}
          </S.ContainerContract>
          <S.CheckContractContainer onClick={() => setChecked(!checked)}>
            <S.Check checked={checked} />
            <p>
              Li e aceito os termos e condições do contrato de Serviços do
              Cartão Aliança
            </p>
          </S.CheckContractContainer>
          <S.ButtonsContainer>
            <DefaultButton
              label="Aceitar e ir para pagamento"
              loading={loading}
              onClick={handleSubmit}
            />
          </S.ButtonsContainer>
        </S.BodyContainer>
      </S.Body>
    </S.ContainerPage>
  )
}

export default Contract
