import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import TextInput from '../../../components/TextInput'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { Masks } from '../../../common/masks'
import { getAddressByCep } from '../../../services/cep'
import DefaultButton from '../../../components/DefaultButton'
import { validateBr } from 'js-brasil'
import {
  isEmpty,
  limitDateDependent,
  validateCep
} from '../../../common/validators'
import Select, { IOption } from '../../../components/Select'
import { getGenders, newDependent } from '../../../services/accountsApi'
import { formatToUSDate } from '../../../common/utils'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../../../components/Card'
import { Dependent } from '../../../models/Dependent'
import Toast from '../../../components/Toast'
import { emissionOrgan } from '../../../common/emissionOrgan'
import { uf } from '../../../common/uf'

type ValueField = {
  value: string
  isInvalid?: boolean
}

const NewDependent = () => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<ValueField>({ value: '' })
  const [socialName, setSocialName] = useState<ValueField>({ value: '' })
  const [cpf, setCpf] = useState<ValueField>({ value: '' })
  const [rg, setRg] = useState<ValueField>({ value: '' })
  const [rgEmitted, setRgEmitted] = useState<ValueField>({ value: '' })
  const [issuingState, setIssuingState] = useState<ValueField>({ value: '' })
  const [email, setEmail] = useState<ValueField>({ value: '' })
  const [phone, setPhone] = useState<ValueField>({ value: '' })
  const [birthDate, setBirthDate] = useState<ValueField>({ value: '' })
  const [biologicalSex, setBiologicalSex] = useState<ValueField>({ value: '' })
  const [sex, setSex] = useState<ValueField>({ value: '' })
  const [zipCode, setZipCode] = useState<ValueField>({ value: '' })
  const [street, setStreet] = useState<ValueField>({ value: '' })
  const [number, setNumber] = useState<ValueField>({ value: '' })
  const [complement, setComplement] = useState<ValueField>({ value: '' })
  const [state, setState] = useState<ValueField>({ value: '' })
  const [city, setCity] = useState<ValueField>({ value: '' })
  const [doc, setDoc] = useState<ValueField>({ value: 'CPF' })
  const [kinshipDesc, setKinshipDesc] = useState<ValueField>({ value: '' })
  const [kinship, setKinship] = useState<ValueField>({ value: '' })
  const [district, setDistrict] = useState<ValueField>({ value: '' })
  const [genders, setGenders] = useState<IOption[]>([])
  const navigate = useNavigate()

  const biologicalSexes: IOption[] = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' }
  ]

  const docType: IOption[] = [
    { value: 'CPF', label: 'CPF' },
    { value: 'RG', label: 'RG' }
  ]

  const kinshipType: IOption[] = [
    { value: 'grandparents', label: 'Avô/Avó' },
    { value: 'parents', label: 'Pai/Mãe' },
    { value: 'spouse', label: 'Cônjuge' },
    { value: 'children', label: 'Filho(a)' },
    { value: 'uncle', label: 'Tio/Tia' },
    { value: 'outros', label: 'Outros' }
  ]

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Cadastro do Dependente' }
  ]

  const { id: subscriberId } = useParams()

  useEffect(() => {
    searchZipCode()
  }, [zipCode])

  useEffect(() => {
    getGenders().then((response) => {
      if (response && response.data) {
        const options: IOption[] = response.data.map((gender) => ({
          value: gender.code,
          label: gender.description
        }))
        setGenders(options)
      }
    })
  }, [])

  function searchZipCode() {
    const zipCodeNumbers = Masks.onlyNumbers(zipCode.value)
    if (zipCodeNumbers.length === 8) {
      getAddressByCep(zipCodeNumbers)
        .then((response) => {
          if (response && response.data) {
            const address = response.data
            setCity({ value: address.localidade })
            setStreet({ value: address.logradouro })
            setDistrict({ value: address.bairro })
            setState({ value: address.uf })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  function validateFields(): boolean {
    let isValid = true

    if (isEmpty(name.value)) {
      setName({ ...name, isInvalid: true })
      isValid = false
    }

    if (!validateBr.cpf(cpf.value) && doc.value === 'CPF') {
      setCpf({ ...cpf, isInvalid: true })
      isValid = false
    }

    if (isEmpty(rg.value) && doc.value === 'RG') {
      setRg({ ...rg, isInvalid: true })
      isValid = false
    }

    if (isEmpty(rgEmitted.value) && doc.value === 'RG') {
      setRgEmitted({ ...rgEmitted, isInvalid: true })
      isValid = false
    }

    if (isEmpty(email.value)) {
      setEmail({ ...email, isInvalid: true })
      isValid = false
    }

    if (isEmpty(biologicalSex.value)) {
      setBiologicalSex({ ...biologicalSex, isInvalid: true })
      isValid = false
    }

    if (!validateCep(zipCode.value)) {
      setZipCode({ ...zipCode, isInvalid: true })
      isValid = false
    }

    if (isEmpty(street.value)) {
      setStreet({ ...street, isInvalid: true })
      isValid = false
    }

    if (isEmpty(state.value)) {
      setState({ ...state, isInvalid: true })
      isValid = false
    }

    if (isEmpty(city.value)) {
      setCity({ ...city, isInvalid: true })
      isValid = false
    }

    if (isEmpty(district.value)) {
      setDistrict({ ...district, isInvalid: true })
      isValid = false
    }

    if (isEmpty(birthDate.value) || !limitDateDependent(birthDate.value)) {
      setBirthDate({ ...birthDate, isInvalid: true })
      isValid = false
    }

    return isValid
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!validateFields()) return

    try {
      setLoading(true)
      const data: Omit<Dependent, 'active' | 'subscriber'> = {
        name: name.value,
        social_name: socialName.value,
        cpf: Masks.onlyNumbers(cpf.value),
        email: email.value,
        phone: Masks.onlyNumbers(phone.value),
        birth: formatToUSDate(birthDate.value),
        birth_sex: biologicalSex.value,
        gender: sex.value,
        document_type: doc.value,
        issuing_agency: rgEmitted.value,
        issuing_state: issuingState.value,
        kinship: kinship.value,
        kinship_description: kinshipDesc.value,
        rg: rg.value,
        //@ts-ignore
        subscriber_id: String(subscriberId),
        address: {
          address_description: street.value,
          address_number: number.value,
          complement: complement.value,
          district: district.value,
          city: city.value,
          state: state.value,
          zip_code: zipCode.value
        }
      }
      const response = await newDependent(data)
      Toast.showSuccess('Dependente criado com sucesso')
      goBack()
    } catch (error) {
      setLoading(false)
      const err = error as any
      Toast.showError(err.response.data.message)
    }
  }

  function goBack() {
    navigate(`/dashboard/clientes/${subscriberId}`)
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Cadastro de Dependente'} />
        </S.ContainerTitle>
        <S.Form onSubmit={handleSubmit}>
          <Card>
            <S.Row>
              <S.Col>
                <S.Label>Nome Completo *</S.Label>
                <TextInput
                  maxLength={50}
                  value={name.value}
                  onChange={(value) =>
                    setName({ value: Masks.inputName(value).toUpperCase() })
                  }
                  messageError={
                    name.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Nome Social</S.Label>
                <TextInput
                  maxLength={50}
                  value={socialName.value}
                  onChange={(value) =>
                    setSocialName({
                      value: Masks.inputName(value).toUpperCase()
                    })
                  }
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Tipo de Documento *</S.Label>
                <Select
                  title="Escolha um Doc."
                  options={docType}
                  value={doc.value}
                  onChange={(event) => setDoc({ value: event.target.value })}
                  messageError={
                    doc.isInvalid ? 'Campo deve ser selecionado' : ''
                  }
                />
              </S.Col>
              {doc.value === 'CPF' ? (
                <>
                  <S.Col>
                    <S.Label>CPF *</S.Label>
                    <TextInput
                      maxLength={50}
                      value={cpf.value}
                      onChange={(value) => setCpf({ value: Masks.cpf(value) })}
                      messageError={
                        cpf.isInvalid ? 'Campo vazio ou inválido' : ''
                      }
                    />
                  </S.Col>
                  <S.Col />
                </>
              ) : (
                <>
                  <S.Col2>
                    <S.Label>RG *</S.Label>
                    <TextInput
                      maxLength={50}
                      value={rg.value}
                      onChange={(value) => setRg({ value: Masks.rg(value) })}
                      messageError={
                        rg.isInvalid ? 'Campo vazio ou inválido' : ''
                      }
                    />
                  </S.Col2>
                  <S.Col>
                    <S.Label>Emissor *</S.Label>
                    <Select
                      title="Escolha um Emissor"
                      options={emissionOrgan}
                      value={rgEmitted.value}
                      onChange={(event) =>
                        setRgEmitted({ value: event.target.value })
                      }
                      messageError={
                        rgEmitted.isInvalid ? 'Campo deve ser selecionado' : ''
                      }
                    />
                  </S.Col>
                  <S.Col2>
                    <S.Label>Estado *</S.Label>
                    <Select
                      title="Escolha um Estado"
                      options={uf}
                      value={issuingState.value}
                      onChange={(event) =>
                        setIssuingState({ value: event.target.value })
                      }
                      messageError={
                        issuingState.isInvalid
                          ? 'Campo deve ser selecionado'
                          : ''
                      }
                    />
                  </S.Col2>
                </>
              )}
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Data de Nascimento *</S.Label>
                <TextInput
                  placeholder="DD/MM/YYYY"
                  value={birthDate.value}
                  onChange={(value) =>
                    setBirthDate({ value: Masks.dateDDMMYYYY(value) })
                  }
                  messageError={
                    birthDate.isInvalid
                      ? 'Campo vazio ou limite de data inválido'
                      : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>E-mail *</S.Label>
                <TextInput
                  maxLength={50}
                  value={email.value}
                  onChange={(value) => setEmail({ value })}
                  messageError={
                    email.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Celular</S.Label>
                <TextInput
                  value={phone.value}
                  onChange={(value) => setPhone({ value: Masks.phone(value) })}
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Sexo Biológico *</S.Label>
                <Select
                  title="Escolha um Sexo"
                  options={biologicalSexes}
                  value={biologicalSex.value}
                  onChange={(event) =>
                    setBiologicalSex({ value: event.target.value })
                  }
                  messageError={
                    biologicalSex.isInvalid ? 'Campo deve ser selecionado' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Gênero</S.Label>
                <Select
                  title="Escolha um Gênero"
                  options={genders}
                  value={sex.value}
                  onChange={(event) => setSex({ value: event.target.value })}
                />
              </S.Col>
              <S.Col></S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Parentesco</S.Label>
                <Select
                  title="Grau de Parentesco"
                  options={kinshipType}
                  value={kinship.value}
                  onChange={(event) =>
                    setKinship({ value: event.target.value })
                  }
                />
              </S.Col>

              {kinship.value === 'outros' ? (
                <S.Col>
                  <S.Label>Outros</S.Label>
                  <TextInput
                    value={kinshipDesc.value}
                    onChange={(value) => setKinshipDesc({ value })}
                  />
                </S.Col>
              ) : (
                <S.Col />
              )}

              <S.Col />
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>CEP *</S.Label>
                <TextInput
                  value={zipCode.value}
                  onChange={(value) => setZipCode({ value: Masks.cep(value) })}
                  onBlur={searchZipCode}
                  messageError={
                    zipCode.isInvalid ? 'Campo vazio ou inválido' : ''
                  }
                />
              </S.Col>
              <S.Col></S.Col>
              <S.Col></S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Logradouro *</S.Label>
                <TextInput
                  maxLength={50}
                  value={street.value}
                  onChange={(value) => setStreet({ value })}
                  messageError={
                    street.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Número</S.Label>
                <TextInput
                  maxLength={5}
                  value={number.value}
                  onChange={(value) => setNumber({ value })}
                />
              </S.Col>
              <S.Col>
                <S.Label>Complemento</S.Label>
                <TextInput
                  maxLength={50}
                  value={complement.value}
                  onChange={(value) => setComplement({ value })}
                />
              </S.Col>
            </S.Row>
            <S.Row>
              <S.Col>
                <S.Label>Estado *</S.Label>
                <TextInput
                  maxLength={50}
                  value={state.value}
                  onChange={(value) => setState({ value })}
                  messageError={
                    state.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Cidade *</S.Label>
                <TextInput
                  maxLength={50}
                  value={city.value}
                  onChange={(value) => setCity({ value })}
                  messageError={
                    city.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
              <S.Col>
                <S.Label>Bairro *</S.Label>
                <TextInput
                  maxLength={50}
                  value={district.value}
                  onChange={(value) => setDistrict({ value })}
                  messageError={
                    district.isInvalid ? 'Campo deve ser preenchido' : ''
                  }
                />
              </S.Col>
            </S.Row>
          </Card>
          <S.BoxButton>
            <DefaultButton label="Voltar" secondary onClick={goBack} />
            <DefaultButton label="Salvar" type="submit" loading={loading} />
          </S.BoxButton>
        </S.Form>
      </S.Container>
    </S.ContainerPage>
  )
}

export default NewDependent
