import { Navigate, Route, Routes } from 'react-router-dom'
import Menu from '../../components/Menu'
import Clients from '../../pages/Clients'
import ShowSubscriber from '../../pages/Subscriber/Show'
import ShowDependent from '../../pages/Dependent/Show'
import NewSalesChannel from '../../pages/SalesChannel/New'
import ListSalesChannel from '../../pages/SalesChannel/List'
import ShowSalesChannel from '../../pages/SalesChannel/Show'
import EditSubscriberAddress from '../../pages/Subscriber/Edit/AddressEdit'
import ListPlans from '../../pages/Plan/List'
import NewPlan from '../../pages/Plan/New'
import Home from '../../pages/Home'
import { isAuthenticated } from '../../routes/PrivateRouter'
import { Container, Body, Children } from './styles'
import StepNewSubscriber from '../../pages/Subscriber/New/Step1NewSubscriber'
import StepChoosePlan from '../../pages/Subscriber/New/Step2ChoosePlan'
import StepChoosePaymentMethod from '../../pages/Subscriber/New/Step3ChoosePaymentMethod'
import StepSuccessSubscription from '../../pages/Subscriber/New/Step4SuccessSubscription'
import ShowPlan from '../../pages/Plan/Show'
import UsersList from '../../pages/Users/List'
import NewUser from '../../pages/Users/New'
import ShowUser from '../../pages/Users/Show'
import MyProfile from '../../pages/MyProfile/Show'
import ChangePassword from '../../pages/MyProfile/ChangePassword'
import EditPersonalData from '../../pages/Subscriber/Edit/EditPersonalData'
import AccessProfileList from '../../pages/AccessProfile/List'
import AccessProfileShow from '../../pages/AccessProfile/Show'
import AccessProfileListNew from '../../pages/AccessProfile/New'
import ResourceList from '../../pages/Resource/List'
import ResourceShow from '../../pages/Resource/Show'
import ResourceNew from '../../pages/Resource/New'
import NewDependent from '../../pages/Dependent/New'
import EditDependentAddress from '../../pages/Dependent/Edit/AddressEdit'
import EditDependentPersonalData from '../../pages/Dependent/Edit/PersonalData'

const DrawerMenu = () => {
  return (
    <Container>
      <Body>
        <Menu />
        <Children>
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated() ? <Home /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/home"
              element={
                isAuthenticated() ? <Home /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/clientes"
              element={
                isAuthenticated() ? <Clients /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/canais-de-venda"
              element={
                isAuthenticated() ? (
                  <ListSalesChannel />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/planos"
              element={
                isAuthenticated() ? <ListPlans /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/planos/novo"
              element={
                isAuthenticated() ? <NewPlan /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/planos/:id"
              element={
                isAuthenticated() ? <ShowPlan /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/clientes/novo"
              element={
                isAuthenticated() ? (
                  <StepNewSubscriber />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:id/subscriptions"
              element={
                isAuthenticated() ? (
                  <StepChoosePlan />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:id/subscriptions/:subscription_id/edit"
              element={
                isAuthenticated() ? (
                  <StepChoosePlan />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:subscriber_id/subscriptions/:subscription_id/payment-method"
              element={
                isAuthenticated() ? (
                  <StepChoosePaymentMethod />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:subscriber_id/subscriptions/:subscription_id/success"
              element={
                isAuthenticated() ? (
                  <StepSuccessSubscription />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:id"
              element={
                isAuthenticated() ? (
                  <ShowSubscriber />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:id/dependentes/novo"
              element={
                isAuthenticated() ? (
                  <NewDependent />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:subscriber_id/dependentes/:dependent_id"
              element={
                isAuthenticated() ? (
                  <ShowDependent />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:subscriber_id/dependentes/:dependent_id/editar-endereco"
              element={
                isAuthenticated() ? (
                  <EditDependentAddress />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:subscriber_id/dependentes/:dependent_id/editar-dados-pessoais"
              element={
                isAuthenticated() ? (
                  <EditDependentPersonalData />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:id/editar-dados-pessoais"
              element={
                isAuthenticated() ? (
                  <EditPersonalData />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/clientes/:id/editar-endereco"
              element={
                isAuthenticated() ? (
                  <EditSubscriberAddress />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/canais-de-venda/novo"
              element={
                isAuthenticated() ? (
                  <NewSalesChannel />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/canais-de-venda/:id"
              element={
                isAuthenticated() ? (
                  <ShowSalesChannel />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/canais-de-venda/:id/edit"
              element={
                isAuthenticated() ? (
                  <ShowSalesChannel />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/usuarios"
              element={
                isAuthenticated() ? <UsersList /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/usuarios/novo"
              element={
                isAuthenticated() ? <NewUser /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/usuarios/:id"
              element={
                isAuthenticated() ? <ShowUser /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/usuarios/:id/editar"
              element={
                isAuthenticated() ? <NewUser /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/meu-perfil"
              element={
                isAuthenticated() ? <MyProfile /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/perfis-de-acesso"
              element={
                isAuthenticated() ? (
                  <AccessProfileList />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/perfis-de-acesso/:id"
              element={
                isAuthenticated() ? (
                  <AccessProfileShow />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/perfis-de-acesso/novo"
              element={
                isAuthenticated() ? (
                  <AccessProfileListNew />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/perfis-de-acesso/:id/editar"
              element={
                isAuthenticated() ? (
                  <AccessProfileListNew />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/recursos"
              element={
                isAuthenticated() ? (
                  <ResourceList />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/recursos/:id"
              element={
                isAuthenticated() ? (
                  <ResourceShow />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/recursos/novo"
              element={
                isAuthenticated() ? (
                  <ResourceNew />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/recursos/:id/editar"
              element={
                isAuthenticated() ? (
                  <ResourceNew />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/meu-perfil/alterar-senha"
              element={
                isAuthenticated() ? (
                  <ChangePassword />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Routes>
        </Children>
      </Body>
    </Container>
  )
}

export default DrawerMenu
