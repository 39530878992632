import styled from 'styled-components'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const BoxButton = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 100%;
    max-width: 300px;
    margin-left: 8px;
  }
`

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;

  strong {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  p {
    margin-top: 16px;
  }

  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #9E3F87;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }

  div {
    max-width: 60%;
  }
`
