import { MdOutlineEdit, MdAdd } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { formatCPF } from '../../../../common/utils'
import Badge from '../../../../components/Badge'
import Toast from '../../../../components/Toast'
import { Dependent } from '../../../../models/Dependent'
import { ResourceType } from '../../../../models/ResourceType'
import { hasPermission } from '../../../../services/userData'
import { Table } from '../../../../styles/Table'
import * as S from '../styles'

type Props = {
  isLoading: boolean
  dependents?: Dependent[]
}

const DependentsSession = ({ isLoading, dependents = [] }: Props) => {
  const { id: subscriberId } = useParams()
  const navigate = useNavigate()

  function handleGoToNewDependent() {
    const dependentActive = dependents.filter((act) => act.active === true)
    console.log(dependentActive.length)
    if (dependentActive.length >= 4) {
      return Toast.showError(
        'Já atigiu o limite máximo de 4 dependentes ativos para este assinante'
      )
    }
    navigate(`/dashboard/clientes/${subscriberId}/dependentes/novo`)
  }

  function handleGoToShowDependent(dependentId?: string) {
    if (!dependentId) return
    navigate(
      `/dashboard/clientes/${subscriberId}/dependentes/${dependentId}?backToSubscriber=true`
    )
  }

  return (
    <S.ContainerCard>
      <S.HeaderCard>
        <S.HeaderTitle>Dependentes</S.HeaderTitle>
        <S.HeaderOptions>
          {hasPermission(ResourceType.CREATE_NEW_DEPENDENT) && (
            <S.HeaderOption>
              <MdAdd />
              <a onClick={handleGoToNewDependent}>
                <S.HeaderOptionLabel>Adicionar</S.HeaderOptionLabel>
              </a>
            </S.HeaderOption>
          )}
        </S.HeaderOptions>
      </S.HeaderCard>
      {isLoading && <S.LoadingArea>Carregando...</S.LoadingArea>}
      <S.BodyCard hidden={isLoading}>
        <Table enableHover>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>RG</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dependents.map((dep, index) => (
              <tr key={index}>
                <td>{dep.name}</td>
                <td>{dep.cpf ? formatCPF(dep.cpf) : '-'}</td>
                <td>{dep.rg || '-'}</td>
                <td>{dep.email}</td>
                <td>{dep.phone}</td>
                <td>
                  <>
                    {dep.active ? (
                      <Badge title="Ativo" type="success" />
                    ) : (
                      <Badge title="Inativo" type="error" />
                    )}
                  </>
                </td>
                <td>
                  <S.HeaderOption
                    onClick={() => handleGoToShowDependent(dep.id)}
                  >
                    <MdOutlineEdit size={25} />
                  </S.HeaderOption>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!isLoading && !dependents.length && (
          <S.LoadingArea>Nenhum dependente cadastrado</S.LoadingArea>
        )}
      </S.BodyCard>
    </S.ContainerCard>
  )
}

export default DependentsSession
