import styled from 'styled-components'
import Colors from '../../../common/colors'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`

export const ContainerCard = styled.form`
  background-color: ${Colors.white};
  box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
`

export const Row = styled.div`
  display: flex;
  padding: 8px;
`

export const Col = styled.div`
  width: 100%;
  padding: 8px;

  input {
    width: 100%;
  }
`

export const Label = styled.strong`
  margin-top: 10px;
`

export const Value = styled.div`
  margin-top: 10px;
`
export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
`

type IAction = {
  color?: 'error' | 'primary' | 'warning' | 'success'
}

export const Action = styled.div<IAction>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.primary};
  ${({ color }) => color === 'error' && `color: ${Colors.error};`}
  ${({ color }) => color === 'warning' && `color: ${Colors.warning};`}
  ${({ color }) => color === 'success' && `color: ${Colors.success};`}

  svg {
    margin-right: 4px;
  }

  & + & {
    margin-left: 16px;
  }

  &:hover {
    opacity: 0.7;
  }
`
