export type ColorsType = keyof typeof Colors

const Colors = {
  primary: '#9E3F87',
  softPrimary: '#CD77B8',
  white: '#FFFFFF',
  black: '#000000',
  neutralColor: '#363636',
  neutralColorLight: '#CDCDCD',
  clearWhite: '#eeeeef',
  success: '#23C46F',
  error: '#EA625E',
  warning: '#F39C12',
  basicGray: '#EEEEEE'
}

export default Colors
