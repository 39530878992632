import styled from 'styled-components'
import Colors from '../../../../../common/colors'

type ContainerModal = {
  hidden?: boolean
}

export const ContainerModal = styled.div<ContainerModal>`
  background: rgba(0, 0, 0, .4);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${({ hidden }) => hidden && `display: none;`}
`

export const Modal = styled.div`
  background: ${Colors.white};
  width: 100%;
  max-width: 500px;
  border-radius: 16px;
  padding: 32px;
`

export const ModalBoxForm = styled.div`
  padding-top: 16px;
`

export const ModalBoxActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  button {
    width: 100%;
    max-width: 200px;
  }
`

export const ModalBoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: ${Colors.primary};
    cursor: pointer;
  }
`

export const Label = styled.div`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 8px;
`
