import React, { useEffect, useState } from 'react'
import {
  IItemMenu as IIM,
  ItemMenuContainer,
  ItemMenuName,
  ContainerIcon,
  ContainerName
} from './styles'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

interface IItemMenu extends IIM {
  name: string
  icon?: React.ReactNode
  isLink?: boolean
  onClick?: () => void
  enableArrow?: boolean
  arrowOpen?: boolean
}

const ItemMenu: React.FC<IItemMenu> = ({
  icon: Icon,
  name,
  active,
  onClick,
  isLink,
  enableArrow,
  arrowOpen: arrowOp,
}) => {
  const [arrowOpened, setArrowOpened] = useState(false)

  useEffect(() => {
    setArrowOpened(!!arrowOp)
  }, [arrowOp])

  function getArrow() {
    if (!enableArrow) return '';

    if (arrowOpened) {
      return <MdKeyboardArrowUp size={25} />
    } else {
      return <MdKeyboardArrowDown size={25} />
    }
  }

  return (
    <ItemMenuContainer active={active} onClick={onClick} isLink={isLink}>
      <ContainerName>
        <ContainerIcon>{Icon}</ContainerIcon>
        <ItemMenuName>{name}</ItemMenuName>
      </ContainerName>
      {getArrow()}
    </ItemMenuContainer>
  )
}

export default ItemMenu
