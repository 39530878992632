import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Masks } from '../../common/masks'
import { isEmpty, isValidCpf } from '../../common/validators'
import DefaultButton from '../../components/DefaultButton'
import TextInput from '../../components/TextInput'
import { authenticate } from '../../services/publicApis'
import { saveTokenData, saveUserData } from '../../services/userData'
import * as S from './styles'

const Login: React.FC = () => {
  const [cpf, setCpf] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(event: any) {
    try {
      event.preventDefault()
      setErrorMessage('')

      const cpfCleaned = Masks.onlyNumbers(cpf.trim())
      const passwordCleaned = password.trim()
      if (isEmpty(cpfCleaned) || isEmpty(passwordCleaned.trim())) {
        setErrorMessage('Preencha todos os campos')
        return
      }

      if (!isValidCpf(cpfCleaned)) {
        setErrorMessage('CPF inválido')
        return
      }

      setLoading(true)
      const data: any = await authenticate(cpfCleaned, passwordCleaned)
      saveUserData(data.accessToken)
      saveTokenData(data)
      setLoading(false)
      navigate('/dashboard/home')
    } catch (error: any) {
      setLoading(false)
      const data = error.response.data
      if (data.type === 'USER_IS_INACTIVE') {
        setErrorMessage(
          'Usuário inativado, contate o suporte para mais informações!'
        )
      } else {
        setErrorMessage('Usuário ou senha inválidos!')
      }
    }
  }

  return (
    <S.Container>
      <S.FormContainer onSubmit={handleSubmit}>
        <S.Title>Login</S.Title>
        <TextInput
          placeholder="CPF"
          onChange={(value) => setCpf(Masks.cpf(value))}
          value={cpf}
        />
        <TextInput
          placeholder="Senha"
          type="password"
          onChange={setPassword}
          value={password}
        />
        {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        <S.BoxButton>
          <DefaultButton label="Acessar" type="submit" loading={loading} />
          <Link to="/recovery">Esqueci minha senha</Link>
        </S.BoxButton>
      </S.FormContainer>
    </S.Container>
  )
}

export default Login
