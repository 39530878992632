export enum ResourceType {
  CREATE_NEW_SUBSCRIBER = 'CREATE_NEW_SUBSCRIBER',
  EDIT_SUBSCRIBER_ADDRESS = 'EDIT_SUBSCRIBER_ADDRESS',
  EDIT_SUBSCRIBER_PERSONAL_DATA = 'EDIT_SUBSCRIBER_PERSONAL_DATA',
  SHOW_CLIENTS = 'SHOW_CLIENTS',
  CREATE_NEW_DEPENDENT = 'CREATE_NEW_DEPENDENT',
  EDIT_DEPENDENT = 'EDIT_DEPENDENT',
  SHOW_BACKOFFICE_USERS = 'SHOW_BACKOFFICE_USERS',
  SHOW_SALES_CHANNEL = 'SHOW_SALES_CHANNEL',
  SHOW_PLAN = 'SHOW_PLAN',
  SHOW_ACCESS_PROFILE = 'SHOW_ACCESS_PROFILE',
  SHOW_RESOURCE = 'SHOW_RESOURCE',
  EXPORT_LGPD_DATA = 'EXPORT_LGPD_DATA',
  NEW_BACKOFFICE_USER = 'NEW_BACKOFFICE_USER',
  ACTIVE_INACTIVE_BACKOFFICE_USER = 'ACTIVE_INACTIVE_BACKOFFICE_USER',
  RESET_PASSWORD_BACKOFFICE_USER = 'RESET_PASSWORD_BACKOFFICE_USER',
  EDIT_BACKOFFICE_USER = 'EDIT_BACKOFFICE_USER',
  CREATE_SALES_CHANNEL = 'CREATE_SALES_CHANNEL',
  CREATE_PLAN = 'CREATE_PLAN',
  ACTIVE_INACTIVE_PLAN = 'ACTIVE_INACTIVE_PLAN',
  CREATE_ACCESS_PROFILE = 'CREATE_ACCESS_PROFILE',
  EDIT_ACCESS_PROFILE = 'EDIT_ACCESS_PROFILE',
  CREATE_RESOURCE = 'CREATE_RESOURCE',
  EDIT_RESOURCE = 'EDIT_RESOURCE',
  RESEND_SUBSCRIPTION_LINK = 'RESEND_SUBSCRIPTION_LINK',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION',

  EDIT_SALES_CHANNEL = 'EDIT_SALES_CHANNEL',
  ACTIVE_INACTIVE_SALES_CHANNEL = 'ACTIVE_INACTIVE_SALES_CHANNEL',
  ACTIVE_INACTIVE_DEPENDENT = 'ACTIVE_INACTIVE_DEPENDENT'
}
