import axios, { AxiosInstance } from 'axios'
import { requestBase, responseError } from './baseInterceptors'

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_PLANS
})

api.interceptors.request.use(requestBase, (error) => Promise.reject(error))
api.interceptors.response.use((response) => response, responseError)

export default api
