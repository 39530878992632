import { useEffect, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import DefaultButton from '../../../../../components/DefaultButton'
import TextArea from '../../../../../components/TextArea'
import Title from '../../../../../components/Title'
import Toast from '../../../../../components/Toast'
import { cancelSubscription } from '../../../../../services/plansApi'
import * as S from './styles'

type ICancelModal = {
  showModal: boolean
  onClose?: () => void
  onConfirmed?: () => void
  subscriptionId?: string
}

const CancelModal = ({ showModal: sm, onClose, onConfirmed, subscriptionId }: ICancelModal) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    setShowModal(sm)
    setDescription('')
    setMessageError('')
  }, [sm])

  function handleClickOutsideModal(event: any) {
    if (event.target.id === 'outsideModal') {
      handleCloseModal()
    }
  }

  function handleCloseModal() {
    if (onClose) {
      onClose()
    }
  }

  async function handleConfirm() {
    setMessageError('')

    if (!subscriptionId) return
    if (!description) {
      return setMessageError('Motivo do cancelamento é obrigatório.')
    }

    try {
      setLoading(true)
      await cancelSubscription(subscriptionId, description)
      setLoading(false)
      Toast.showSuccess('Assinatura cancelada com sucesso!')
      onConfirmed && onConfirmed()
    } catch (error) {
      setLoading(false)
      setMessageError('Não foi possível realizar o cancelamento.')
    }
  }

  return (
    <S.ContainerModal id='outsideModal' onClick={handleClickOutsideModal} hidden={!showModal}>
      <S.Modal id='insideModal'>
        <S.ModalBoxTitle>
          <Title title={'Cancelar assinatura'} />
          <MdOutlineClose size={24} onClick={handleCloseModal} />
        </S.ModalBoxTitle>
        <S.ModalBoxForm>
          <S.Label>Qual o motivo do cancelamento?</S.Label>
          <TextArea value={description} onChange={setDescription} messageError={messageError} />
          <S.ModalBoxActions>
            <DefaultButton label="Cancelar assinatura" type="button" onClick={handleConfirm} loading={loading} />
          </S.ModalBoxActions>
        </S.ModalBoxForm>
      </S.Modal>
    </S.ContainerModal>
  )
}

export default CancelModal
