import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultButton from '../../../components/DefaultButton'
import { SearchInput } from '../../../components/SearchInput'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import { Title } from '../../../components/Title'
import * as S from './styles'
import TableArea from './TableArea'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

const ListSalesChannel = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Canais de Venda', path: '/dashboard/canais-de-venda' }
  ]

  function getContainerTable() {
    return <TableArea searchSales={search} />
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Canais de Venda'} />
          {hasPermission(ResourceType.CREATE_SALES_CHANNEL) && (
            <DefaultButton
              label="Novo Canal de Venda"
              type="button"
              loading={false}
              onClick={() => navigate(`/dashboard/canais-de-venda/novo`)}
            />
          )}
        </S.ContainerTitle>

        <SearchInput
          placeholder="Busque por CNPJ, razão ou nome fantasia"
          onChange={setSearch}
          value={search}
        />
        <S.ContainerTable>{getContainerTable()}</S.ContainerTable>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ListSalesChannel
