import styled from 'styled-components'
import Colors from '../../../common/colors'

type IHeaderOption = {
  primary?: boolean
}

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`

export const ContainerCard = styled.div`
  background-color: ${Colors.white};
  box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
`

export const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`

export const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: ${Colors.neutralColor};
`

type HeaderOptionsProps = {
  hideOptions?: boolean
}

export const HeaderOptions = styled.div<HeaderOptionsProps>`
  display: flex;
  ${({ hideOptions }) => hideOptions && `display: none;`}
`

export const HeaderOption = styled.div<IHeaderOption>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.black};
  ${({ primary }) => !!primary && `color: ${Colors.primary};`}
  ${({ primary }) => !!primary && `text-decoration: underline;`}

  .loading {
    animation: rotation 1s infinite linear;
  }


  &:hover {
    color: ${Colors.primary};
  }

  & + & {
    margin-left: 16px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const HeaderOptionLabel = styled.p`
  font-weight: 450;
  margin-left: 8px;

  &:hover {
    text-decoration: underline;
  }
`

type IBodyCard = {
  hidden?: boolean
}

export const BodyCard = styled.div<IBodyCard>`
  ${({ hidden }) => !!hidden && `display: none;`}
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
`

export const Col = styled.div`
  width: 100%;
  max-width: 180px;
`

export const Label = styled.div`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 8px;
`

export const Description = styled.div`
  word-break: break-word;
`

export const LoadingArea = styled.p`
  margin-top: 16px;
  margin-left: 8px;
`

export const ContainerButton = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: end;

  button {
    width: 100%;
    max-width: 250px;
  }
`

type IContainerHistory = {
  marginHorizontal?: 'left' | 'right'
}

export const ContainerHistory = styled.div<IContainerHistory>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    ${({ marginHorizontal }) =>
      !!marginHorizontal && `margin-${marginHorizontal}: 20px;`}
    margin-bottom: 16px;
  }

  .description {
    width: 100%;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 8px;
    box-sizing: border-box;
  }

  .linkDownload {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8px;
    align-items: center;
    cursor: pointer;
    color: ${Colors.primary};
    text-decoration: underline;

    svg {
      width: auto;
      margin-left: 8px;
    }
  }
`

export const SeparatorLine = styled.hr`
  background-color: ${Colors.neutralColorLight};
  height: 1px;
  border: none;
  margin: 20px 16px 10px;
`

export const ContainerListHistory = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 0 0 20px 30px;
  box-sizing: border-box;
  margin: 0 auto;

  :before,
  :after {
    content: '';
    position: absolute;
    background-color: ${Colors.softPrimary};
  }
  :before {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    top: 8px;
    left: 0;
  }
  :after {
    width: 1px;
    height: 100%;
    left: 3px;
    top: 1rem;
  }

  &:last-child:after {
    display: none;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .changes {
    font-size: 16px;
    list-style: disc;
    display: list-item;
    margin-left: 20px;
  }
`
