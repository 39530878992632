import styled from 'styled-components'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const ContainerTable = styled.div`
  margin-top: 32px;

  table {
    padding: 16px;
  }
`

export const EmptyArea = styled.p`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`
