import * as S from './styles'

type Props = {
  children?: JSX.Element | JSX.Element[]
}

const Card = ({ children }: Props) => (
  <S.Container>{children}</S.Container>
)

export default Card
