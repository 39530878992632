import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { translatePaymentMethod } from '../../../../common/utils'
import Breadcrumb, { ISections } from '../../../../components/Breadcrumb'
import Card from '../../../../components/Card'
import DefaultButton from '../../../../components/DefaultButton'
import Select, { IOption } from '../../../../components/Select'
import TextInput from '../../../../components/TextInput'
import Title from '../../../../components/Title'
import Toast from '../../../../components/Toast'
import { CompleteSubscriptionRequest } from '../../../../models/CompleteSubscriptionRequest'
import { PaymentMethodType } from '../../../../models/PaymentMethodType'
import {
  completeSubscription,
  getSalesChannel
} from '../../../../services/plansApi'
import { getUserData } from '../../../../services/userData'
import * as S from './styles'

const StepChoosePaymentMethod = () => {
  const navigate = useNavigate()
  const userData = getUserData()
  const { subscriber_id, subscription_id } = useParams()
  const [holderName, setHolderName] = useState('')
  const [machineCode, setMachineCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [paymentKind, setPaymentKind] = useState<PaymentMethodType | ''>('')
  const [paymentMethods, setPaymentMethods] = useState<IOption[]>([])
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Cadastro do Titular (passo 3 de 3)' }
  ]

  useEffect(() => {
    async function init() {
      await loadSalesChannel()
    }
    init()
  }, [])

  async function loadSalesChannel() {
    try {
      if (!userData?.salesChannelId) return
      const response = await getSalesChannel(userData.salesChannelId)
      const { data } = response
      if (data && data.payment_type) {
        const paymentTypes = data.payment_type as []
        const paymentTypesNew = paymentTypes.map((paymentType) => ({
          label: translatePaymentMethod(paymentType),
          value: paymentType
        }))
        setPaymentMethods(paymentTypesNew)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function handleGoToShowSubscriber() {
    navigate(`/dashboard/clientes/${subscriber_id}`)
  }

  async function handleConfirmPayment() {
    if (!subscription_id) {
      return Toast.showError('Assinatura não encontrado')
    }

    if (!subscriber_id) {
      return Toast.showError('Assinante não encontrado')
    }

    if (!paymentKind) {
      return Toast.showError(
        'Por favor, selecione um Tipo de Pagamento para continuar'
      )
    }

    if (['credit_card', 'debit_card'].includes(paymentKind)) {
      if (!holderName) {
        return Toast.showError(
          'Por favor, preencha o Nome do Titular do cartão para continuar'
        )
      }

      if (!machineCode) {
        return Toast.showError(
          'Por favor, preencha o Código de Pagamento da maquininha para continuar'
        )
      }
    }

    try {
      setLoading(true)
      const data: CompleteSubscriptionRequest = {
        payment_kind: paymentKind,
        holder_name: holderName,
        machine_code: machineCode
      }

      if (data.payment_kind === '') {
        data.holder_name = ''
        data.machine_code = ''
      }

      await completeSubscription(subscription_id, data)
      setLoading(false)
      navigate(
        `/dashboard/clientes/${subscriber_id}/subscriptions/${subscription_id}/success`
      )
    } catch (error) {
      setLoading(false)
    }
  }

  function getMoreInfos() {
    if (!['credit_card', 'debit_card'].includes(paymentKind || '')) return

    return (
      <>
        <S.ContainerDescription>
          <p>Confirmação de pagamento</p>
          <p>
            Para concluir o pagamento coloque no campo abaixo o código de
            autorização enviado pela maquininha e o nome do titular.
          </p>
        </S.ContainerDescription>
        <S.Row>
          <S.Col>
            <S.Label>Nome do titular *</S.Label>
            <TextInput
              maxLength={50}
              value={holderName}
              onChange={setHolderName}
            />
          </S.Col>
          <S.Col>
            <S.Label>Código de pagamento *</S.Label>
            <TextInput
              maxLength={50}
              value={machineCode}
              onChange={setMachineCode}
            />
          </S.Col>
          <S.Col />
        </S.Row>
      </>
    )
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title="Método de pagamento" />
        </S.ContainerTitle>
        <Card>
          <S.Form onSubmit={() => {}}>
            <p>Escolha o método de pagamento</p>
            <span>Os campos com (*) são obrigatórios</span>
            <S.Row>
              <S.Col>
                <S.Label>Tipo de Pagamento *</S.Label>
                <Select
                  title="Escolha um Tipo de Pagamento"
                  options={paymentMethods}
                  value={paymentKind}
                  onChange={(event) => {
                    setPaymentKind(event.target.value)
                  }}
                />
              </S.Col>
              <S.Col />
              <S.Col />
            </S.Row>
            {getMoreInfos()}
          </S.Form>
        </Card>
        <S.BoxButton>
          <DefaultButton
            label="Pagamento não confirmado"
            type="button"
            loading={false}
            secondary
            onClick={handleGoToShowSubscriber}
          />
          <S.BoxErrorButton>
            <DefaultButton
              label="Confirmar pagamento"
              type="button"
              loading={loading}
              onClick={handleConfirmPayment}
            />
          </S.BoxErrorButton>
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default StepChoosePaymentMethod
