import axios, { AxiosInstance } from 'axios'

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_PLANS
})

export async function getContractPublic(subscriptionId: string): Promise<any> {
  return await api.get(`/public/subscriptions/${subscriptionId}/contract`, {
    responseType: 'text'
  })
}

export async function createSubscriptionGateway(
  subscriptionId: string
): Promise<any> {
  return await api.post(
    `/public/subscriptions/${subscriptionId}/create-subscription-gateway`
  )
}

export async function authenticate(
  username: string,
  password: string
): Promise<Response> {
  const url = `${process.env.REACT_APP_BASE_API_AUTH}/auth/users`
  const response = await axios.post(url, { username, password })
  return response.data
}

export async function recoverPassword(cpf: string): Promise<Response> {
  const url = `${process.env.REACT_APP_BASE_API_AUTH}/users/recovery-password`
  const response = await axios.post(url, { cpf })
  return response.data
}
