import styled from 'styled-components'
import Colors from '../../common/colors'

export const Container = styled.div``

export const ContainerInput = styled.div`
  height: 68px;
  background-color: #fff;
  box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 24px;
  gap: 12px;
`

export const Input = styled.input`
  font-size: 18px;
  font-weight: 400;
  margin: 0 15px;
  color: rgba(54, 54, 54, 1);
  height: 28px;
  border: none;
  width: 100%;
`

export const ErrorMessage = styled.span`
  color: ${Colors.error};
  font-size: 12px;
`
