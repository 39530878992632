import { useEffect, useState } from 'react'
import { BsCheckSquare, BsCheckSquareFill } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import Colors from '../../../../common/colors'
import { dateFileName, handleViewBlob } from '../../../../common/utils'
import Breadcrumb, { ISections } from '../../../../components/Breadcrumb'
import Card from '../../../../components/Card'
import DefaultButton from '../../../../components/DefaultButton'
import Title from '../../../../components/Title'
import {
  getReceiptPayment,
  getSubscriptionById
} from '../../../../services/plansApi'
import * as S from './styles'

const StepSuccessSubscription = () => {
  const { subscriber_id, subscription_id } = useParams()
  const navigate = useNavigate()
  const sections: ISections[] = [
    { description: 'Home', path: '/dashboard/home' },
    { description: 'Clientes', path: '/dashboard/clientes' },
    { description: 'Cadastro do Titular' },
    { description: 'Pagamento confirmado' }
  ]
  const [loading, setLoading] = useState(false)
  const [subscriptionByLink, setSubscriptionByLink] = useState<
    boolean | undefined
  >(undefined)

  useEffect(() => {
    async function init() {
      await loadSubscription()
    }
    init()
  }, [])

  function handleGoToShowSubscriber() {
    navigate(`/dashboard/clientes/${subscriber_id}`)
  }

  function handleGoToNewDependent() {
    navigate(`/dashboard/clientes/${subscriber_id}/dependentes/novo`)
  }

  async function handleDownloadReceipt() {
    setLoading(true)
    await getReceiptPayment(String(subscription_id))
      .then((res) => {
        const blob = res.data
        const fileName = dateFileName()
        handleViewBlob(blob, `receipt_payment_${fileName}.pdf`)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  async function loadSubscription() {
    try {
      if (!subscription_id) return
      const response = await getSubscriptionById(subscription_id)
      const { data } = response
      setSubscriptionByLink(
        data && data.payment_kind === 'link' && data.status === 'pending'
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          {subscriptionByLink === true && (
            <Title title="Aguardando pagamento" />
          )}
          {subscriptionByLink === false && (
            <Title title="Pagamento confirmado" />
          )}
        </S.ContainerTitle>
        <Card>
          <S.CardBody>
            <div>
              <strong>
                {subscriptionByLink === true && (
                  <BsCheckSquareFill color={Colors.warning} />
                )}
                {subscriptionByLink === false && (
                  <BsCheckSquareFill color={Colors.success} />
                )}
                Assinatura concluída com sucesso!
              </strong>
              {subscriptionByLink === true && (
                <p>Aguardando confirmação de pagamento.</p>
              )}
              {subscriptionByLink === false && (
                <p>
                  Agora, você deve imprimir as duas vias do recibo - sendo uma
                  delas para o cliente. Após esse processo, é possível cadastrar
                  dependentes ou voltar para a página de clientes.
                </p>
              )}
            </div>
            <a onClick={handleGoToNewDependent}>
              <BsCheckSquare />
              Cadastrar dependentes
            </a>
          </S.CardBody>
        </Card>
        <S.BoxButton>
          <DefaultButton
            label="Voltar para página de assinantes"
            type="button"
            onClick={handleGoToShowSubscriber}
            secondary
          />

          {subscriptionByLink === false && (
            <DefaultButton
              label="Imprimir recibo"
              type="button"
              loading={loading}
              onClick={handleDownloadReceipt}
            />
          )}
        </S.BoxButton>
      </S.Container>
    </S.ContainerPage>
  )
}

export default StepSuccessSubscription
