import styled from 'styled-components'
import Colors from '../common/colors'

export type ITable = {
  enableShadow?: boolean
  enableHover?: boolean
}

export const Table = styled.table<ITable>`
  width: 100%;
  background-color: ${Colors.white};
  border-radius: 8px;
  border-spacing: 0;
  ${({ enableShadow }) =>
    enableShadow && `box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);`}

  thead tr th {
    text-align: start;
    padding: 8px;
  }

  tbody tr {
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      ${({ enableHover }) =>
        enableHover && `background-color: ${Colors.clearWhite};`}
    }
  }

  tbody tr td {
    padding: 8px;
  }
`
