import styled from 'styled-components'
import Colors from '../../../../../common/colors'

type PlanOptionType = {
  isActive?: boolean
}

export const Container = styled.div<PlanOptionType>`
  height: 100px;
  width: 100%;
  max-width: 360px;
  border-radius: 8px;
  cursor: pointer !important;
  margin-right: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  border: 1px solid ${Colors.basicGray};
  padding: 16px;
  text-align: center;
  ${({ isActive }) => isActive ? `border: 1px solid ${Colors.primary};` : `` }
  ${({ isActive }) => isActive ? `color: ${Colors.primary};` : `` }

  &:hover {
    ${({ isActive }) => !isActive ? `opacity: 0.8;` : `` }
  }
`
