import styled from 'styled-components'
import Colors from '../../common/colors'

export const Container = styled.div``

export const MessageError = styled.span`
  color: ${Colors.error};
  font-size: 13px;
`

export const Input = styled.textarea`
  width: 100%;
  max-width: 100%;
  padding: 8px;
  background-color: rgb(249, 249, 249);
  border: 1px solid rgb(188, 189, 189);
  box-sizing: border-box;
  border-radius: 4px;

  &:focus {
    border: 1px solid ${Colors.primary};
    box-shadow: inset 1px 2px 4px rgba(158, 63, 135, 0.01),
      0px 0px 8px rgba(158, 63, 135, 0.2);
  }

  &:disabled {
    border: 1px solid ${Colors.neutralColorLight};
    background-color: rgb(225, 225, 225);
  }
`
