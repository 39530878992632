import { useEffect, useState } from 'react'
import Breadcrumb, { ISections } from '../../../components/Breadcrumb'
import Title from '../../../components/Title'
import DefaultButton from '../../../components/DefaultButton'
import { getCategoryById, getPlansById, inactivePlan } from '../../../services/plansApi'
import { useNavigate, useParams } from 'react-router-dom'
import * as S from './styles'
import { MdClose } from 'react-icons/md'
import Badge from '../../../components/Badge'
import { hasPermission } from '../../../services/userData'
import { ResourceType } from '../../../models/ResourceType'

const sections: ISections[] = [
  { description: 'Home', path: '/dashboard/home' },
  { description: 'Planos', path: '/dashboard/planos' },
  { description: 'Dados do Plano' }
]

const ShowPlan = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [clientType, setClientType] = useState('')
  const [price, setPrice] = useState('')
  const [interval, setInterval] = useState('')
  const [typeSalesChannelName, setTypeSalesChannelName] = useState('')
  const [description, setDescription] = useState('')
  const [status, setStatus] = useState('')
  const [planId, setPlanId] = useState('')
  const [gatewayPlanId, setGatewayPlanId] = useState('')
  const [inactivating, setInactivating] = useState(false)

  useEffect(() => {
    getPlan()
  }, [])

  function goBack() {
    navigate(`/dashboard/planos`)
  }

  async function getPlan() {
    try {
      if (!id) return
      const response = await getPlansById(id)
      const { data } = response
      setPlanId(data.plan_id)
      setStatus(data.status)
      setName(data.name)
      setClientType(data.client_type)
      setInterval(data.interval_month)
      setPrice(data.price)
      setDescription(data.description)
      setGatewayPlanId(data.gateway_plan_id)
      const typeSalesChannelIds: any[] = data.type_saleschannel_plan
      if (typeSalesChannelIds && typeSalesChannelIds.length) {
        const typeId = typeSalesChannelIds[0].type_saleschannel_id
        const { data } = await getCategoryById(typeId)
        if (data) {
          setTypeSalesChannelName(data.name)
        }
      }
    } catch (error) {}
  }

  async function handleInactivePlan() {
    if (!planId) return
    if (inactivating) return

    try {
      setInactivating(true)
      await inactivePlan(planId)
      setTimeout(async () => {
        await getPlan()
        setInactivating(false)
      }, 600)
    } catch (error) {
      setTimeout(() => {
        setInactivating(false)
      }, 600)
    }
  }

  return (
    <S.ContainerPage>
      <S.Container>
        <Breadcrumb sections={sections} />
        <S.ContainerTitle>
          <Title title={'Dados do Plano'} />
        </S.ContainerTitle>
        <S.ContainerCard onSubmit={() => {}}>
          {status === 'active' && hasPermission(ResourceType.ACTIVE_INACTIVE_PLAN) && (
            <S.InactivePlan onClick={handleInactivePlan}>
              <S.InactivePlanOption>
                <MdClose size={20} />
                {inactivating ? 'Inativando....' : 'Inativar'}
              </S.InactivePlanOption>
            </S.InactivePlan>
          )}
          <S.Row>
            <S.Col>
              <S.Label>Nome do Plano</S.Label>
              <S.Value>{name}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>Cliente</S.Label>
              <S.Value>{clientType}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>Preço</S.Label>
              <S.Value>{price}</S.Value>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Intervalo (meses)</S.Label>
              <S.Value>{interval}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>Tipo de Canal de Venda</S.Label>
              <S.Value>{typeSalesChannelName}</S.Value>
            </S.Col>
            <S.Col>
              <S.Label>Status</S.Label>
              <S.Value>
                {status === 'active' ? (
                  <Badge title="Ativo" type="success" />
                ) : (
                  <Badge title="Inativo" type="error" />
                )}
              </S.Value>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Gateway ID (Vindi)</S.Label>
              <S.Value>{gatewayPlanId || 'N/A'}</S.Value>
            </S.Col>
            <S.Col />
            <S.Col />
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Descrição</S.Label>
              <S.Value>{description}</S.Value>
            </S.Col>
          </S.Row>
          <S.BoxButton>
            <DefaultButton label="Voltar" secondary onClick={goBack} />
          </S.BoxButton>
        </S.ContainerCard>
      </S.Container>
    </S.ContainerPage>
  )
}

export default ShowPlan
