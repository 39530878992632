import { useRef } from 'react'

export const useDebounce = (fn: any, delay: number) => {
  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null)

  const debounceFn = (...args: any) => {
    window.clearTimeout(timeoutRef.current as NodeJS.Timeout)
    timeoutRef.current = setTimeout(() => {
      fn(...args)
    }, delay)
  }
  return debounceFn
}
