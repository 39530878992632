import axios from 'axios'
import { Masks } from '../common/masks'

type AddressViaCep = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ddd: string
}

export async function getAddressByCep(cep: string) {
  cep = Masks.onlyNumbers(cep)
  return await axios.get<AddressViaCep>(`https://viacep.com.br/ws/${cep}/json/`)
}
