import styled from 'styled-components'
import Colors from '../../../common/colors'
import Select from 'react-select'

export const ContainerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  padding-top: 32px;
`
export const MessageError = styled.span`
  color: ${Colors.error};
  font-size: 10px;
`

export const MessageErrorButton = styled.p`
  color: ${Colors.error};
`

export const ContainerBlock = styled.div`
  background-color: ${Colors.white};
  padding: 16px;
  border-radius: 8px;
  box-shadow: -1px 3px 15px rgba(22, 34, 51, 0.08);
  margin-top: 20px;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`

export const ContainerCard = styled.form``

export const Row = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  padding: 0 8px 8px 8px;
`

export const Col = styled.div`
  width: 100%;
  padding: 8px;

  input {
    width: 100%;
  }
`
export const ColFlex = styled.div`
  width: 100%;
  display: flex;
  input {
    width: 100%;
  }
`

export const Label = styled.div`
  margin-top: 1px;
`

export const BoxButton = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  button {
    width: 100%;
    max-width: 120px;
  }
`
export const CustonSelect = styled(Select)`
  .Select__control {
    height: 40px;
    width: 100%;
    border: 1px solid rgb(188, 189, 189);
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    height: auto;
  }

  .Select__control:hover {
    border: 1px solid ${Colors.primary};
    box-shadow: inset 1px 2px 4px rgba(158, 63, 135, 0.01),
      0px 0px 8px rgba(158, 63, 135, 0.2);
  }

  .Select__control--is-focused {
    border: 1px solid ${Colors.primary};
    box-shadow: inset 1px 2px 4px rgba(158, 63, 135, 0.01),
      0px 0px 8px rgba(158, 63, 135, 0.2);
  }

  .Select__control--is-open {
    border: 1px solid ${Colors.primary};
    box-shadow: inset 1px 2px 4px rgba(158, 63, 135, 0.01),
      0px 0px 8px rgba(158, 63, 135, 0.2);
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    color: #3c3d3e;
  }
`
export const InactiveSalesChannel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
`
export const EditSalesChannel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
`

export const InactiveSalesChannelOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.error};
`
export const ActiveSalesChannelOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.success};
`
export const EditSalesChannelOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${Colors.black};
`
export const BoxOption = styled.div`
  display: flex;
  justify-content: end;

  button {
    width: 100%;
    max-width: 120px;
  }
`
